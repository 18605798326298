import { HttpBody, PutNetworkQuery } from "../../../../../harmony/core";
import { PreApprovalSource } from "../../../../shared/models/partnerCampaign";
import { ApiEndpoint } from "../../../api-endpoint";

export class PreApprovePartnerCampaignNetworkQuery extends PutNetworkQuery {
  constructor(
    private readonly source: PreApprovalSource,
    private readonly partnerId: number,
    private readonly campaignId?: number,
  ) {
    super(ApiEndpoint.PreApprove);
  }

  public get body(): HttpBody {
    return {
      source: this.source,
      campaignId: this.campaignId,
      partnerId: this.partnerId,
    };
  }
}
