@switch (state.$type) {
  @case ("error") {
    <app-reload
      textKey="shared.errorLoadingTheList"
      (action)="updatePublications()"
    />
  }

  @case ("no-results") {
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <app-reload
      textKey="campaign.content.noResultsFound"
      buttonKey="campaign.content.resetSearch"
      (action)="resetFilters.emit()"
    />
  }

  @case ("list") {
    <app-publication-template-aggregated-metrics [metrics]="state.metrics" />
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <div class="publications-grid">
      @if (state.publicationTemplates === undefined) {
        @for (item of EmptyArray; track item; let i = $index) {
          <app-publication-tile-placeholder />
        }
      } @else {
        @for (
          item of state.publicationTemplates;
          track item.publicationTemplate.id
        ) {
          <app-publication-tile
            [publication]="item"
            [areActionsVisible]="false"
          >
            <app-publication-template-preview
              [data]="item.publicationTemplate"
            />
          </app-publication-tile>
        }
      }
    </div>
    <app-paginator
      [currentPage]="currentPage()"
      [totalPages]="totalPages()"
      (pageChange)="currentPage.set($event)"
    />
  }
}
