import dayjs from "dayjs";

import { PartnerCampaignGoogleAdPost } from "../../models/partnerCampaignGoogleAdPost";

export class CreatePaidGoogleAdFromPostData {
  public chargeId: string;
  public clientSecret: string;
  public postId: number;
  public adDuration: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public radius: number;
  constructor(
    chargeId: string,
    clientSecret: string,
    post: PartnerCampaignGoogleAdPost,
    adDuration: number,
    addressId: number,
    scheduledPublishDate: Date,
    radius: number,
  ) {
    this.chargeId = chargeId;
    this.clientSecret = clientSecret;
    this.postId = post.id;
    this.adDuration = adDuration;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.radius = radius;
  }
}
