import dayjs from "dayjs";

import { PartnerFacebookPostLog } from "../../models/partnerFacebookPostLog";

export class CreatePaidFacebookAdFromPostLogData {
  public chargeId: string;
  public clientSecret: string;
  public postLogId: number;
  public adDuration: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public isDarkPost: boolean;
  public radius: number;
  public text: string;
  public postToFacebook: boolean;
  public postToInstagram: boolean;
  constructor(
    chargeId: string,
    clientSecret: string,
    postLog: PartnerFacebookPostLog,
    adDuration: number,
    addressId: number,
    scheduledPublishDate: Date,
    isDarkPost: boolean,
    radius: number,
    postToFacebook: boolean,
    postToInstagram: boolean,
  ) {
    this.chargeId = chargeId;
    this.clientSecret = clientSecret;
    this.postLogId = postLog.id;
    this.text = postLog.text;
    this.adDuration = adDuration;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.isDarkPost = isDarkPost;
    this.radius = radius;
    this.postToInstagram = postToInstagram;
    this.postToFacebook = postToFacebook;
  }
}
