import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { OrderService } from "../../shared/services/order.service";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { getAppDataSignal } from "../../../../shared/services/api/app-data.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";

@Component({
  selector: "app-partner-campaign-pos-orders",
  templateUrl: "./partner-campaign-orders.component.html",
  styleUrl: "./partner-campaign-orders.component.scss",
})
export class PartnerCampaignOrdersComponent implements OnInit, OnDestroy {
  protected appData = getAppDataSignal();
  protected campaign: PartnerCampaign;
  protected hasPastOrders = false;
  protected partner: Partner;

  private campaignSubscription?: Subscription;

  constructor(
    private readonly orderService: OrderService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
  }

  public ngOnInit(): void {
    this.initData(this.campaign);

    this.campaignSubscription =
      this.partnerCampaignService.partnerCampaignUpdated.subscribe((campaign) =>
        this.initData(campaign),
      );
  }

  public ngOnDestroy(): void {
    this.campaignSubscription?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign): void {
    this.campaign = campaign;
    this.checkForPreviousOrders();
  }

  protected hasPOS(): boolean {
    const currentDetails = this.campaign.currentDetails;

    if (!currentDetails) {
      return false;
    }

    return currentDetails.pos && currentDetails.pos.length > 0;
  }

  public checkForPreviousOrders() {
    this.orderService
      .getOrders(this.partner.id, this.campaign.id)
      .subscribe((orders) => {
        this.hasPastOrders = !!orders.length;
      });
  }
}
