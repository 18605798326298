<app-partner-campaign-header-notice [campaign]="campaign" />
<div class="campaign-header">
  <div class="campaign-header-info">
    <div class="campaign-header-info-title">{{ campaign.name }}</div>
    <div class="campaign-header-info-brand">
      {{ campaign.brand.companyName }}
    </div>
    <div class="campaign-header-info-dates">
      {{
        "partner.campaign.header.campaignDates"
          | translate
            : {
                startDate: campaign.startDate | localizeDate: "shortDate",
                endDate: campaign.endDate | localizeDate: "shortDate"
              }
      }}
    </div>
  </div>
  <div class="campaign-header-boxes">
    <div
      *ngIf="shouldShowShortLink()"
      (click)="goToLink()"
      class="campaign-header-boxes-item campaign-header-boxes-item--clickable"
    >
      <div>
        <img src="/assets/images/icons/header-link.svg" />
      </div>
      <a class="action">
        <span>{{ "partner.campaign.header.campaignLink" | translate }}</span>
      </a>
      <div class="brand-provided-badge">
        <ng-container
          *ngIf="true || campaign.hasBrandDefinedLandingPageLink; else emptyTpl"
          >{{
            "partner.campaign.header.brandProvided" | translate
          }}</ng-container
        >
        <ng-template #emptyTpl>&nbsp;</ng-template>
      </div>
    </div>
    @if (!campaign.isBeta) {
      <div class="campaign-header-boxes-item">
        <div>
          <img src="/assets/images/icons/header-post-scheduled.svg" />
        </div>
        {{ campaign.totalScheduledPostLogs + campaign.totalPublishedPostLogs }}
        {{ "partner.campaign.header.postsScheduled" | translate }}
        <div>&nbsp;</div>
      </div>
      <div
        class="campaign-header-boxes-item campaign-header-boxes-item--clickable"
        (click)="openBudgetDialog()"
      >
        <div>
          <img src="/assets/images/icons/header-budget.svg" />
        </div>
        {{ campaign.totalCampaignBudget }}{{ campaign.currencySymbol }}
        {{ "partner.campaign.header.budget" | translate }}
        <a>{{
          (campaign.canPartnerEditBudget ? "shared.edit" : "shared.view")
            | translate
        }}</a>
      </div>
      <div class="campaign-header-boxes-item">
        <div>
          <img src="/assets/images/icons/header-spent.svg" />
        </div>
        {{ campaign.totalBudgetSpent }}{{ campaign.currencySymbol }}
        {{ "partner.campaign.header.spent" | translate }}
        <div>&nbsp;</div>
      </div>
    }
  </div>
</div>
