import { DatePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationTemplateListItemScheduleComponent } from "../../../../shared/content/components/publication-template-list-item-schedule/publication-template-list-item-schedule.component";
import { PublicationAggregatedListViewItemWithIntents } from "../../../../shared/content/domain/models/publication-list-view";
import { SharedPipesModule } from "../../../../shared/pipes/shared-pipes.module";
import { getDateTimeFormatSignal } from "../../../../shared/services/date.service";

@Component({
  standalone: true,
  selector: "app-partner-publication-template-with-intents-list-item",
  templateUrl:
    "./partner-publication-template-with-intents-list-item.component.html",
  styleUrls: [
    "./partner-publication-template-with-intents-list-item.component.scss",
    "../partner-publication-template-list-item.component.scss",
  ],
  imports: [
    MatIcon,
    TranslateModule,
    SharedPipesModule,
    DatePipe,
    PublicationTemplateListItemScheduleComponent,
  ],
})
export class PartnerPublicationTemplateWithIntentsListItemComponent {
  protected publication =
    input.required<PublicationAggregatedListViewItemWithIntents>();
  protected readonly dateTimeFormat = getDateTimeFormatSignal();
}
