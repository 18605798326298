import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BrandFacebookPostLog } from "../../shared/models/brandFacebookPostLog";
import { PaymentType } from "../../shared/enums/campaign.enums";
import { CampaignPostContentType } from "../../shared/enums/campaignPost.enums";
import { PartnerCampaign } from "../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../shared/models/partnerCampaignDetail";
import { PostLog } from "../../shared/models/postLog";

export interface PartnerCampaignViewAdDialogData {
  readonly campaign: PartnerCampaign;
  readonly postLog: PostLog;
}

@Component({
  selector: "app-partner-campaign-view-ad-dialog",
  templateUrl: "./partner-campaign-view-ad-dialog.component.html",
  styleUrl: "./partner-campaign-view-ad-dialog.component.scss",
})
export class PartnerCampaignViewAdDialogComponent implements OnInit {
  protected readonly ContentType = CampaignPostContentType;
  protected readonly PaymentType = PaymentType;
  protected readonly campaign: PartnerCampaign;
  protected readonly postLog: PostLog;
  protected campaignDetails?: PartnerCampaignDetails;
  protected effectiveAdsEndDate?: Date;
  protected effectiveAdsStartDate?: Date;
  protected loading = false;
  protected mapCenter!: google.maps.LatLng;
  protected mapRadius!: number;

  @ViewChild("scrollableArea") private scrollableArea!: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) data: PartnerCampaignViewAdDialogData) {
    this.campaign = data.campaign;
    this.postLog = data.postLog;
  }

  public ngOnInit(): void {
    if (this.scrollableArea) {
      this.scrollableArea.nativeElement.scrollTop = 0;
    }

    this.campaignDetails = this.campaign.currentDetails;
    this.effectiveAdsStartDate = this.postLog.ad?.startDate;
    this.effectiveAdsEndDate = this.postLog.ad?.endDate;
    this.mapRadius = this.postLog.ad?.radius || 30; // `radius=0` is not accepted

    this.mapCenter = new google.maps.LatLng(
      parseFloat(this.postLog.ad?.latitude + ""),
      parseFloat(this.postLog.ad?.longitude + ""),
    );

    setTimeout(() => {
      if (this.scrollableArea) {
        this.scrollableArea.nativeElement.scrollTop = 0;
      }
    }, 500);

    this.loading = false;
  }

  protected getCarouselFirstSlideUrl(post: BrandFacebookPostLog): string {
    return post.carouselSlides[0]?.url || "";
  }

  protected isAPostLogWithAlreadyPaidAd(): boolean {
    return this.postLog && this.postLog.hasAd && this.postLog.ad!.isPaidAd;
  }
}
