import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { PartnerCampaignService } from "../../shared/services/api/partner-campaign.service";
import { assert } from "../../shared/utils/assert";

export function PartnerCampaignResolverGuard(): CanActivateFn {
  return async (route): Promise<boolean> => {
    const router = inject(Router);
    const partnerCampaignService = inject(PartnerCampaignService);

    let currentCampaignId: number | undefined;
    try {
      currentCampaignId = partnerCampaignService.currentPartnerCampaignId;
    } catch {}

    const campaignIdParam = route.paramMap.get("campaignId");
    assert(campaignIdParam, "Missing Campaign ID parameter in route");

    const campaignId = parseInt(campaignIdParam, 10);
    const isIdCorrect = !isNaN(campaignId);
    const isNewCampaignSelected = campaignId !== currentCampaignId;
    const isCurrentCampaignNotLoaded =
      !partnerCampaignService.getCurrentPartnerCampaign();

    if (!isIdCorrect) {
      await router.navigate(["/not-found"]);
      return false;
    }

    if (isNewCampaignSelected || isCurrentCampaignNotLoaded) {
      try {
        await lastValueFrom(
          partnerCampaignService.loadPartnerCampaign(campaignId),
        );
      } catch (err) {
        console.error(err);
      }
    }

    return true;
  };
}
