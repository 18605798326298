import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PostPlatform } from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignNavigationComponent } from "../partner-campaign-navigation/partner-campaign-navigation.component";

export interface PartnerCampaignPostsDialogData {
  readonly campaign: PartnerCampaign;
  readonly partner: Partner;
}

@Component({
  selector: "app-partner-campaign-posts-dialog",
  templateUrl: "./partner-campaign-posts-dialog.component.html",
  styleUrls: ["./partner-campaign-posts-dialog.component.scss"],
  providers: [PartnerCampaignNavigationComponent],
})
export class PartnerCampaignPostsDialogComponent {
  public partner: Partner;
  public campaign: PartnerCampaign;
  public campaignDetails?: PartnerCampaignDetails;
  public postsTabsSelectedIndex = 0;
  public readonly Platform = PostPlatform;

  constructor(@Inject(MAT_DIALOG_DATA) data: PartnerCampaignPostsDialogData) {
    this.campaign = data.campaign;
    this.campaignDetails = this.campaign.currentDetails;
    this.partner = data.partner;
  }
}
