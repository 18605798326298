import { DataSource } from "@angular/cdk/collections";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Order, OrderDetailRow } from "../../../../shared/models/order";
import { OrderService } from "../../shared/services/order.service";

// TODO: replace this with a service with orderService injected and a method to get partner and campaign ids
export class OrderDataSource extends DataSource<any> {
  constructor(
    private orderService: OrderService,
    private partnerId: number,
    private campaignId: number,
  ) {
    super();
  }

  public connect(): Observable<Order[] | OrderDetailRow[]> {
    const rows: any[] = [];

    return this.orderService.getOrders(this.partnerId, this.campaignId).pipe(
      map((orders) => {
        orders.forEach((order) => {
          order.total = this.getOrderTotal(order);
          rows.push(order);

          // (used for populating the order detail row)
          const orderDetail = {
            detailRow: true,
            order: order,
          };

          rows.push(orderDetail);
        });

        return rows;
      }),
    );
  }

  public disconnect(): void {}

  public getOrderTotal(order: Order): number {
    let total = 0;
    if (order.details) {
      order.details.forEach((ol) => {
        if (ol.price !== "0") {
          total += parseFloat(ol.price);
        }
      });
    }
    return total;
  }
}
