import { NgModule } from "@angular/core";
import {
  ApiRequestService,
  Cached,
  provideDefaultNetworkDataSource,
  PutInteractor,
  PutRepository,
  SinglePutDataSourceRepository,
} from "../../../harmony/core";
import { PartnerCampaignService } from "../../shared/services/api/partner-campaign.service";
import { PreApprovePartnerCampaignInteractor } from "./domain/interactors/pre-approve-partner-campaign.interactor";

export abstract class PartnerCampaignProvider {
  abstract getPreApprovePartnerCampaign(
    partnerCampaignService: PartnerCampaignService,
  ): PreApprovePartnerCampaignInteractor;
}

export class PartnerCampaignDefaultProviders extends PartnerCampaignProvider {
  constructor(private readonly apiRequestService: ApiRequestService) {
    super();
  }

  @Cached()
  private getRepository(): PutRepository<void> {
    const dataSource = provideDefaultNetworkDataSource<void>(
      this.apiRequestService,
    );
    return new SinglePutDataSourceRepository<void>(dataSource);
  }

  public getPreApprovePartnerCampaign(): PreApprovePartnerCampaignInteractor {
    return new PreApprovePartnerCampaignInteractor(
      new PutInteractor<void>(this.getRepository()),
    );
  }
}

export const PARTNER_CAMPAIGN_INTERACTOR_PROVIDERS = [
  {
    provide: PreApprovePartnerCampaignInteractor,
    deps: [PartnerCampaignProvider, PartnerCampaignService],
    useFactory: (
      partnerCampaignProvider: PartnerCampaignProvider,
      partnerCampaignService: PartnerCampaignService,
    ) =>
      partnerCampaignProvider.getPreApprovePartnerCampaign(
        partnerCampaignService,
      ),
  },
];

@NgModule({
  providers: [
    ...PARTNER_CAMPAIGN_INTERACTOR_PROVIDERS,
    {
      provide: PartnerCampaignProvider,
      deps: [ApiRequestService],
      useFactory: (apiRequest: ApiRequestService) =>
        new PartnerCampaignDefaultProviders(apiRequest),
    },
  ],
})
export class PartnerCampaignProviderModule {}
