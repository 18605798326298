import { Component, OnInit } from "@angular/core";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";

@Component({
  selector: "app-partner-campaign-downloads",
  templateUrl: "./partner-campaign-downloads.component.html",
  styleUrl: "./partner-campaign-downloads.component.scss",
})
export class PartnerCampaignDownloadsComponent implements OnInit {
  protected campaign: PartnerCampaign;
  protected campaignDetails: PartnerCampaignDetails;
  protected partner: Partner;
  protected selectedIndex = 1;

  constructor(
    public readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
    this.campaignDetails = this.campaign.currentDetails!;

    this.partnerCampaignService.selectedMenuCategoryIndex =
      this.partnerCampaignService.selectedMenuCategoryIndex ?? 0;
  }

  public ngOnInit(): void {
    this.scrollToTopPositioning();
  }

  private scrollToTopPositioning(): void {
    const scrollToTop: any = document.querySelector("#scroll-to-top");
    let scrollPos: number;

    window.addEventListener("scroll", () => {
      scrollPos = window.scrollY;
      if (scrollPos > 100) {
        scrollToTop.classList.add("show");
      } else {
        scrollToTop.classList.remove("show");
      }
    });
  }
}
