import {
  Component,
  computed,
  input,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Address, AddressType } from "../../../../shared/models/address";
import { AppData } from "../../../../shared/models/appData";
import { CampaignProduct } from "../../../../shared/models/campaignProduct";
import { Image } from "../../../../shared/models/image";
import { Order, OrderLine } from "../../../../shared/models/order";
import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { CampaignService } from "../../../../shared/services/api/campaign.service";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { UserService } from "../../../../shared/services/api/user.service";
import { getTranslatedCountriesSignal } from "../../../../shared/services/language.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import {
  PartnerCampaignOrderConfirmationDialogComponent,
  PartnerCampaignOrderConfirmationDialogData,
} from "../partner-campaign-order-confirmation-dialog/partner-campaign-order-confirmation-dialog.component";
import { PartnerCampaignOrdersComponent } from "../partner-campaign-orders/partner-campaign-orders.component";

export interface OrderLineCreate {
  productId: number;
  optionId: string | number;
  text: string;
  title: string;
  price: string;
}

@Component({
  selector: "app-partner-campaign-create-order",
  templateUrl: "./partner-campaign-create-order.component.html",
  styleUrls: ["./partner-campaign-create-order.component.scss"],
})
export class PartnerCampaignCreateOrderComponent implements OnInit, OnDestroy {
  public appData = input.required<AppData>();
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public partner!: Partner;

  protected readonly AddressType = AddressType;
  protected readonly countries = getTranslatedCountriesSignal(this.appData);
  protected readonly currency = computed(() => this.appData().currency);
  protected order: Order;
  protected address?: Address;
  protected products: CampaignProduct[] = [];
  protected fullscreenEnabled = false;
  protected fullscreenImageUrl = "";
  protected orderLines: OrderLineCreate[] = [];

  private readonly subscriptions = new Subscription();

  constructor(
    private campaignService: CampaignService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private parentOrders: PartnerCampaignOrdersComponent,
    private partnerCampaignService: PartnerCampaignService,
    private partnerService: PartnerService,
    private router: Router,
    private userService: UserService,
  ) {
    this.order = new Order();
  }

  public ngOnInit(): void {
    this.initData(this.campaign);

    this.subscriptions.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe((campaign) =>
        this.initData(campaign),
      ),
    );

    this.updateOrderShippingAddress(this.partner);

    // Update the order shipping address if
    // partner shipping address has changed
    this.subscriptions.add(
      this.partnerService.currentPartner$.subscribe((partner) => {
        if (partner) {
          this.partner = partner;
          this.updateOrderShippingAddress(partner);
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign): void {
    if (!campaign.partnerHasAccess) {
      this.router.navigate([
        "/partner/campaign/view/" + campaign.id + "/summary",
      ]);
    }
    this.products = campaign.currentDetails?.pos ?? [];
  }

  private updateOrderShippingAddress(partner: Partner): void {
    this.address = this.address
      ? this.address
      : partner.addresses.find((a) => a.id === partner.shippingAddressId);
  }

  protected openFullscreen(imageUrl: string): void {
    this.fullscreenImageUrl = imageUrl;
    this.fullscreenEnabled = true;

    document.body.classList.add("prevent-scrolling");
  }

  protected closeFullscreen(): void {
    this.fullscreenEnabled = false;

    document.body.classList.remove("prevent-scrolling");
  }

  protected animateLeft(images: Image[]): void {
    const firstImage = images[0];

    images.splice(0, 1);
    images.splice(images.length, 0, firstImage);
  }

  protected animateRight(images: Image[]): void {
    const lastImage = images[images.length - 1];

    images.splice(images.length - 1, 1);
    images.splice(0, 0, lastImage);
  }

  protected handleOptionChange(
    productId: number,
    optionId: number | string,
  ): void {
    const product = this.products.find((p) => p.id === productId);
    const option = product?.options.find((o) => o.id === optionId);

    // Find matching order lines for this product
    const orderLinesArray = this.orderLines.filter(
      (oL) => oL.productId === productId,
    );

    if (optionId === "none") {
      const index = this.orderLines.findIndex(
        (oL) => oL.productId === productId,
      );

      if (index !== -1) {
        this.orderLines.splice(index, 1);
      }
    } else {
      // Add/update the item
      if (orderLinesArray.length) {
        // matching product exists, update selected option id
        const orderLine = orderLinesArray[0];
        orderLine.optionId = optionId;
        orderLine.text = option?.text ?? "";
        orderLine.title = product?.title ?? "";
        orderLine.price = option?.price ?? "";
      } else {
        // matching product doesn't exist, add product/option combination
        this.orderLines.push({
          productId: productId,
          optionId: optionId,
          text: option?.text ?? "",
          title: product?.title ?? "",
          price: option?.price ?? "",
        });
      }
    }

    // Notify user of change
    this.notificationService.success("partner.campaign.order.cartUpdated");
  }

  protected getOrderTotal(): number {
    return (this.orderLines ?? []).reduce(
      (total, ol) => total + parseFloat(ol.price),
      0,
    );
  }

  protected showConfirmationDialog(): void {
    // Update additional information field with any new data
    if (this.address && this.order.additionalInformation) {
      this.address.additionalInformation = this.order.additionalInformation;
    }

    const partnerName =
      this.partner.contactPersonFirstName +
      " " +
      this.partner.contactPersonLastName;

    const dialogRef = this.dialog.open(
      PartnerCampaignOrderConfirmationDialogComponent,
      {
        width: "550px",
        data: {
          orderLines: this.orderLines,
          orderTotal: this.getOrderTotal(),
          currency: this.currency(),
          companyName: this.partner.companyName,
          partnerName: partnerName,
          address: this.address,
          email: this.partner.emails.join(", "),
        } as PartnerCampaignOrderConfirmationDialogData,
      },
    );

    this.subscriptions.add(
      dialogRef.componentInstance.orderConfirmedEvent.subscribe(() =>
        this.submitOrder(dialogRef),
      ),
    );
  }

  private submitOrder(
    dialogRef: MatDialogRef<PartnerCampaignOrderConfirmationDialogComponent>,
  ): void {
    const country: any = this.address?.country;

    // Invalid shipping address
    if (
      !this.address ||
      this.address?.streetAndNumber === "" ||
      (country instanceof Array && country.length === 0)
    ) {
      this.notificationService.error("partner.campaign.order.invalidAddress");
      return;
    }

    this.order.address = this.address;
    this.order.order_details = this.orderLines.map(
      (ol) =>
        new OrderLine({
          text: ol.text ? ol.text : "1",
          concept: ol.title,
          price: ol.price,
        }),
    );

    this.campaignService
      .createOrder(this.partner.id, this.campaign.id, this.order)
      .subscribe(() => this.refreshUserData(dialogRef));
  }

  private refreshUserData(
    dialogRef: MatDialogRef<PartnerCampaignOrderConfirmationDialogComponent>,
  ): void {
    this.userService.get().subscribe({
      next: () => this.confirmOrder(dialogRef),
      error: () =>
        this.notificationService.error("partner.campaign.order.errorCreating"),
    });
  }

  private confirmOrder(
    dialogRef: MatDialogRef<PartnerCampaignOrderConfirmationDialogComponent>,
  ): void {
    dialogRef.componentInstance.orderConfirmed = true;
    dialogRef
      .afterClosed()
      .subscribe(() => this.parentOrders.checkForPreviousOrders());
  }

  protected scrollToCard(): void {
    const cart = document.getElementById("cart");
    const yOffset = 80;

    if (cart) {
      window.scrollTo({
        top: cart.getBoundingClientRect().top + window.scrollY - yOffset,
        behavior: "smooth",
      });
    }
  }
}
