import { Partner } from "../../shared/models/partner";
import { PartnerCampaign } from "../../shared/models/partnerCampaign";
import { PartnerCampaignPost } from "../../shared/models/partnerCampaignPost";
import { PostLog } from "../../shared/models/postLog";

export class PartnerCampaignPromoteAdDialogData {
  constructor(
    public readonly campaign: PartnerCampaign,
    public readonly post: PartnerCampaignPost | PostLog,
    public readonly scheduledPublishDateAndTime: Date,
    public readonly partner: Partner,
    public readonly redirectToScheduledPosts = false,
    public readonly postToFacebook = false,
    public readonly postToInstagram = false,
  ) {}
}
