@switch (state.$type) {
  @case ("error") {
    <app-reload
      textKey="shared.errorLoadingTheList"
      (action)="updatePublications()"
    />
  }

  @case ("no-results") {
    <app-reload
      textKey="campaign.content.noResultsFound"
      buttonKey="campaign.content.resetSearch"
      (action)="resetFilters.emit()"
    />
  }

  @case ("list") {
    <app-publication-template-aggregated-metrics [metrics]="state.metrics" />
  }
}

@if (state.$type === "list") {
  <full-calendar #calendar [options]="calendarConfig" [events]="calendarEvents">
    <ng-template #dayCellContent let-arg>
      <app-calendar-day-cell
        [dayNumberText]="arg.dayNumberText"
        [date]="arg.date"
        [editable]="false"
      />
    </ng-template>
    <ng-template #eventContent let-arg>
      <app-partner-publication-calendar-event [event]="arg.event" />
    </ng-template>
  </full-calendar>
}
