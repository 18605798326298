<ng-container *ngIf="!orderConfirmed">
  <h2 mat-dialog-title>
    {{ "partner.order.confirmationDialog.title" | translate }}
  </h2>
  <mat-dialog-content>
    <div class="order-items border-bottom-grey">
      <h3>{{ "partner.order.confirmationDialog.yourOrder" | translate }}</h3>
      <table *ngIf="data.orderLines.length > 0">
        <tbody>
          <tr *ngFor="let orderLine of data.orderLines">
            <td class="item">
              <span class="quantity">{{ orderLine.text }}</span>
              x {{ orderLine.title }}
            </td>
            <td class="amount">
              {{
                orderLine.price === "0"
                  ? "FREE"
                  : (orderLine.price | formatNumber: data.currency)
              }}
            </td>
          </tr>
          <tr class="total">
            <td class="item">
              {{ "partner.order.confirmationDialog.total" | translate }}
            </td>
            <td class="amount">
              {{ data.orderTotal | formatNumber: data.currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-shipping border-bottom-grey">
      <h3>
        {{ "partner.order.confirmationDialog.shippingAddress" | translate }}
      </h3>
      <address>
        {{ data.address.streetAndNumber }}<br />
        {{ data.address.postalCode }}, {{ data.address.city }}<br />
        {{ data.address.country }}<br /><br />
      </address>

      <h3>{{ "partner.order.confirmationDialog.contactInfo" | translate }}</h3>
      <address>
        {{ data.partnerName }}<br />
        {{ data.companyName }}
      </address>
    </div>
    <div
      class="additional-info border-bottom-grey"
      *ngIf="data.address.additionalInformation"
    >
      <h3>
        {{ "partner.order.confirmationDialog.additionalInfo" | translate }}
      </h3>
      <p>{{ data.address.additionalInformation }}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="button-grey" [disabled]="saving" [mat-dialog-close]="true">
      {{ "shared.cancel" | translate }}
    </button>
    <button class="button-orange" [disabled]="saving" (click)="confirmOrder()">
      {{ "shared.confirm" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
<ng-container *ngIf="orderConfirmed">
  <h2 mat-dialog-title>
    {{ "partner.order.confirmationDialog.thanks" | translate }}
  </h2>
  <mat-dialog-content>
    <p>
      {{
        "partner.order.confirmationDialog.confirmationEmail"
          | translate: { email: data.email }
      }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button-orange" (click)="seeOrders()">
      {{ "partner.order.confirmationDialog.seeOrders" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
