<h2 mat-dialog-title>
  <mat-icon>info</mat-icon>
  {{
    "partner.campaign.contentCalendar.selectACalendarWarningTitle" | translate
  }}
</h2>
<mat-dialog-content>
  <p class="warning-text">
    {{
      "partner.campaign.contentCalendar.selectACalendarWarningDescription"
        | translate
    }}
  </p>
  <img
    class="example-image"
    src="/assets/images/calendar-select-campaign-sample-{{
      currentLanguageCode
    }}.png"
  />
</mat-dialog-content>
<mat-dialog-actions>
  <button tabindex="-1" class="button-orange" [mat-dialog-close]="true">
    {{ "shared.ok" | translate }}
  </button>
</mat-dialog-actions>
