import { Component, computed, inject, signal, ViewChild } from "@angular/core";
import { SelectorOptionsComponent } from "../../../../features/selector-options/selector-options.providers";
import { SortDir } from "../../../../features/shared/data/sort-dir.enum";
import { AggregatedFilterComponent } from "../../../../shared/components/filtering/aggregated-filter/aggregated-filter.component";
import {
  FilterOutput,
  FilterType,
} from "../../../../shared/components/filtering/filter.interfaces";
import { FiltersRowComponent } from "../../../../shared/components/filtering/filters-row/filters-row.component";
import { SelectorOptionsService } from "../../../../shared/components/filtering/selector-options.service";
import {
  SortingOption,
  SortingSelectorComponent,
} from "../../../../shared/components/filtering/sorting-selector/sorting-selector.component";
import { ContentViewTypeSelectorComponent } from "../../../../shared/content/components/content-type-selector/content-view-type-selector.component";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { PartnerCalendarAcceptanceBannerComponent } from "../../components/partner-calendar-acceptance-banner/partner-calendar-acceptance-banner.component";
import { PartnerPublicationListComponent } from "../partner-publication-list/partner-publication-list.component";
import { PartnerPublicationTilesComponent } from "../partner-publication-tiles/partner-publication-tiles.component";
import { ContentViewType } from "../../../../shared/content/content-view-type.enum";
import { PartnerPublicationCalendarComponent } from "../publication-calendar/partner-publication-calendar.component";

@Component({
  standalone: true,
  selector: "app-partner-content",
  templateUrl: "./partner-content.component.html",
  styleUrls: ["./partner-content.component.scss"],
  imports: [
    SortingSelectorComponent,
    FiltersRowComponent,
    AggregatedFilterComponent,
    PartnerPublicationListComponent,
    PartnerPublicationTilesComponent,
    ContentViewTypeSelectorComponent,
    PartnerCalendarAcceptanceBannerComponent,
    PartnerPublicationCalendarComponent,
  ],
})
export class PartnerContentComponent {
  protected readonly ContentViewType = ContentViewType;
  protected readonly FilterType = FilterType;
  private readonly campaign = inject(PartnerCampaignService)
    .currentPartnerCampaign;
  protected readonly getTypesInteractor =
    SelectorOptionsComponent.provideGetOptionsInteractorByType(
      FilterType.PublicationStatus,
      inject(SelectorOptionsService),
    );
  protected readonly filters: FilterType[] = [
    FilterType.PublicationTemplateType,
    FilterType.TimePeriod,
  ];
  protected readonly sortingOptions: SortingOption[] = [
    {
      name: "newestFirst",
      field: "CREATED_AT",
      dir: SortDir.Desc,
    },
    {
      name: "oldestFirst",
      field: "CREATED_AT",
      dir: SortDir.Asc,
    },
  ];

  private readonly fixedFilters: FilterOutput[] = [
    {
      type: FilterType.Campaign,
      value: {
        id: this.campaign.id,
        name: "campaign",
      },
    },
    {
      type: FilterType.Creator,
      value: {
        id: this.campaign.brand.id,
        name: "creator",
      },
    },
    {
      type: FilterType.Publisher,
      value: {
        id: this.campaign.partner.id,
        name: "publisher",
      },
    },
  ];

  protected readonly aggregatedFilters = signal<FilterOutput[]>([]);
  protected readonly statusFilter = signal<FilterOutput | undefined>(undefined);
  protected readonly sortBy = signal<SortingOption>(this.sortingOptions[0]);
  protected readonly viewType = signal<ContentViewType>(ContentViewType.Grid);
  protected readonly isInitialized = signal(false);
  protected readonly activeFilters = computed(() => [
    ...this.aggregatedFilters(),
    ...(this.statusFilter()?.value ? [this.statusFilter()] : []),
    ...this.fixedFilters,
  ]);

  @ViewChild(AggregatedFilterComponent)
  private aggregatedFiltersComponent!: AggregatedFilterComponent;
  @ViewChild(FiltersRowComponent)
  private filtersComponent!: FiltersRowComponent;
  @ViewChild(SortingSelectorComponent)
  private sortingComponent!: SortingSelectorComponent;

  protected resetFilters(): void {
    this.aggregatedFiltersComponent.reset();
    this.filtersComponent.reset();
    this.sortingComponent.reset();
  }

  protected onAggregatedFiltersChanged(filters: FilterOutput[]): void {
    this.isInitialized.set(true);
    this.aggregatedFilters.set(filters);
  }
}
