import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PostLogStatus } from "../../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { PartnerFacebookPostLog } from "../../../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../../../shared/models/postLog";
import { PartnerCampaignPromoteAdDialogData } from "../../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import {
  PartnerCampaignViewAdDialogComponent,
  PartnerCampaignViewAdDialogData,
} from "../../../partner-campaign-view-ad-dialog/partner-campaign-view-ad-dialog.component";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-posts-list-post",
  templateUrl: "./partner-campaign-posts-list-post.component.html",
  styleUrl: "./partner-campaign-posts-list-post.component.scss",
})
export class CampaignPartnerPostsListPostComponent {
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public type!: PostLogStatus;
  @Input({ required: true }) public post!: PostLog;

  @Output() public postLogDeletedEvent = new EventEmitter<PostLog>();
  @Output() public postLogCreatedOrUpdated = new EventEmitter<PostLog>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  protected openAdConfigDialog(postLog: PostLog): void {
    this.dialog.open<
      PartnerCampaignViewAdDialogComponent,
      PartnerCampaignViewAdDialogData
    >(PartnerCampaignViewAdDialogComponent, {
      width: "940px",
      data: { campaign: this.campaign, postLog: postLog },
    });
  }

  protected openPromoteFacebookPostDialog(
    postLog: PartnerFacebookPostLog,
  ): void {
    this.dialog.closeAll();
    if (!this.partner.isAuthorizedFBAds) {
      this.partnerDialogService.showPartnerAuthorizeFacebookAds({
        partner: this.partner,
      });
      return;
    }

    const dialogRef = this.dialog.open(
      PartnerCampaignPromoteAdDialogComponent,
      {
        data: new PartnerCampaignPromoteAdDialogData(
          this.campaign,
          postLog,
          postLog.scheduledPublishDate,
          this.partner,
          false,
          postLog.postToFacebook,
          postLog.postToInstagram,
        ),
        disableClose: true,
      },
    );

    dialogRef.componentInstance.adCreatedOrUpdated.subscribe(() => {
      dialogRef.close();
      this.postLogCreatedOrUpdated.emit(postLog);
    });
  }

  protected async onActionDeletePost(post: PostLog): Promise<void> {
    const isDeleted = await this.partnerDialogService.showDeletePost({
      post: post,
    });

    if (isDeleted) {
      this.campaign.posts = this.campaign.posts.filter(
        (loopPost) => loopPost.id !== post.id,
      );
      this.postLogDeletedEvent.emit(post);
    }
  }

  protected showEditPostModal(post: PostLog): void {
    this.partnerDialogService.showSchedulePost({
      post: post,
      campaign: this.campaign,
    });
  }
}
