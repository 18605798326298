<ng-container>
  <h3 mat-dialog-title>
    {{ "partner.campaign.viewAdDialog.title" | translate }}
  </h3>
</ng-container>
<mat-dialog-content *ngIf="!loading" #scrollableArea class="loader-wrapper">
  <div class="row">
    <div class="col background-gray">
      <div class="promo-ads-preview">
        <div class="promo-ads-preview__details">
          <div class="row">
            <div *ngIf="postLog.isFacebookPost" class="col width-25">
              <div class="promo-ads-preview__mock-wrapper">
                <div class="promo-ads-preview__facebook-header">
                  <div class="promo-ads-preview__facebook-profile-image">
                    <img
                      src="{{
                        postLog.facebookPagePicture ||
                          'assets/images/avatar-default.jpg'
                      }}"
                      alt=""
                    />
                  </div>
                  <div class="promo-ads-preview__facebook-line"></div>
                </div>
                <img
                  class="promo-ads-preview__image"
                  *ngIf="
                    postLog.mediaType === ContentType.Image ||
                    postLog.mediaType === ContentType.Gif
                  "
                  [src]="postLog.image"
                  alt=""
                />
                <img
                  class="promo-ads-preview__image"
                  *ngIf="postLog.mediaType === ContentType.Carousel"
                  [src]="getCarouselFirstSlideUrl(postLog)"
                  alt=""
                />
                <video
                  class="promo-ads-preview__video"
                  style="width: 100%"
                  *ngIf="postLog.mediaType === ContentType.Video"
                  src="{{ postLog.videoUrl }}"
                  controls
                ></video>
              </div>
            </div>
            <div *ngIf="postLog.isGoogleAd" class="col width-25">
              <div class="promo-ads-preview__mock-wrapper">
                <img
                  class="promo-ads-preview__image"
                  [src]="postLog.image"
                  alt=""
                />
                <div class="promo-ads-preview__google-black-line"></div>
                <div class="promo-ads-preview__google-footer">
                  <div class="promo-ads-preview__google-profile-image">
                    <img
                      class="google-ad-content-logo"
                      [src]="postLog.googleAdLogoUrl"
                      alt=""
                    />
                  </div>
                  <div class="promo-ads-preview__google-grey-lines">
                    <div
                      class="promo-ads-preview__google-grey-lines__line"
                    ></div>
                    <div
                      class="promo-ads-preview__google-grey-lines__line"
                    ></div>
                  </div>
                  <div class="promo-ads-preview__google-icon">
                    <div class="promo-ads-preview__google-icon__image">
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col width-75">
              <div class="promo-ads-preview__info">
                <span>
                  <b>{{
                    "partner.campaign.viewAdDialog.campaignBudget" | translate
                  }}</b> </span
                >: {{ campaign.currencySymbol }}{{ campaign.budget }}<br />
                <span>
                  <b>{{
                    "partner.campaign.viewAdDialog.maxAds" | translate
                  }}</b> </span
                >: {{ campaign.maximumNumberOfAds }}<br />
                <span>
                  <b>{{
                    "partner.campaign.viewAdDialog.budgetPerAd" | translate
                  }}</b> </span
                >:
                <span
                  >{{ campaign.currencySymbol
                  }}{{ campaign.budgetPerAd | number: "1.2-2" }}</span
                >
                <span *ngIf="campaign.paymentType === PaymentType.CoFunded">
                  + {{ campaign.currencySymbol
                  }}{{ campaign.budgetPerAd | number: "1.2-2" }}
                  {{ "partner.campaign.viewAdDialog.onTopFrom" | translate }}
                  {{ campaign.brand.companyName }}
                </span>
                <span *ngIf="campaign.paymentType === PaymentType.Brand">
                  {{ "partner.campaign.viewAdDialog.from" | translate }}
                  {{ campaign.brand.companyName }}
                </span>
                <div class="promo-ads-details__extra_information">
                  <div
                    class="promo-ads-details__text"
                    [innerHTML]="campaignDetails.fbOtherInformation"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- MAP SECTION -->
        <div class="promo-ads-preview__map-wrapper">
          <div class="row">
            <div class="promo-ads-preview__map-wrapper--col-one">
              <h4 class="promo-ads-section__title">
                {{ "partner.campaign.viewAdDialog.targetGroup" | translate }}
              </h4>
              <div class="row">
                <div class="col width-100">
                  <p
                    class="promo-ads-section__value promo-ads-section__value--gender"
                  >
                    {{ campaign.gender | translate }}
                  </p>
                </div>
              </div>
              <h4 class="promo-ads-section__title">
                {{ "partner.campaign.viewAdDialog.targetAge" | translate }}
              </h4>
              <div class="row">
                <div class="col width-100">
                  <p class="promo-ads-section__value">
                    {{ "shared.dateFrom" | translate }} {{ campaign.minAge }}
                    {{ "shared.dateTo" | translate }} {{ campaign.maxAge }}
                  </p>
                </div>
              </div>
              <h4 class="promo-ads-section__title">
                {{ "shared.budget" | translate }}
              </h4>
              <div class="row">
                <div class="col width-100">
                  <p
                    *ngIf="!isAPostLogWithAlreadyPaidAd()"
                    class="promo-ads-section__value"
                  >
                    <span
                      >{{ campaign.currencySymbol
                      }}{{ campaign.budgetPerAd | number: "1.2-2" }}</span
                    >
                    <span *ngIf="campaign.paymentType === PaymentType.CoFunded">
                      + {{ campaign.currencySymbol
                      }}{{ campaign.budgetPerAd | number: "1.2-2" }}
                      <ng-container>
                        {{
                          "partner.campaign.viewAdDialog.onTopFrom" | translate
                        }}</ng-container
                      >
                      {{ campaign.brand.companyName }}
                    </span>
                    <span *ngIf="campaign.paymentType === PaymentType.Brand">
                      {{ "partner.campaign.viewAdDialog.from" | translate }}
                      {{ campaign.brand.companyName }}
                    </span>
                  </p>
                  <p
                    *ngIf="isAPostLogWithAlreadyPaidAd()"
                    class="promo-ads-section__value"
                  >
                    <span>
                      {{ postLog.ad.budget | number: ".2-2" }}
                      {{ campaign.currencySymbol }}
                    </span>
                  </p>
                </div>
              </div>
              <h4
                class="promo-ads-section__title promo-ads-section__title--time-frame"
              >
                {{ "partner.campaign.viewAdDialog.timeFrame" | translate }}
              </h4>
              <div class="row">
                <div class="col width-100">
                  <h5 class="promo-ads-section__subtitle">
                    {{ "campaign.adConfigDialog.startingDate" | translate }}
                  </h5>
                  <p class="promo-ads-section__value">
                    {{ effectiveAdsStartDate | localizeDate: "short" }}
                  </p>
                </div>
                <div class="col width-100">
                  <h5 class="promo-ads-section__subtitle">
                    {{ "campaign.adConfigDialog.finishingDate" | translate }}
                  </h5>
                  <p class="promo-ads-section__value">
                    {{ effectiveAdsEndDate | localizeDate: "short" }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Brand Map-->
            <div class="promo-ads-preview__map-wrapper--col-two">
              <div class="promo-ads-section__map">
                <google-map
                  *ngIf="mapCenter"
                  width="520px"
                  height="416px"
                  [zoom]="8"
                  [center]="mapCenter"
                >
                  <map-marker [position]="mapCenter" />
                  <map-circle
                    [center]="mapCenter"
                    [radius]="mapRadius * 1000"
                    [options]="{ fillColor: 'blue', clickable: false }"
                  />
                </google-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<ng-container>
  <mat-dialog-actions class="promo-ads-actions">
    <button class="promo-ads-actions__button button-grey" [mat-dialog-close]="">
      {{ "shared.close" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
