import { Cached } from "../../../../../harmony/core";
import { PublicationIntentService } from "../data/publication-intent.service";
import { CountPendingPublicationIntentsInteractor } from "./interactors/count-pending-publication-intents.interactor";

export abstract class PublicationIntentProvider {
  abstract provideCountPendingIntents(): CountPendingPublicationIntentsInteractor;
}

export class PublicationIntentsDefaultProvider
  implements PublicationIntentProvider
{
  constructor(
    private readonly PublicationIntentsService: PublicationIntentService,
  ) {}

  @Cached()
  public provideCountPendingIntents(): CountPendingPublicationIntentsInteractor {
    return new CountPendingPublicationIntentsInteractor(
      this.PublicationIntentsService,
    );
  }
}
