@if (campaign) {
  <router-outlet />
} @else {
  <div class="loader-wrapper">
    @if (loadingError) {
      <div class="reload-wrap">
        <app-reload (action)="load()" />
      </div>
    } @else {
      <app-loader
        class="visible"
        [text]="'shared.loadingCampaign' | translate"
      />
    }
  </div>
}
