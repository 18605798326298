import {
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  untracked,
} from "@angular/core";
import { createState } from "../../../../../harmony/angular";
import { FilterOutput } from "../../../../shared/components/filtering/filter.interfaces";
import { SortingOption } from "../../../../shared/components/filtering/sorting-selector/sorting-selector.component";
import { PaginatorComponent } from "../../../../shared/components/paginator/paginator.component";
import { ReloadComponent } from "../../../../shared/components/reload/reload.component";
import { PublicationListItemPlaceholderComponent } from "../../../../shared/content/components/publication-list-item-placeholder/publication-list-item-placeholder.component";
import { PublicationTemplateAggregatedMetricsComponent } from "../../../../shared/content/components/publication-template-aggregated-metrics/publication-template-aggregated-metrics.component";
import { GetPublicationAggregatedListWithIntentsInteractor } from "../../../../shared/content/domain/interactors/get-publication-aggregated-list-with-intents.interactor";
import { GetPublicationAggregatedMetricsInteractor } from "../../../../shared/content/domain/interactors/get-publication-aggregated-metrics.interactor";
import { assert } from "../../../../shared/utils/assert";
import { PartnerCalendarAcceptanceBannerComponent } from "../../components/partner-calendar-acceptance-banner/partner-calendar-acceptance-banner.component";
import { PartnerPublicationTemplateWithIntentsListItemComponent } from "../../components/partner-publication-template-with-intents-list-item/partner-publication-template-with-intents-list-item.component";
import { PartnerPublicationListState } from "./partner-publication-list.state";

@Component({
  standalone: true,
  selector: "app-partner-publication-list",
  templateUrl: "./partner-publication-list.component.html",
  styleUrls: ["./partner-publication-list.component.scss"],
  imports: [
    ReloadComponent,
    PublicationListItemPlaceholderComponent,
    PaginatorComponent,
    PartnerPublicationTemplateWithIntentsListItemComponent,
    PartnerCalendarAcceptanceBannerComponent,
    PublicationTemplateAggregatedMetricsComponent,
  ],
})
export class PartnerPublicationListComponent {
  protected readonly state = createState<PartnerPublicationListState>({
    $type: "list",
    publications: undefined,
    metrics: undefined,
  });
  private readonly getPublicationsWithIntents = inject(
    GetPublicationAggregatedListWithIntentsInteractor,
  );
  private readonly getAggregatedMetrics = inject(
    GetPublicationAggregatedMetricsInteractor,
  );
  private readonly pageSize = 10;
  protected readonly EmptyArray = Array.from({ length: this.pageSize });

  public readonly filters = input<FilterOutput[]>([]);
  public readonly sorting = input.required<SortingOption>();
  public readonly isInitialized = input.required<boolean>();
  protected readonly currentPage = signal(1);
  protected readonly totalPages = signal(0);
  @Output() public readonly resetFilters = new EventEmitter<void>();

  constructor() {
    effect(async () => {
      if (!this.isInitialized()) {
        this.updatePublications();
      }
    });
  }

  protected updatePublications(): void {
    const filters = this.filters();
    const sorting = this.sorting();
    const page = this.currentPage();
    untracked(() => {
      this.getPublications(filters, sorting, page);
    });
  }

  protected async getPublications(
    filters: FilterOutput[],
    sorting?: SortingOption,
    pageIndex?: number,
  ): Promise<void> {
    this.state.set({
      $type: "list",
      metrics: undefined,
      publications: undefined,
    });
    try {
      this.getAggregatedMetrics
        .execute(this.pageSize, pageIndex, filters, sorting)
        .then((metrics) => {
          if (this.state.$type === "no-results") {
            return;
          }
          assert(this.state.$type === "list");
          this.state.set({
            $type: this.state.$type,
            metrics: metrics,
            publications: this.state.publications,
          });
        });

      this.getPublicationsWithIntents
        .execute(this.pageSize, pageIndex, filters, sorting)
        .then((list) => {
          if (list.totalSize === 0) {
            this.state.set({ $type: "no-results" });
            return;
          }

          assert(this.state.$type === "list");
          this.state.set({
            $type: "list",
            publications: list.entries,
            metrics: this.state.metrics,
          });
          this.totalPages.set(Math.ceil(list.totalSize / this.pageSize));
        });
    } catch {
      this.state.set({ $type: "error" });
    }
  }
}
