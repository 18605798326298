import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";

@Component({
  selector: "app-partner-campaign-winner-posts",
  templateUrl: "./partner-campaign-winner-posts.component.html",
  styleUrl: "./partner-campaign-winner-posts.component.scss",
})
export class PartnerCampaignWinnerPostsComponent implements OnInit, OnDestroy {
  protected campaign: PartnerCampaign;
  protected campaignDetails?: PartnerCampaignDetails;
  protected partner: Partner;

  private campaignSubscription?: Subscription;

  constructor(
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
    this.campaignDetails = this.campaign.currentDetails;
  }

  public ngOnInit(): void {
    this.campaignSubscription =
      this.partnerCampaignService.partnerCampaignUpdated.subscribe((campaign) =>
        this.initData(campaign),
      );
  }

  public ngOnDestroy(): void {
    this.campaignSubscription?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign): void {
    this.campaign = campaign;
    this.campaignDetails = campaign.currentDetails;
  }
}
