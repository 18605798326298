<div class="campaign-summary loader-wrapper">
  <ng-container *ngIf="campaign && campaignDetails">
    <div class="left-content">
      <div class="campaign-body">
        <h1>{{ "brand.campaign.summary.campaignInformation" | translate }}</h1>
      </div>
      <div class="carousel" *ngIf="campaignDetails?.sliders?.length">
        <h3>{{ "brand.campaign.summary.featuredImages" | translate }}</h3>
        <div class="carousel-img-wrapper">
          <div
            class="carousel-img static"
            [ngStyle]="{ 'background-image': 'url(' + currentImage.file + ')' }"
            (click)="nextImage()"
          ></div>
          <div
            class="carousel-img slide"
            [ngClass]="{ fadeIn: isSliding, hide: !isSliding }"
            [ngStyle]="{ 'background-image': 'url(' + slideImage.file + ')' }"
          ></div>
        </div>
        <ul class="carousel-controls">
          <li
            class="carousel-control"
            *ngFor="let image of campaignDetails.sliders; let i = index"
          >
            <button
              class="carousel-item-toggle"
              (click)="showImage(i)"
              title="{{ 'partner.campaign.summary.showImage' | translate }}"
              [class.is-active]="image.file === currentImage.file"
            ></button>
          </li>
        </ul>
      </div>
      <div class="campaign-description-title">
        <h3>{{ "partner.campaign.summary.description" | translate }}</h3>
      </div>
      <div [innerHTML]="campaignDescription" class="campaign-description"></div>
      <div *ngIf="campaignDetails?.assets.length" class="assets" #assets>
        <h3>{{ "partner.campaign.summary.assets" | translate }}</h3>
        <ul class="asset-list">
          <li class="asset-item" *ngFor="let asset of campaignDetails.assets">
            <a
              class="asset-item-button"
              type="button"
              href="{{ asset.file }}"
              target="_blank"
            >
              <span
                class="asset-item-img"
                style="background-image: url(assets/images/icons/ic_pdf.svg)"
              >
              </span>
              <span class="asset-item-details">
                <span class="asset-item-name">{{ asset.name }}</span>
                <span class="asset-item-size">{{ asset.size | fileSize }}</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="leave-campaign">
        <div
          (click)="checkLeaveLink($event)"
          class="leave-campaign-text"
          [innerHTML]="'partner.campaign.summary.leaveCampaignText' | translate"
        ></div>
      </div>
    </div>
    <section class="campaign-details">
      <div class="header">
        <p>{{ "partner.campaign.summary.yourDetails" | translate }}</p>
      </div>
      <div class="info">
        <div class="group-data">
          <p class="title">
            {{ "partner.campaign.summary.status" | translate }}
          </p>
          <app-partner-campaign-status [campaign]="campaign" />
        </div>
        <div class="group-data">
          <p class="title">{{ "partner.campaign.summary.logo" | translate }}</p>
          <img
            *ngIf="campaign.partner && campaign.partner.logo"
            class="logo"
            src="{{ campaign.partner.logo }}"
          />
          <ng-container *ngIf="!campaign.partner.logo">
            <span class="data">-</span>
            <span (click)="goToPartnerSettings()" class="change-logo-text">{{
              "partner.campaign.summary.changeLogo" | translate
            }}</span>
          </ng-container>
        </div>
        <div class="group-data">
          <p class="title">
            {{ "partner.campaign.summary.defaultStoreLocation" | translate }}
          </p>
          <div class="group-address">
            <div
              class="campaign-details-address"
              (click)="setAsDefault(address)"
              *ngFor="let address of campaign.partner.addresses"
            >
              <app-address-box
                *ngIf="address && partner && countries().length"
                [address]="address"
                [partner]="partner"
                [countries]="countries()"
                [mode]="'noAction'"
                [allowEdition]="false"
                [allowDeletion]="false"
                [boxSize]="'summary'"
                [type]="address.type"
              />
            </div>
          </div>
          <ng-container *ngIf="!isThereAddressData()">
            <span class="data">-</span>
          </ng-container>
        </div>
        <div class="group-data">
          <p class="title">
            {{ "partner.campaign.summary.salesRep" | translate }}
          </p>
          <mat-form-field>
            <mat-label>{{ "admin.campaignList.name" | translate }}</mat-label>
            <input
              name="sales-rep"
              [(ngModel)]="campaign.partner.salesRep"
              [disabled]="campaign.hasFinished"
              matInput
            />
          </mat-form-field>
        </div>
        <div *ngIf="campaign.partner.isCustomFieldEnabled" class="group-data">
          <p class="title">
            {{ campaign.partner.customFieldLabel | translate }}
          </p>
          <mat-form-field style="margin-bottom: 1.25em">
            <textarea
              name="custom-field-label"
              [(ngModel)]="campaign.partner.customFieldValue"
              matInput
            ></textarea>
          </mat-form-field>
        </div>
        <div
          [ngClass]="{ hidden: !campaign.hasLandingPageRetailerOwnLink }"
          class="group-data"
        >
          <p class="title">
            {{ "partner.campaign.info.partnerCampaignLink" | translate }}
          </p>
          <mat-form-field>
            <mat-select
              [(ngModel)]="campaign.partner.shortUrlPrefix"
              name="websiteUrlPrefix"
              required
            >
              <mat-option
                *ngFor="let urlPrefix of UrlPrefixes"
                [value]="urlPrefix"
              >
                {{ urlPrefix }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              #shortUrl="ngModel"
              (change)="formatShortUrl()"
              [(ngModel)]="campaign.partner.shortUrl"
              name="shortUrl"
              pattern="{{ URL_VALIDATION_PATTERN }}"
              required
            />
            <mat-error
              *ngIf="
                shortUrl.errors &&
                (shortUrl.errors.pattern || shortUrl.errors.required)
              "
            >
              {{ "shared.enterValidUrl" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <button
          [disabled]="
            saving ||
            campaign.hasFinished ||
            (campaign.hasLandingPageRetailerOwnLink && shortUrl.errors)
          "
          (click)="saveCampaignDetails()"
          class="button-orange"
          type="submit"
        >
          <span *ngIf="!saving">{{ "shared.saveChanges" | translate }}</span>
          <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
        </button>
      </div>
    </section>
  </ng-container>
  <app-loader
    [text]="'shared.loadingCampaign' | translate"
    [ngClass]="{ visible: loading }"
  ></app-loader>
</div>
