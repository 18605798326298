<div class="texts-editor">
  <form [formGroup]="form">
    <h3>
      {{ "googleAdEditor.googleAdUploadHeadlines" | translate }}
      <app-tooltip
        text="{{ 'googleAdEditor.googleAdUploadHeadlinesToolTip' | translate }}"
      ></app-tooltip>
    </h3>
    <mat-form-field>
      <mat-label>{{
        "googleAdEditor.googleAdUploadHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="30"
        type="text"
        formControlName="headlineOne"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{ googleAdPost.headlineOne ? googleAdPost.headlineOne?.length : 0 }} /
        30
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineOne')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineOne')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('required', 'headlineOne')">
        {{ "shared.requiredField" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="googleAdPost.headlineTwo || showHeadlineIndex >= 2">
      <mat-label>{{
        "googleAdEditor.googleAdUploadHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="30"
        type="text"
        formControlName="headlineTwo"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{ googleAdPost.headlineTwo ? googleAdPost.headlineTwo?.length : 0 }} /
        30
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineTwo')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineTwo')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="googleAdPost.headlineThree || showHeadlineIndex >= 3"
    >
      <mat-label>{{
        "googleAdEditor.googleAdUploadHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="30"
        type="text"
        formControlName="headlineThree"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.headlineThree ? googleAdPost.headlineThree?.length : 0
        }}
        / 30
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineThree')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineThree')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="googleAdPost.headlineFour || showHeadlineIndex >= 4">
      <mat-label>{{
        "googleAdEditor.googleAdUploadHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="30"
        type="text"
        formControlName="headlineFour"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{ googleAdPost.headlineFour ? googleAdPost.headlineFour?.length : 0 }}
        / 30
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineFour')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineFour')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="googleAdPost.headlineFive || showHeadlineIndex >= 5">
      <mat-label>{{
        "googleAdEditor.googleAdUploadHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="30"
        type="text"
        formControlName="headlineFive"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{ googleAdPost.headlineFive ? googleAdPost.headlineFive?.length : 0 }}
        / 30
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'headlineFive')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'headlineFive')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>

    <div
      class="add-link"
      *ngIf="showHeadlineIndex < 5"
      (click)="showNextHeadline()"
    >
      {{ "googleAdEditor.googleAdAddHeadline" | translate }}
    </div>

    <h3>
      {{ "googleAdEditor.googleAdUploadLongHeadline" | translate }}
      <app-tooltip
        text="{{
          'googleAdEditor.googleAdUploadLongHeadlineToolTip' | translate
        }}"
      ></app-tooltip>
    </h3>
    <mat-form-field>
      <mat-label>{{
        "googleAdEditor.googleAdUploadLongHeadline" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="longHeadline"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{ googleAdPost.longHeadline ? googleAdPost.longHeadline?.length : 0 }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'longHeadline')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'longHeadline')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('required', 'longHeadline')">
        {{ "shared.requiredField" | translate }}
      </mat-error>
    </mat-form-field>

    <h3>
      {{ "googleAdEditor.googleAdUploadDescriptions" | translate }}
      <app-tooltip
        text="{{
          'googleAdEditor.googleAdUploadDescriptionsToolTip' | translate
        }}"
      ></app-tooltip>
    </h3>
    <mat-form-field>
      <mat-label>
        {{ "googleAdEditor.googleAdUploadDescription" | translate }}
      </mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="descriptionOne"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.descriptionOne ? googleAdPost.descriptionOne?.length : 0
        }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'descriptionOne')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'descriptionOne')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('required', 'descriptionOne')">
        {{ "shared.requiredField" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="googleAdPost.descriptionTwo || showDescriptionIndex >= 2"
    >
      <mat-label>{{
        "googleAdEditor.googleAdUploadDescription" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="descriptionTwo"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.descriptionTwo ? googleAdPost.descriptionTwo?.length : 0
        }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'descriptionTwo')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'descriptionTwo')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="googleAdPost.descriptionThree || showDescriptionIndex >= 3"
    >
      <mat-label>
        {{ "googleAdEditor.googleAdUploadDescription" | translate }}
      </mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="descriptionThree"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.descriptionThree
            ? googleAdPost.descriptionThree?.length
            : 0
        }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'descriptionThree')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error
        *ngIf="form.hasError('twoConsecutiveDots', 'descriptionThree')"
      >
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="googleAdPost.descriptionFour || showDescriptionIndex >= 4"
    >
      <mat-label>{{
        "googleAdEditor.googleAdUploadDescription" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="descriptionFour"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.descriptionFour
            ? googleAdPost.descriptionFour?.length
            : 0
        }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'descriptionFour')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'descriptionFour')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="googleAdPost.descriptionFive || showDescriptionIndex >= 5"
    >
      <mat-label>{{
        "googleAdEditor.googleAdUploadDescription" | translate
      }}</mat-label>
      <input
        matInput
        maxlength="90"
        type="text"
        formControlName="descriptionFive"
        [errorStateMatcher]="customErrorStateMatcher"
      />
      <mat-hint align="end"
        >{{
          googleAdPost.descriptionFive ? googleAdPost.descriptionTwo?.length : 0
        }}
        / 90
      </mat-hint>
      <mat-error *ngIf="form.hasError('forbiddenChars', 'descriptionFive')">{{
        getForbiddenCharErrorMessage()
      }}</mat-error>
      <mat-error *ngIf="form.hasError('twoConsecutiveDots', 'descriptionFive')">
        {{ "shared.errorTwoDotsPattern" | translate }}
      </mat-error>
    </mat-form-field>
    <div
      class="add-link"
      *ngIf="showDescriptionIndex < 5"
      (click)="showNextDescription()"
    >
      {{ "googleAdEditor.googleAdAddDescription" | translate }}
    </div>
  </form>
</div>
