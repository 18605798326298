import { Component, Input } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { PreApprovePartnerCampaignInteractor } from "../../../../features/partner-campaign/domain/interactors/pre-approve-partner-campaign.interactor";
import { CampaignPartnerStatus } from "../../../../shared/enums/campaign.enums";
import {
  PartnerCampaign,
  PreApprovalSource,
} from "../../../../shared/models/partnerCampaign";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { cloneWith } from "../../../../shared/services/clonable";
import { NotificationService } from "../../../../shared/services/notification.service";
import { Partner } from "../../../../shared/models/partner";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-header-notice",
  templateUrl: "./partner-campaign-header-notice.component.html",
  styleUrls: ["./partner-campaign-header-notice.component.scss"],
})
export class PartnerCampaignHeaderNoticeComponent {
  @Input({ required: true }) public campaign!: PartnerCampaign;

  constructor(
    private readonly partnerDialogService: PartnerDialogService,
    private readonly preApprovePartnerCampaign: PreApprovePartnerCampaignInteractor,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
  ) {}

  public get isCancelled(): boolean {
    return (
      this.campaign.campaignPartnerStatus === CampaignPartnerStatus.Cancelled
    );
  }

  public get isAcceptInvitation(): boolean {
    return (
      this.campaign.campaignPartnerStatus === CampaignPartnerStatus.Invited
    );
  }

  public get isWaitingForValidation(): boolean {
    return (
      this.campaign.campaignPartnerStatus ===
      CampaignPartnerStatus.PendingValidation
    );
  }

  public get canPreApprove(): boolean {
    if (this.campaign.isBeta) {
      return false;
    }

    return (
      [
        CampaignPartnerStatus.PendingValidation,
        CampaignPartnerStatus.Accepted,
      ].includes(this.campaign.campaignPartnerStatus) &&
      !this.campaign.preApproved
    );
  }

  public get isContentCalendarGenerated(): boolean {
    return (
      this.campaign.isContentCalendarBeingGenerated && !this.campaign.isBeta
    );
  }

  public get isPreApproved(): boolean {
    return this.campaign.preApproved;
  }

  protected async onActionPreApprove(): Promise<void> {
    const source = await this.partnerDialogService.showPreApprove();

    if (!source) {
      return;
    }

    const partner = this.partnerService.currentPartner;

    if (partner.hasAllConnectionsSet) {
      await this.preApproveCampaign(partner, source);
      return;
    }

    const hasPartnerSomeConnection =
      await this.partnerDialogService.showThirdPartyConnectionsCheck({
        context: "pre-approve",
      });

    if (hasPartnerSomeConnection) {
      await this.preApproveCampaign(partner, source);
    }
  }

  private async preApproveCampaign(
    partner: Partner,
    source: PreApprovalSource,
  ): Promise<void> {
    try {
      await this.preApprovePartnerCampaign.execute(
        source,
        partner.id,
        this.campaign.id,
      );

      const currentPartnerCampaign = await lastValueFrom(
        this.partnerCampaignService.getCurrentPartnerCampaign(),
      );

      const updatedPartnerCampaign = cloneWith(currentPartnerCampaign, {
        preApproved: true,
      });

      this.partnerCampaignService.partnerCampaignUpdated.emit(
        updatedPartnerCampaign,
      );

      this.notificationService.success("partner.campaign.preApproved");
    } catch (e) {
      this.notificationService.error("errorDialog.unexpectedError");
    }
  }
}
