<div class="event-publication" [style.border-color]="event().borderColor">
  <img class="thumbnail" [src]="event().extendedProps.image" alt="thumbnail" />
  <div class="data-column">
    <span class="title">{{ event().extendedProps.title | translate }}</span>
    @if (event().extendedProps.summary; as summary) {
      <div class="summary">
        @if (summary.published) {
          <mat-icon class="green">check_circle</mat-icon>
        } @else if (summary.scheduled) {
          <mat-icon class="green">check_circle_outline</mat-icon>
        } @else if (summary.notAccepted) {
          <mat-icon class="gray">schedule</mat-icon>
        } @else if (summary.withIssues) {
          <mat-icon class="red">cancel</mat-icon>
        }
      </div>
    }
  </div>
  <img
    class="network-icon"
    [src]="
      'assets/images/icons/ic_' +
      (event().extendedProps.platform | lowercase) +
      '.svg'
    "
    alt="network-icon"
  />
</div>
