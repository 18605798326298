import { Address } from "../../../shared/models/address";

export class PartnerCampaignPromoteAdSettingsContinueEvent {
  constructor(
    public readonly budget: number,
    public readonly adDuration: number,
    public readonly address: Address,
    public readonly scheduledPublishDate: Date,
    public readonly isDarkPost: boolean,
    public readonly radius: number,
  ) {}
}
