<div class="notice notice--success" *ngIf="isAcceptInvitation">
  <div>
    <p class="notice__text">
      {{ "partner.campaign.header.campaignAcceptInvitationTitle" | translate }}
    </p>
    <p class="notice__text">
      {{
        "partner.campaign.header.campaignAcceptInvitationSubTitle" | translate
      }}
    </p>
  </div>
</div>

<div class="notice notice--warning" *ngIf="isCancelled">
  <div>
    <p class="notice__text">
      {{ "partner.campaign.header.campaignCancelledTitle" | translate }}
    </p>
    <p class="notice__text">
      {{ "partner.campaign.header.campaignCancelledMessage" | translate }}
    </p>
  </div>
</div>

<div class="notice" *ngIf="isContentCalendarGenerated">
  <div>
    <p class="notice__text">
      {{ "partner.campaign.header.generatingContentCalendarTitle" | translate }}
    </p>
    <p class="notice__text">
      {{
        "partner.campaign.header.generatingContentCalendarMessage" | translate
      }}
    </p>
  </div>
</div>

<div class="notice" *ngIf="canPreApprove">
  <p class="notice__text">
    <mat-icon class="notice__icon">check</mat-icon>
    {{ "partner.campaign.header.pendingPartnerApprovalTitle" | translate }}
  </p>
  <button class="notice__link" (click)="onActionPreApprove()">
    {{ "partner.campaign.header.pendingPartnerApprovalMessage" | translate }}
  </button>
</div>

<div class="notice" *ngIf="isWaitingForValidation && isPreApproved">
  <p class="notice__text">
    <mat-icon class="notice__icon">check</mat-icon>
    {{ "partner.campaign.header.pendingPartnerApprovalSentTitle" | translate }}
  </p>
  <div class="status-label">
    <mat-icon class="status-label__icon">check</mat-icon>
    <span class="status-label__text">{{
      "partner.campaign.header.pendingPartnerApprovalSentMessage" | translate
    }}</span>
  </div>
</div>
