import { OverlayModule } from "@angular/cdk/overlay";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule as NgCommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { PartnerCampaignProviderModule } from "../../features/partner-campaign/partner-campaign.providers";
import { AddressBoxComponent } from "../../shared/components/address-box/address-box.component";
import { BudgetPlanComponent } from "../../shared/components/budget-plan/budget-plan.component";
import { CampaignPostStatsComponent } from "../../shared/components/campaign-post-stats/campaign-post-stats.component";
import { SelectorOptionsService } from "../../shared/components/filtering/selector-options.service";
import { GuideTooltipComponent } from "../../shared/components/guide-tooltip/guide-tooltip.component";
import { LoaderComponent } from "../../shared/components/loader/loader.component";
import { PanoramicViewerComponent } from "../../shared/components/panoramic-viewer/panoramic-viewer.component";
import { ReloadComponent } from "../../shared/components/reload/reload.component";
import { SharedComponentsModule } from "../../shared/components/shared-components.module";
import { TooltipComponent } from "../../shared/components/tooltip/tooltip.component";
import { PublicationService } from "../../shared/content/data/publication.service";
import { PublicationProviderModule } from "../../shared/content/domain/publication.provider.module";
import { AngularMaterialModule } from "../../shared/material/material.module";
import { SharedPipesModule } from "../../shared/pipes/shared-pipes.module";
import { PartnerContentComponent } from "../content/pages/partner-content/partner-content.component";
import { PartnerCampaignPublishPostDialogComponent } from "../partner-campaign-publish-post-dialog/partner-campaign-publish-post-dialog.component";
import { PartnerCampaignPublishPostTimePickerComponent } from "../partner-campaign-publish-post-time-picker/partner-campaign-publish-post-time-picker.component";
import { HasCampaignAccessGuard } from "../shared/guards/has-campaign-access.guard";
import { HasPosGuard } from "../shared/guards/has-pos.guard";
import { PartnerCampaignResolverGuard } from "../shared/partner-campaign-resolver.guard";
import { PartnerCampaignBudgetConfirmDialogComponent } from "./partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog.component";
import { PartnerCampaignBudgetEditDialogComponent } from "./partner-campaign-budget-edit-dialog/partner-campaign-budget-edit-dialog.component";
import { PartnerCampaignBudgetPostConfirmDialogComponent } from "./partner-campaign-budget-post-confirm-dialog/partner-campaign-budget-post-confirm-dialog.component";
import { PartnerCampaignBudgetViewDialogComponent } from "./partner-campaign-budget-view-dialog/partner-campaign-budget-view-dialog.component";
import { PartnerCampaignDownloadsComponent } from "./partner-campaign-campaign-downloads/partner-campaign-downloads.component";
import { PartnerCampaignContentCalendarComponent } from "./partner-campaign-content-calendar/partner-campaign-content-calendar.component";
import { PostAcceptanceDialogComponent } from "./partner-campaign-content-calendar/post-acceptance-dialog/post-acceptance-dialog.component";
import { PostsCarouselItemDirective } from "./partner-campaign-content-calendar/post-acceptance-dialog/post-carousel/posts-carousel-item.directive";
import {
  PostsCarouselComponent,
  PostsCarouselItemElementDirective,
} from "./partner-campaign-content-calendar/post-acceptance-dialog/post-carousel/posts-carousel.component";
import { PostRescheduleDialogComponent } from "./partner-campaign-content-calendar/post-acceptance-dialog/post-reschedule-dialog/post-reschedule-dialog.component";
import { CalendarSelectCampaignWarningDialogComponent } from "./partner-campaign-content-calendar/select-campaign-warning-dialog/select-campaign-warning-dialog.component";
import { CalendarSetupStartDialogComponent } from "./partner-campaign-content-calendar/setup-start-dialog/setup-start-dialog.component";
import { PartnerCampaignCreateAdsListComponent } from "./partner-campaign-create-ads-list/partner-campaign-create-ads-list.component";
import { PartnerCampaignDownloadCategoryLinkedInPostComponent } from "./partner-campaign-download-category-linkedin-post/partner-campaign-download-category-linkedin-post.component";
import { PartnerCampaignDownloadCategoryPostComponent } from "./partner-campaign-download-category-post/partner-campaign-download-category-post.component";
import { PartnerCampaignDownloadCategoryComponent } from "./partner-campaign-download-category/partner-campaign-download-category.component";
import { PartnerCampaignFacebookPostEditDialogComponent } from "./partner-campaign-facebook-post-edit-dialog/partner-campaign-facebook-post-edit-dialog.component";
import { PartnerCampaignGoogleAdEditDialogComponent } from "./partner-campaign-google-ad-edit-dialog/partner-campaign-google-ad-edit-dialog.component";
import { PartnerCampaignGoogleAdEditComponent } from "./partner-campaign-google-ad-edit/partner-campaign-google-ad-edit.component";
import { PartnerCampaignGoogleAdTextsEditorComponent } from "./partner-campaign-google-ad-texts-editor/partner-campaign-google-ad-texts-editor.component";
import { PartnerCampaignHeaderNoticeComponent } from "./partner-campaign-header/partner-campaign-header-notice/partner-campaign-header-notice.component";
import { PartnerCampaignHeaderComponent } from "./partner-campaign-header/partner-campaign-header.component";
import { PartnerCampaignLinkedInPostEditDialogComponent } from "./partner-campaign-linkedin-post-edit-dialog/partner-campaign-linkedin-post-edit-dialog.component";
import { PartnerCampaignLinkedInPostEditComponent } from "./partner-campaign-linkedin-post-edit/partner-campaign-linkedin-post-edit.component";
import { PartnerCampaignMissingUrlDialogComponent } from "./partner-campaign-missing-url-dialog/partner-campaign-missing-url-dialog.component";
import { PartnerCampaignNavigationComponent } from "./partner-campaign-navigation/partner-campaign-navigation.component";
import { PartnerCampaignCreateOrderComponent } from "./partner-campaign-order/partner-campaign-create-order/partner-campaign-create-order.component";
import { PartnerCampaignOrderConfirmationDialogComponent } from "./partner-campaign-order/partner-campaign-order-confirmation-dialog/partner-campaign-order-confirmation-dialog.component";
import { PartnerCampaignOrderListComponent } from "./partner-campaign-order/partner-campaign-order-list/partner-campaign-order-list.component";
import { PartnerCampaignOrdersComponent } from "./partner-campaign-order/partner-campaign-orders/partner-campaign-orders.component";
import { CampaignPartnerPostsListPostComponent } from "./partner-campaign-partner-posts/partner-campaign-posts-list-post/partner-campaign-posts-list-post.component";
import { PartnerCampaignPostsListComponent } from "./partner-campaign-partner-posts/partner-campaign-posts-list/partner-campaign-posts-list.component";
import { PartnerCampaignPostsComponent } from "./partner-campaign-partner-posts/partner-campaign-posts.component";
import { PartnerCampaignPostEditComponent } from "./partner-campaign-post-edit/partner-campaign-post-edit.component";
import { PartnerCampaignPostsDialogComponent } from "./partner-campaign-posts-dialog/partner-campaign-posts-dialog.component";
import { PartnerCampaignPostsScheduleTimePickerComponent } from "./partner-campaign-posts-schedule-time-picker/partner-campaign-posts-schedule-time-picker.component";
import { PartnerCampaignPreApproveDialogComponent } from "./partner-campaign-pre-approve-dialog/partner-campaign-pre-approve-dialog.component";
import { PartnerCampaignSummaryComponent } from "./partner-campaign-summary/partner-campaign-summary.component";
import { PartnerCampaignWinnerPostsComponent } from "./partner-campaign-winner-posts/partner-campaign-winner-posts.component";
import { PartnerCampaignComponent } from "./partner-campaign/partner-campaign.component";
import { PartnerCheckThirdPartyConnectionsDialogComponent } from "./partner-check-third-party-connections-dialog/partner-check-third-party-connections-dialog.component";
import { PartnerPostSuggestedTextEditorComponent } from "./partner-post-suggested-text-editor/partner-post-suggested-text-editor.component";
import { PublicationIntentSharedModule } from "./publication-intent/publication-intent-shared.module";
import { SchedulePostDialogComponent } from "./schedule-post-dialog/schedule-post-dialog.component";
import { OrderService } from "./shared/services/order.service";
import { PartnerContentDownloadTrackingService } from "./shared/services/partner-content-download-tracking.service";

export const routes: Routes = [
  {
    path: "",
    component: PartnerCampaignNavigationComponent,
    children: [
      { path: "", redirectTo: "summary", pathMatch: "full" },
      { path: "posts", redirectTo: "content/posts", pathMatch: "full" },
      { path: "content", redirectTo: "content/calendar", pathMatch: "full" },
      {
        path: "",
        component: PartnerCampaignComponent,
        canActivate: [PartnerCampaignResolverGuard()],
        children: [
          {
            path: "summary",
            component: PartnerCampaignSummaryComponent,
          },
          {
            path: "downloads",
            component: PartnerCampaignDownloadsComponent,
            canActivate: [HasCampaignAccessGuard()],
          },
          {
            path: "publications",
            component: PartnerContentComponent,
            canActivate: [HasCampaignAccessGuard()],
          },
          {
            path: "orders",
            component: PartnerCampaignOrdersComponent,
            canActivate: [HasCampaignAccessGuard(), HasPosGuard()],
          },
          {
            path: "content/calendar",
            component: PartnerCampaignContentCalendarComponent,
            canActivate: [HasCampaignAccessGuard()],
          },
          {
            path: "content/posts",
            component: PartnerCampaignPostsComponent,
            canActivate: [HasCampaignAccessGuard()],
          },
          {
            path: "winner-posts",
            component: PartnerCampaignWinnerPostsComponent,
            canActivate: [HasCampaignAccessGuard()],
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [
    PartnerCampaignNavigationComponent,
    PartnerCampaignSummaryComponent,
    PartnerCampaignDownloadsComponent,
    PartnerCampaignHeaderComponent,
    PartnerCampaignHeaderNoticeComponent,
    PartnerCampaignDownloadCategoryComponent,
    PartnerCampaignDownloadCategoryPostComponent,
    PartnerCampaignDownloadCategoryLinkedInPostComponent,
    PartnerCampaignPostEditComponent,
    PartnerCampaignLinkedInPostEditComponent,
    PartnerCampaignGoogleAdEditComponent,
    PartnerCampaignFacebookPostEditDialogComponent,
    PartnerCampaignGoogleAdEditDialogComponent,
    PartnerCampaignLinkedInPostEditDialogComponent,
    PartnerCampaignWinnerPostsComponent,
    SchedulePostDialogComponent,
    PartnerPostSuggestedTextEditorComponent,
    PartnerCampaignGoogleAdTextsEditorComponent,
    PartnerCampaignPostsScheduleTimePickerComponent,
    PartnerCampaignOrdersComponent,
    PartnerCampaignCreateOrderComponent,
    PartnerCampaignOrderListComponent,
    PartnerCampaignPostsDialogComponent,
    PostAcceptanceDialogComponent,
    PartnerCheckThirdPartyConnectionsDialogComponent,
    PostsCarouselComponent,
    PostsCarouselItemDirective,
    PostsCarouselItemElementDirective,
    PostRescheduleDialogComponent,
    PartnerCampaignContentCalendarComponent,
    CalendarSelectCampaignWarningDialogComponent,
    CalendarSetupStartDialogComponent,
    PartnerCampaignPostsComponent,
    PartnerCampaignPostsListComponent,
    PartnerCampaignCreateAdsListComponent,
    PartnerCampaignPublishPostDialogComponent,
    PartnerCampaignPublishPostTimePickerComponent,
    CampaignPartnerPostsListPostComponent,
    PartnerCampaignComponent,
    PartnerCampaignOrderConfirmationDialogComponent,
    PartnerCampaignBudgetEditDialogComponent,
    PartnerCampaignBudgetViewDialogComponent,
    PartnerCampaignBudgetPostConfirmDialogComponent,
    PartnerCampaignBudgetConfirmDialogComponent,
    PartnerCampaignPreApproveDialogComponent,
    PartnerCampaignMissingUrlDialogComponent,
  ],
  imports: [
    PartnerCampaignProviderModule,
    PublicationIntentSharedModule,
    PublicationProviderModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule,
    AngularMaterialModule,
    NgCommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    GoogleMapsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CdkTableModule,
    OverlayModule,
    LoaderComponent,
    BudgetPlanComponent,
    TooltipComponent,
    ReloadComponent,
    GuideTooltipComponent,
    PanoramicViewerComponent,
    AddressBoxComponent,
    CampaignPostStatsComponent,
  ],
  exports: [PartnerCampaignSummaryComponent, PartnerCampaignOrdersComponent],
  providers: [
    OrderService,
    PartnerContentDownloadTrackingService,
    SelectorOptionsService,
    PublicationService,
  ],
})
export class PartnerCampaignModule {}
