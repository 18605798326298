import { PutInteractor } from "../../../../../harmony/core";
import { PreApprovalSource } from "../../../../shared/models/partnerCampaign";
import { PreApprovePartnerCampaignNetworkQuery } from "../../data/queries/pre-approve-partner-campaign-network.query";

export class PreApprovePartnerCampaignInteractor {
  constructor(
    protected readonly preApprovePartnerCampaign: PutInteractor<void>,
  ) {}

  public async execute(
    source: PreApprovalSource,
    partnerId: number,
    campaignId?: number,
  ): Promise<void> {
    await this.preApprovePartnerCampaign.execute(
      undefined,
      new PreApprovePartnerCampaignNetworkQuery(source, partnerId, campaignId),
    );
  }
}
