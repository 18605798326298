import { Component, Input } from "@angular/core";
import { DownloadFileInteractor } from "../../../features/shared/download-file.interactor";
import { PartnerCampaignLinkedInPost } from "../../../shared/models/partnerCampaignLinkedInPost";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";
import { LinkedInPostContentType } from "../../../shared/enums/campaignPost.enums";
import { PartnerContentDownloadTrackingService } from "../shared/services/partner-content-download-tracking.service";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { CreateZipFileInteractor } from "../../../features/shared/create-zip-file.interactor";

@Component({
  selector: "app-partner-campaign-linkedin-post-edit",
  templateUrl: "./partner-campaign-linkedin-post-edit.component.html",
  styleUrls: ["./partner-campaign-linkedin-post-edit.component.scss"],
})
export class PartnerCampaignLinkedInPostEditComponent {
  @Input() campaign!: PartnerCampaign;
  @Input() campaignDetails!: PartnerCampaignDetails;
  @Input() post!: PartnerCampaignLinkedInPost | PartnerLinkedInPostLog;
  @Input() showDownloadIcon = false;

  public readonly ContentType = LinkedInPostContentType;

  constructor(
    private readonly contentDownloadService: PartnerContentDownloadTrackingService,
    private readonly createZipFile: CreateZipFileInteractor,
    private readonly downloadFile: DownloadFileInteractor,
  ) {}

  private async getDownloadUrl(): Promise<string> {
    switch (this.post.mediaType) {
      case LinkedInPostContentType.Image:
        return this.post.image as string;

      case LinkedInPostContentType.MultiImage:
        return URL.createObjectURL(
          await this.createZipFile.execute(
            this.post.images.map((image) => image.url),
          ),
        );

      case LinkedInPostContentType.Video:
        return this.post.videoUrl as string;

      default:
        return "";
    }
  }

  public async download(): Promise<void> {
    this.trackDownload();
    this.downloadFile.execute(await this.getDownloadUrl());
  }

  private trackDownload(): void {
    this.contentDownloadService.trackCampaignContentDownload(this.campaign.id, {
      postId:
        this.post instanceof PartnerCampaignLinkedInPost
          ? this.post.id
          : undefined,
      postLogId:
        this.post instanceof PartnerLinkedInPostLog ? this.post.id : undefined,
    });
  }
}
