<h2 mat-dialog-title class="title">
  <div class="title__section">
    <img class="title__logo" src="/assets/images/google-ad-logo.svg" />
    <span>{{ "shared.googleAd" | translate }}</span>
  </div>
  <div class="title__section">
    <button
      class="btn btn--content-only title__icon"
      *ngIf="postLog.partnerStatus === PostPartnerStatus.Approved"
      (click)="updatePost(PostPartnerStatus.Pending)"
    >
      <img src="/assets/images/un-schedule.svg" />
    </button>
    <button class="btn btn--content-only title__icon" (click)="cancelPost()">
      <img src="/assets/images/trash-bin.svg" />
    </button>
    <button
      class="btn btn--content-only title__icon"
      [mat-dialog-close]="false"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h2>
<mat-dialog-content class="no-padding">
  <div class="post-content-section">
    <img [src]="postLog.image" alt="" />
    <div class="post-content-section-editor">
      <app-partner-campaign-google-ad-texts-editor
        [googleAdPost]="postLog"
        (hasFormError)="hasFormError($event)"
      />
    </div>
  </div>
  <div class="post-settings-section">
    <app-partner-campaign-publish-post-time-picker
      (saveButtonClickedEvent)="handleSaveOrPublishButtonPressed($event)"
      [stickButtonToBottom]="false"
      [publishing]="saving"
      [hasError]="hasError"
      [campaign]="campaign"
      [partner]="partner"
      [postLog]="postLog"
    />
  </div>
</mat-dialog-content>
