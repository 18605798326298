import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerUrl } from "../../partner.url";

export function HasPosGuard(): CanActivateFn {
  return async (route): Promise<boolean> => {
    const router = inject(Router);
    const partnerCampaignService = inject(PartnerCampaignService);

    const campaign = partnerCampaignService.currentCampaign;

    if (
      campaign?.currentDetails?.pos &&
      campaign.currentDetails.pos.length > 0
    ) {
      return true;
    }

    const partnerId = Number(route.parent?.paramMap.get("partnerId"));

    if (!campaign) {
      await router.navigate([PartnerUrl.Home(partnerId)]);
    } else {
      await router.navigate([PartnerUrl.CampaignHome(partnerId, campaign.id)]);
    }

    return false;
  };
}
