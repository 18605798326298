import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";

export class PartnerCampaignLinkedInPostEditDialogData {
  constructor(
    public readonly campaign: PartnerCampaign,
    public readonly partner: Partner,
    public readonly postLog: PartnerLinkedInPostLog,
  ) {}
}
