<div *ngIf="facebookPost" class="post-item">
  <app-partner-campaign-post-edit
    [showDownloadIcon]="true"
    [campaign]="campaign"
    [campaignDetails]="campaignDetails"
    [post]="post"
  />
  <div
    *ngIf="campaign.promoAdsEnabled && campaign.useFacebookAds"
    class="step-container step-container--first"
  >
    <div class="step-title">
      <span class="step-number">2</span>
      <span class="step-text">{{
        "partner.contentArea.promoAds" | translate
      }}</span>
    </div>
    <div *ngIf="!tooManyAds" class="post-step-options">
      <mat-checkbox
        name="create-ad"
        [disabled]="isCreateAdCheckboxDisabled"
        [(ngModel)]="createAd"
      >
        <span
          [ngClass]="{
            'post-step-options-label--disabled': isCreateAdCheckboxDisabled
          }"
          class="post-step-options-label"
        >
          {{ "partner.contentArea.usePromoAds" | translate }}
          <span class="ad-details">({{ adsCreatedString }})</span>
        </span>
      </mat-checkbox>
    </div>
    <div *ngIf="tooManyAds" class="post-step-options">
      <div class="ad-more-budget">
        <div class="ad-more-budget-text">
          {{ "partner.contentArea.maxPromoAdsReached" | translate }}
        </div>
        <div
          *ngIf="!campaign.hasFinished && !isCreateAdCheckboxDisabled"
          (click)="showAdMoreBudgetDialog()"
          class="ad-more-budget-action-text"
        >
          {{ "partner.contentArea.addMoreBudget" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="googleAdPost" class="post-item">
  <app-partner-campaign-google-ad-edit
    [showDownloadIcon]="true"
    [campaign]="campaign"
    [campaignDetails]="campaignDetails"
    [(validForm)]="validForm"
    [post]="post"
  />
  <div *ngIf="campaign.useGoogleAds" class="step-container">
    <div *ngIf="tooManyAds" class="post-step-options">
      <div class="ad-more-budget">
        <div class="ad-more-budget-text">
          {{ "partner.contentArea.maxPromoAdsReached" | translate }}
        </div>
        <div
          *ngIf="!campaign.hasFinished"
          (click)="showAdMoreBudgetDialog()"
          [ngClass]="{
            'ad-more-budget-action-text--disabled':
              isGoogleAdMoreBudgetButtonDisabled
          }"
          class="ad-more-budget-action-text"
        >
          {{ "partner.contentArea.addMoreBudget" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="post-item-footer" *ngIf="facebookPost">
  <button
    class="btn btn--primary"
    (click)="showScheduleFacebookPostDialog()"
    [disabled]="isFacebookPostButtonDisabled()"
  >
    <span>{{ "shared.publishPost" | translate }}</span>
  </button>
</div>

<div
  class="post-item-footer"
  *ngIf="googleAdPost && campaign.useGoogleAds && !tooManyAds"
>
  <button
    class="btn btn--primary"
    (click)="showScheduleAdDialog()"
    [disabled]="isGoogleAdPostButtonDisabled"
  >
    <span>{{ "shared.promoteAd" | translate }}</span>
  </button>
</div>
