import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { StripeCardComponent, StripeService } from "ngx-stripe";
import { Address } from "../../../shared/models/address";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerCampaignGoogleAdPost } from "../../../shared/models/partnerCampaignGoogleAdPost";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PartnerCampaignAdService } from "../../shared/services/partner-campaign-ad.service";
import { LanguageService } from "../../../shared/services/language.service";
import { CreatePaidFacebookAdFromPostData } from "../../../shared/services/parameters/create-paid-facebook-ad-from-post-data";
import { CreatePaidFacebookAdFromPostLogData } from "../../../shared/services/parameters/create-paid-facebook-ad-from-post-log-data";
import { CreatePaidGoogleAdFromPostData } from "../../../shared/services/parameters/create-paid-google-ad-from-post-data";
import { PromoteAdPaymentErrorEvent } from "./partner-campaign-promote-ad-payment-error-event";

@Component({
  selector: "app-partner-campaign-promote-ad-payment",
  templateUrl: "./partner-campaign-promote-ad-payment.component.html",
  styleUrl: "./partner-campaign-promote-ad-payment.component.scss",
})
export class PartnerCampaignPromoteAdPaymentComponent implements OnInit {
  @Input({ required: true }) public address!: Address;
  @Input({ required: true }) public adDuration!: number;
  @Input({ required: true }) public budget!: number;
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public isDarkPost!: boolean;
  @Input({ required: true }) public postToFacebook!: boolean;
  @Input({ required: true }) public postToInstagram!: boolean;
  @Input({ required: true }) public radius!: number;
  @Input({ required: true }) public scheduledPublishDate!: Date;
  @Input() public post?: PartnerCampaignPost;
  @Input() public postLog?: PartnerFacebookPostLog;

  @Output() public goBackEvent = new EventEmitter<void>();
  @Output() public paymentSuccessEvent = new EventEmitter<
    PartnerGoogleAdPostLog | PartnerFacebookPostLog
  >();
  @Output() public paymentErrorEvent =
    new EventEmitter<PromoteAdPaymentErrorEvent>();

  @ViewChild(StripeCardComponent) protected card!: StripeCardComponent;

  protected readonly cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#e07f36",
        color: "#31325F",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#3C3C3C",
        },
      },
    },
    value: {},
    hidePostalCode: true,
  };

  protected elementsOptions: StripeElementsOptions = { locale: "en" };
  protected managementFee!: number;
  protected netAmount!: number;
  protected paymentSuccess = false;
  protected processingPayment = false;
  protected stripeFormGroup!: UntypedFormGroup;
  protected taxAmount!: number;
  protected totalCost!: number;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly languageService: LanguageService,
    private readonly partnerCampaignAdService: PartnerCampaignAdService,
    private readonly stripeService: StripeService,
  ) {}

  public ngOnInit(): void {
    this.stripeFormGroup = this.fb.group({
      // name: ['', [Validators.required]],
    });
    this.elementsOptions.locale = this.languageService.language;
    this.managementFee = +(
      (this.campaign.managementFee * this.budget) /
      100
    ).toFixed(2);
    this.netAmount = this.managementFee + this.budget;
    this.taxAmount = +(
      (this.campaign.adTaxPercentage * this.netAmount) /
      100
    ).toFixed(2);
    this.totalCost = +(this.taxAmount + this.netAmount).toFixed(2);
  }

  // Test card 4242 4242 4242 4242
  // https://stripe.com/docs/payments/3d-secure
  // 3D Secure Test card 4000000000003220
  protected createToken = () => {
    this.processingPayment = true;
    this.partnerCampaignAdService
      .getStripePaymentIntend(this.campaign.id, Math.ceil(this.budget * 100))
      .subscribe({
        next: (clientSecret: string) => {
          this.handleCardPayment(clientSecret);
        },
        error: (e: unknown) => {
          console.error(e);
          this.handlePaymentFailed();
          this.processingPayment = false;
        },
      });
  };

  private handleCardPayment(clientSecret: string) {
    this.stripeService
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card.element,
        },
      })
      .subscribe({
        next: (result: any) => {
          if (
            !result.paymentIntent ||
            result.paymentIntent.status !== "succeeded"
          ) {
            this.processingPayment = false;
            this.handlePaymentFailed();
            return;
          }
          this.createPaidAdFromPost(result, clientSecret);
        },
        error: (e: unknown) => {
          console.error(e);
          this.processingPayment = false;
          this.handlePaymentFailed();
        },
      });
  }

  private createPaidAdFromPost(result: any, clientSecret: string) {
    let paidPostData = null;
    if (this.post) {
      const facebookPost = this.post as PartnerCampaignFacebookPost;
      paidPostData = this.post.isFacebookPost
        ? new CreatePaidFacebookAdFromPostData(
            result.paymentIntent.id,
            clientSecret,
            facebookPost,
            this.adDuration,
            this.address.id,
            this.scheduledPublishDate,
            this.isDarkPost,
            this.radius,
            this.postToFacebook,
            this.postToInstagram,
          )
        : new CreatePaidGoogleAdFromPostData(
            result.paymentIntent.id,
            clientSecret,
            this.post as PartnerCampaignGoogleAdPost,
            this.adDuration,
            this.address.id,
            this.scheduledPublishDate,
            this.radius,
          );
    } else if (this.postLog) {
      paidPostData = new CreatePaidFacebookAdFromPostLogData(
        result.paymentIntent.id,
        clientSecret,
        this.postLog,
        this.adDuration,
        this.address.id,
        this.scheduledPublishDate,
        this.isDarkPost,
        this.radius,
        this.postToFacebook,
        this.postToInstagram,
      );
    }

    this.partnerCampaignAdService
      .createPaidAdFromPost(this.campaign.id, paidPostData!)
      .subscribe({
        next: (postLog: PartnerGoogleAdPostLog | PartnerFacebookPostLog) => {
          this.processingPayment = false;
          this.paymentSuccess = true;
          this.paymentSuccessEvent.emit(postLog);
        },
        error: () => {
          this.handlePaymentFailed();
          this.processingPayment = false;
        },
      });
  }

  private handlePaymentFailed(): void {
    this.paymentErrorEvent.emit({
      errorMessage: "partner.promoteAd.cardPaymentFailed",
    });
  }

  protected goBack(): void {
    this.goBackEvent.emit();
  }
}
