<span class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</span>
<h3 mat-dialog-title>{{ "partner.campaign.postsDialog.title" | translate }}</h3>
<mat-tab-group
  mat-stretch-tabs="false"
  class="create-ad-list"
  [(selectedIndex)]="postsTabsSelectedIndex"
>
  <mat-tab [id]="0" *ngIf="campaign.useFacebookAds">
    <ng-template mat-tab-label>
      <div class="download-tab-label">
        <div class="tab-title">
          <span class="tab-title-text">
            <img src="/assets/images/instagram-post-logo.svg" width="16" />
            <img src="/assets/images/facebook-post-logo.svg" width="16" />
          </span>
          <span class="tab-title-text">{{
            "partner.campaign.postsDialog.facebookPosts" | translate
          }}</span>
        </div>
      </div>
    </ng-template>
    <section>
      <mat-dialog-content class="posts-tab">
        <app-partner-campaign-create-ads-list
          [partner]="partner"
          [campaign]="campaign"
          [platform]="Platform.Facebook"
          [campaignDetails]="campaignDetails"
        />
      </mat-dialog-content>
    </section>
  </mat-tab>
  <mat-tab [id]="1" *ngIf="campaign.useGoogleAds">
    <ng-template mat-tab-label>
      <div class="download-tab-label">
        <div class="tab-title">
          <span class="tab-title-text">
            <img src="/assets/images/google-ad-logo.svg" width="16" />
          </span>
          <span class="tab-title-text">{{
            "partner.campaign.postsDialog.googleAds" | translate
          }}</span>
        </div>
      </div>
    </ng-template>
    <section>
      <mat-dialog-content class="posts-tab">
        <app-partner-campaign-create-ads-list
          [partner]="partner"
          [campaign]="campaign"
          [platform]="Platform.Google"
          [campaignDetails]="campaignDetails"
        />
      </mat-dialog-content>
    </section>
  </mat-tab>
</mat-tab-group>
