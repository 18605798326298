import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";

export class PartnerCampaignBudgetViewDialogData {
  constructor(public readonly campaign: PartnerCampaign) {}
}

@Component({
  selector: "app-partner-campaign-budget-view-dialog",
  templateUrl: "./partner-campaign-budget-view-dialog.component.html",
  styleUrls: ["./partner-campaign-budget-view-dialog.component.scss"],
})
export class PartnerCampaignBudgetViewDialogComponent {
  public readonly campaign: PartnerCampaign;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PartnerCampaignBudgetViewDialogData,
  ) {
    this.campaign = this.data.campaign;
  }
}
