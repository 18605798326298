import { Address } from "./address";
import { SerializableObject } from "./serializable-object";

export enum OrderStatus {
  PENDING = "pending",
  DELIVERED = "delivered",
  COMPLETE = "complete",
  CANCELED = "canceled",
}

export class Order extends SerializableObject {
  id!: number;
  additionalInformation!: string;
  address!: Address;
  date!: string;
  details!: OrderLine[];
  num!: string;
  order_details!: OrderLine[]; // For sending a new order to the API --> We should refactor this.
  status!: OrderStatus;
  total!: number;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "addresses":
        return propertyValue.map((address: any) => new Address(address));
      case "details":
      case "order_details":
        return propertyValue.map((orderLine: any) => new OrderLine(orderLine));
      default:
        return propertyValue;
    }
  }
}

export class OrderLine extends SerializableObject {
  id!: number;
  concept!: string; // Product title
  text!: string; // Selected option text
  price!: string; // Selected option price
}

export class OrderDetailRow extends SerializableObject {
  detailRow!: boolean;
  order!: Order;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "order":
        return propertyValue.map((order: any) => new Order(order));
      default:
        return propertyValue;
    }
  }
}
