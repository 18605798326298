import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass, NgIf } from "@angular/common";

import { PartnerCampaignContentCalendarComponent } from "../../../partner/partner-campaign/partner-campaign-content-calendar/partner-campaign-content-calendar.component";

export type GuideTooltipAction = "close" | "skip" | "next";

@Component({
  selector: "app-guide-tooltip",
  templateUrl: "./guide-tooltip.component.html",
  styleUrl: "./guide-tooltip.component.scss",
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class GuideTooltipComponent implements OnInit {
  @Input({ required: true }) public position!:
    | "top-bar-schedule"
    | "within-calendar-controls"
    | "inside-calendar-day-right"
    | "inside-calendar-day-left";
  @Input({ required: true }) public totalSteps!: number;
  @Input({ required: true }) public currentStep!: number;
  @Input({ required: true }) public title!: string;
  @Input({ required: true }) public description!: string;

  @Input() public bottomIconType?: string;
  @Input() public closeButtonColor: "white" | "orange" = "white";
  @Input() public closeButtonText: "skip" | "close" = "skip";
  @Input() public displayNextButton = true;

  @Output() public readonly toolTipAction =
    new EventEmitter<GuideTooltipAction>();

  protected visible = true;
  protected ICON_TYPE_DRAG_DROP = "ICON_TYPE_DRAG_DROP";

  constructor(
    private readonly element: ElementRef,
    private readonly parent: PartnerCampaignContentCalendarComponent,
  ) {}

  public ngOnInit(): void {
    if (this.visible) {
      this.checkThatTooltipFits();
    }

    this.parent.tutorialStepChanged$.subscribe(() => {
      this.checkThatTooltipFits();
    });
  }

  protected close(): void {
    this.visible = false;
    this.toolTipAction.emit("close");
  }

  protected skip(): void {
    this.visible = false;
    this.toolTipAction.emit("skip");
  }

  private checkThatTooltipFits(): void {
    const rect = this.element.nativeElement.getBoundingClientRect();

    // This is the only case we have for now
    if (
      this.position === "inside-calendar-day-right" &&
      rect.x + 375 > window.innerWidth
    ) {
      // reposition to fit inside window
      this.position = "inside-calendar-day-left";
    }
  }

  protected nextGuideToolTip(): void {
    this.toolTipAction.emit("next");
  }
}
