import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { Address } from "../../shared/models/address";
import { PaymentType } from "../../shared/enums/campaign.enums";
import { PostPartnerStatus } from "../../shared/enums/campaignPost.enums";
import { Partner } from "../../shared/models/partner";
import { PartnerCampaign } from "../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../shared/models/partnerCampaignDetail";
import { PartnerCampaignFacebookPost } from "../../shared/models/partnerCampaignFacebookPost";
import { PartnerFacebookPostLog } from "../../shared/models/partnerFacebookPostLog";
import { PartnerCampaignGoogleAdPost } from "../../shared/models/partnerCampaignGoogleAdPost";
import { PartnerGoogleAdPostLog } from "../../shared/models/partnerGoogleAdPostLog";
import { PartnerCampaignPost } from "../../shared/models/partnerCampaignPost";
import { PostLog } from "../../shared/models/postLog";
import { PartnerCampaignAdService } from "../shared/services/partner-campaign-ad.service";
import { PartnerCampaignPostLogService } from "../shared/services/partner-campaign-post-log.service";
import { DialogService } from "../../shared/services/dialog.service";
import { NotificationService } from "../../shared/services/notification.service";
import { CreateFacebookAdFromPostData } from "../../shared/services/parameters/create-facebook-ad-from-post-data";
import { CreateFacebookAdFromPostLogData } from "../../shared/services/parameters/create-facebook-ad-from-post-log-data";
import { CreateGoogleAdFromPostData } from "../../shared/services/parameters/create-google-ad-from-post-data";
import { UpdateGoogleAdPostLogData } from "../../shared/services/parameters/update-google-ad-post-log-data";
import { PartnerCampaignPromoteAdDialogData } from "./partner-campaign-promote-ad-dialog-data";
import { PromoteAdPaymentErrorEvent } from "./partner-campaign-promote-ad-payment/partner-campaign-promote-ad-payment-error-event";
import { PartnerCampaignPromoteAdSettingsContinueEvent } from "./partner-campaign-promote-ad-settings/partner-campaign-promote-ad-settings-continue-event";
import { getErrorTranslationKey } from "../../shared/functions/get-error-translation-key";
import { PartnerDialogService } from "../partner-dialog.service";

enum PromoteAdStep {
  Settings = "Settings",
  PaymentInfo = "PaymentInfo",
}

@Component({
  selector: "app-partner-campaign-promote-ad-dialog",
  templateUrl: "./partner-campaign-promote-ad-dialog.component.html",
  styleUrl: "./partner-campaign-promote-ad-dialog.component.scss",
})
export class PartnerCampaignPromoteAdDialogComponent {
  public adCreatedOrUpdated = new EventEmitter<PostLog>();

  protected readonly PromoteAdStep = PromoteAdStep;
  protected readonly PaymentType = PaymentType;
  protected adDuration: number;
  protected adRadius: number;
  protected budget: number;
  protected campaign: PartnerCampaign;
  protected campaignDetails?: PartnerCampaignDetails;
  protected isDarkPost: boolean;
  protected partner: Partner;
  protected post?: PartnerCampaignPost;
  protected postLog?: PostLog;
  protected postToFacebook = false;
  protected postToInstagram = false;
  protected promoteAdStep = PromoteAdStep.Settings;
  protected publishing = false;
  protected scheduledPublishDate: Date;
  protected selectedAddress?: Address;

  private redirectToScheduledPosts = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignPromoteAdDialogData,
    private readonly dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignAdService: PartnerCampaignAdService,
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly partnerDialogService: PartnerDialogService,
  ) {
    this.campaign = data.campaign;
    this.partner = data.partner;
    this.redirectToScheduledPosts = data.redirectToScheduledPosts;
    this.campaignDetails = this.campaign.currentDetails;
    this.postToFacebook = data.postToFacebook;
    this.postToInstagram = data.postToInstagram;

    if (data.post instanceof PostLog) {
      this.postLog = data.post;
      this.adDuration =
        this.postLog.hasAd && this.postLog.ad?.adDuration
          ? this.postLog.ad.adDuration
          : this.campaign.adDuration;
    } else if (data.post instanceof PartnerCampaignPost) {
      this.post = data.post;
      this.adDuration = this.campaign.adDuration;
    } else {
      throw new Error("Invalid post type on promote ad dialog");
    }

    this.budget = this.campaign.budget / this.campaign.maxAdsCampaignPartner;
    this.adRadius = this.campaign.adTargetRadius;
    this.isDarkPost =
      this.postLog && this.postLog instanceof PartnerFacebookPostLog
        ? this.postLog.isDarkPost
        : false;
    this.scheduledPublishDate = data.scheduledPublishDateAndTime;
  }

  protected showPaymentStep(
    event: PartnerCampaignPromoteAdSettingsContinueEvent,
  ): void {
    this.budget = event.budget;
    this.selectedAddress = event.address;
    this.adDuration = event.adDuration;
    this.adRadius = event.radius;
    this.isDarkPost = event.isDarkPost;
    this.scheduledPublishDate = event.scheduledPublishDate;
    this.promoteAdStep = PromoteAdStep.PaymentInfo;
  }

  protected showSettingsStep(): void {
    this.promoteAdStep = PromoteAdStep.Settings;
  }

  protected close(): void {
    this.dialog.closeAll();
  }

  protected publishAdConfirm(
    event: PartnerCampaignPromoteAdSettingsContinueEvent,
  ) {
    if (this.campaign.paymentType !== PaymentType.Brand) {
      this.dialogService.confirm({
        title: "partner.promoteAd.confirmWarning",
        message: "partner.promoteAd.confirmDescription",
        onConfirm: () => this.publishAd(event),
      });
    } else {
      this.publishAd(event);
    }
  }

  private publishAd(
    event: PartnerCampaignPromoteAdSettingsContinueEvent,
  ): void {
    this.publishing = true;

    if (this.postLog && this.postLog.isFacebookPost) {
      const facebookPostLog = this.postLog as PartnerFacebookPostLog;
      this.partnerCampaignAdService
        .createFacebookAdFromPostLog(
          this.campaign.id,
          new CreateFacebookAdFromPostLogData(
            facebookPostLog,
            event.address.id,
            event.scheduledPublishDate,
            event.isDarkPost,
            event.radius,
            this.postToFacebook,
            this.postToInstagram,
          ),
        )
        .subscribe({
          next: (postLog) => this.handleCreateAdSuccess(postLog, true),
          error: (e) => this.handleCreateAdError(e.error),
        });
    } else if (this.postLog && this.postLog.isGoogleAd) {
      this.postLog.scheduledPublishDate = event.scheduledPublishDate;
      this.partnerCampaignPostLogService
        .updateCampaignPost(
          this.postLog.id,
          new UpdateGoogleAdPostLogData(
            this.postLog as PartnerGoogleAdPostLog,
            PostPartnerStatus.Approved,
            event.scheduledPublishDate,
          ),
        )
        .subscribe({
          next: (postLog) =>
            this.handleCreateAdSuccess(postLog as PartnerGoogleAdPostLog, true),
          error: (e) => this.handleCreateAdError(e.error),
        });
    } else if (this.post && this.post.isGoogleAd) {
      this.partnerCampaignAdService
        .createGoogleAd(
          this.campaign.id,
          new CreateGoogleAdFromPostData(
            this.post as PartnerCampaignGoogleAdPost,
            event.address.id,
            event.scheduledPublishDate,
            event.radius,
          ),
        )
        .subscribe({
          next: (postLog) => this.handleCreateAdSuccess(postLog, false),
          error: (e) => this.handleCreateAdError(e.error),
        });
    } else if (this.post && this.post.isFacebookPost) {
      const facebookPost = this.post as PartnerCampaignFacebookPost;
      this.partnerCampaignAdService
        .createFacebookAd(
          this.campaign.id,
          new CreateFacebookAdFromPostData(
            facebookPost,
            event.address.id,
            event.scheduledPublishDate,
            event.isDarkPost,
            event.radius,
            this.postToInstagram,
            this.postToFacebook,
          ),
        )
        .subscribe({
          next: (postLog) => this.handleCreateAdSuccess(postLog, false),
          error: (e) => this.handleCreateAdError(e.error),
        });
    }
  }

  protected handleCreateAdSuccess(
    postLog: PartnerGoogleAdPostLog | PartnerFacebookPostLog,
    isPostLogUpdate = false,
  ): void {
    if (!isPostLogUpdate) {
      this.campaign.posts.push(postLog);
    } else {
      this.campaign.posts.map((loopPostLog: PostLog, i: number) => {
        if (loopPostLog.id === postLog.id) {
          this.campaign.posts[i] = loopPostLog;
        }
      });
    }

    this.campaign.totalScheduledAds = this.campaign.totalScheduledAds + 1;
    this.partnerCampaignPostLogService.postLogCreatedOrUpdated.emit(postLog);
    this.adCreatedOrUpdated.emit(postLog);
    this.publishing = false;
    this.dialog.closeAll();
    this.partnerDialogService.showContentPublished({
      postLog: postLog,
      redirectToScheduledPosts: this.redirectToScheduledPosts,
      partnerId: this.partner.id,
    });
  }

  private handleCreateAdError(err?: any): void {
    this.publishing = false;
    const error = err.error;
    const errorDetails =
      getErrorTranslationKey(error?.key) ?? "shared.publishErrorInvalidTime";
    this.notificationService.errorDialog(
      errorDetails,
      "partner.promoteAd.publishAdErrorTitle",
    );
  }

  protected handleCreateAdPaymentError(
    error: PromoteAdPaymentErrorEvent,
  ): void {
    this.notificationService.errorDialog(
      error.errorMessage,
      "partner.promoteAd.publishAdPaymentError",
    );
  }

  protected getBudgetPerAdText(): string {
    if (this.campaign.paymentType === PaymentType.CoFunded) {
      return (
        this.campaign.currencySymbol +
        (this.campaign.budgetPerAd / 2).toFixed(2)
      );
    }
    return this.campaign.currencySymbol + this.campaign.budgetPerAd.toFixed(2);
  }
}
