import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { PostListSidePanelType } from "../../../../shared/enums/campaign.enums";
import { PostLogStatus } from "../../../../shared/enums/campaignPost.enums";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { Partner } from "../../../../shared/models/partner";
import { PostLog } from "../../../../shared/models/postLog";

@Component({
  selector: "app-partner-campaign-posts-list",
  templateUrl: "./partner-campaign-posts-list.component.html",
  styleUrl: "./partner-campaign-posts-list.component.scss",
})
export class PartnerCampaignPostsListComponent implements OnInit, OnChanges {
  @Input({ required: true }) type!: PostLogStatus;
  @Input({ required: true }) posts!: PostLog[];
  @Input({ required: true }) partner!: Partner;
  @Input({ required: true }) campaign!: PartnerCampaign;

  @Output() postLogDeletedEvent = new EventEmitter<void>();
  @Output() postLogCreatedOrUpdated = new EventEmitter<void>();

  protected readonly PostListSidePanelType = PostListSidePanelType;
  protected readonly pageSize = 20;
  protected paginatedPosts: PostLog[] = [];
  protected pageLength = 0;

  public ngOnChanges(): void {
    this.calculatePagination();
  }

  public ngOnInit(): void {
    this.calculatePagination();
  }

  private calculatePagination(): void {
    this.pageLength = this.posts.length;
    this.paginatedPosts = this.posts.slice(0, this.pageSize);
  }

  protected pageChange(pageEvent: PageEvent): void {
    const offset = this.pageSize * pageEvent.pageIndex;
    this.paginatedPosts = this.posts.slice(offset, offset + this.pageSize);
  }
}
