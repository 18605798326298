import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StrictApiRequestService } from "../../../../shared/http/strict-api-request.service";
import { AuthNetworkService } from "../../../../shared/services/api/auth.network.service";

@Injectable()
export class PublicationIntentService {
  private readonly apiRequestService: StrictApiRequestService;
  private readonly COUNT_PENDING_PATH = "/bff/count-pending";
  constructor(
    @Inject("API_GATEWAY_URL")
    API_GATEWAY_URL: string,
    httpClient: HttpClient,
    authNetworkService: AuthNetworkService,
  ) {
    this.apiRequestService = new StrictApiRequestService(
      `${API_GATEWAY_URL}/publication/intents`,
      httpClient,
      authNetworkService,
    );
  }

  public countPendingForCampaignPartner(
    campaignId: number,
    partnerId: number,
  ): Promise<number> {
    return this.apiRequestService
      .path(this.COUNT_PENDING_PATH)
      .setQueryParams({ campaignId: campaignId, publisherId: partnerId })
      .get();
  }
}
