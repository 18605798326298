import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";

import { PartnerCampaignBudgetPostConfirmDialogData } from "./partner-campaign-budget-post-confirm-dialog-data";

@Component({
  selector: "app-partner-campaign-budget-post-confirm-dialog",
  templateUrl: "./partner-campaign-budget-post-confirm-dialog.component.html",
  styleUrls: ["./partner-campaign-budget-post-confirm-dialog.component.scss"],
})
export class PartnerCampaignBudgetPostConfirmDialogComponent {
  public campaign: PartnerCampaign;
  public editBudgetClickEvent: EventEmitter<void>;
  public confirmButtonClickEvent: EventEmitter<void>;
  public saving = false;
  constructor(
    public notificationService: NotificationService,
    public partnerCampaignService: PartnerCampaignService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: PartnerCampaignBudgetPostConfirmDialogData,
  ) {
    this.campaign = this.data.campaign;
    this.editBudgetClickEvent = new EventEmitter();
    this.confirmButtonClickEvent = new EventEmitter();
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  editBudgetDialog(): void {
    this.editBudgetClickEvent.emit();
  }

  confirm(): void {
    this.saving = true;
    this.partnerCampaignService
      .editBudget(
        this.campaign.id,
        this.campaign.totalCampaignBudget,
        this.campaign.standardAdDuration,
        this.campaign.maximumNumberOfAds,
      )
      .subscribe(
        () => {
          this.campaign.hasDefinedCustomBudget = true;
          this.saving = false;
          this.confirmButtonClickEvent.emit();
        },
        () => {
          this.saving = false;
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      );
  }
}
