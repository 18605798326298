<h3 mat-dialog-title>
  {{ "partner.promoteAd.title" | translate }}
  <span class="close-icon" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>
</h3>
<mat-dialog-content class="dialog-content">
  <div class="dialog-content-left">
    <div *ngIf="post" class="post-mockup">
      <app-facebook-post-mock-up
        *ngIf="post.isFacebookPost"
        [noMinHeight]="true"
        [showFooterReactions]="false"
        [showFooterStats]="false"
        [noBorder]="true"
        [partner]="partner"
        [post]="post"
      />
      <app-google-ad-mock-up
        *ngIf="post.isGoogleAd"
        [partner]="partner"
        [noMinHeight]="true"
        [post]="post"
      />
    </div>
    <div *ngIf="postLog" class="post-mockup">
      <app-facebook-post-mock-up
        *ngIf="postLog.isFacebookPost"
        [showFooterReactions]="false"
        [showFooterStats]="false"
        [noMinHeight]="true"
        [noBorder]="true"
        [partner]="partner"
        [post]="postLog"
      />
      <app-google-ad-mock-up
        *ngIf="postLog.isGoogleAd"
        [partner]="partner"
        [noMinHeight]="true"
        [post]="postLog"
      />
    </div>
    <div class="campaign-details">
      <div class="campaign-details-title">
        {{ "partner.promoteAd.campaignDetails" | translate }}
      </div>
      <div
        class="campaign-details-content"
        [innerHTML]="campaignDetails?.fbOtherInformation"
      ></div>
      <div class="campaign-details-title">
        {{ "partner.promoteAd.targetGroup" | translate }}
      </div>
      <div class="campaign-details-content">
        {{ "shared.gender." + campaign.gender | translate }}
      </div>
      <div class="campaign-details-title">
        {{ "partner.promoteAd.targetAge" | translate }}
      </div>
      <div class="campaign-details-content">
        {{ "partner.promoteAd.fromAge" | translate }} {{ campaign.minAge }}
        {{ "partner.promoteAd.toAge" | translate }} {{ campaign.maxAge }}
      </div>
      <div *ngIf="!campaign.hasReachedAdLimit" class="campaign-details-title">
        {{ "partner.promoteAd.maxAdsPerPartner" | translate }}
      </div>
      <div *ngIf="!campaign.hasReachedAdLimit" class="campaign-details-content">
        {{ campaign.maximumNumberOfAds }}
        {{ "partner.promoteAd.ads" | translate }}
      </div>
      <div *ngIf="!campaign.hasReachedAdLimit" class="campaign-details-title">
        {{ "partner.promoteAd.budgetPerAd" | translate }}
      </div>
      <div *ngIf="!campaign.hasReachedAdLimit" class="campaign-details-content">
        <span>{{ getBudgetPerAdText() }}</span>
        <span *ngIf="campaign.paymentType === PaymentType.CoFunded">
          + {{ getBudgetPerAdText() }}
          {{ "partner.promoteAd.onTopFrom" | translate }}
          {{ campaign.brand.companyName }}
        </span>
        <span *ngIf="campaign.paymentType === PaymentType.Brand">
          {{ "partner.promoteAd.from" | translate }}
          {{ campaign.brand.companyName }}
        </span>
      </div>
    </div>
  </div>
  <div class="dialog-content-right">
    <div *ngIf="promoteAdStep === PromoteAdStep.Settings">
      <app-partner-campaign-promote-ad-settings
        (goToPaymentEvent)="showPaymentStep($event)"
        (publishAdEvent)="publishAdConfirm($event)"
        [campaign]="campaign"
        [post]="post"
        [scheduledPublishDate]="scheduledPublishDate"
        [publishing]="publishing"
        [postLog]="postLog"
      />
    </div>
    <div *ngIf="promoteAdStep === PromoteAdStep.PaymentInfo">
      <app-partner-campaign-promote-ad-payment
        (paymentSuccessEvent)="handleCreateAdSuccess($event)"
        (paymentErrorEvent)="handleCreateAdPaymentError($event)"
        (goBackEvent)="showSettingsStep()"
        [campaign]="campaign"
        [address]="selectedAddress"
        [budget]="budget"
        [radius]="adRadius"
        [isDarkPost]="isDarkPost"
        [scheduledPublishDate]="scheduledPublishDate"
        [adDuration]="adDuration"
        [postToFacebook]="postToFacebook"
        [postToInstagram]="postToInstagram"
        [post]="post"
        [postLog]="postLog"
      />
    </div>
  </div>
</mat-dialog-content>
