<h2 mat-dialog-title class="title">
  <div class="title__section">
    <img
      class="title__logo"
      src="/assets/images/icons/linkedin/ic_linkedin.svg"
    />
    <span>{{ "partner.campaign.editFacebookPost.title" | translate }}</span>
  </div>
  <div class="title__section">
    <button
      class="btn btn--content-only title__icon"
      *ngIf="postLog.partnerStatus === PostPartnerStatus.Approved"
      (click)="updatePost(PostPartnerStatus.Pending)"
    >
      <img src="/assets/images/un-schedule.svg" />
    </button>
    <button class="btn btn--content-only title__icon" (click)="cancelPost()">
      <img src="/assets/images/trash-bin.svg" />
    </button>
    <button
      class="btn btn--content-only title__icon"
      [mat-dialog-close]="false"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h2>
<mat-dialog-content class="no-padding">
  <div class="post-content-wrap">
    <div class="post-content-section">
      <div class="post-content-section-images">
        <app-linkedin-multi-image-mock-up
          *ngIf="
            postLog.mediaType === MediaType.MultiImage ||
            postLog.mediaType === MediaType.Image
          "
          [post]="postLog"
        />

        <video
          *ngIf="postLog.mediaType === MediaType.Video"
          style="max-width: 100%"
          src="{{ postLog.videoUrl }}"
          controls
        ></video>
      </div>
      <div class="post-content-section-editor">
        <app-partner-post-suggested-text-editor
          [post]="postLog"
          [showUseSuggestedText]="false"
          [showSuggestedText]="false"
          [showBulletPoint]="false"
        />
      </div>
    </div>
    <div class="post-settings-section">
      <app-partner-campaign-publish-post-time-picker
        (saveButtonClickedEvent)="handleSaveOrPublishButtonPressed($event)"
        [publishing]="saving"
        [campaign]="campaign"
        [partner]="partner"
        [postLog]="postLog"
      />
    </div>
  </div>
</mat-dialog-content>
