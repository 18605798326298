<section *ngIf="campaignDetails">
  <section class="phase">
    <ng-container
      *ngIf="
        campaignDetails.postsPerPartner &&
        campaignDetails.postsPerPartner.length > 0
      "
    >
      <h3>{{ "partner.campaign.winnerPosts.title" | translate }}</h3>
      <p class="section-intro">
        {{ "partner.campaign.winnerPosts.description" | translate }}
      </p>
      <div class="post-list">
        <app-partner-campaign-download-category-post
          *ngFor="let post of campaignDetails.postsPerPartner"
          [campaign]="campaign"
          [partner]="partner"
          [campaignDetails]="campaignDetails"
          [post]="post"
        />
      </div>
    </ng-container>
  </section>
</section>
