import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import dayjs from "dayjs";

import { ToolTipBackground } from "../../../shared/components/tooltip/tooltip.component";
import { Address } from "../../../shared/models/address";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PostLog } from "../../../shared/models/postLog";
import { HOURS_24, MINUTES } from "../../../shared/services/calendar.data";
import { PartnerCampaignPromoteAdSettingsContinueEvent } from "./partner-campaign-promote-ad-settings-continue-event";

@Component({
  selector: "app-partner-campaign-promote-ad-settings",
  templateUrl: "./partner-campaign-promote-ad-settings.component.html",
  styleUrl: "./partner-campaign-promote-ad-settings.component.scss",
})
export class PartnerCampaignPromoteAdSettingsComponent
  implements OnInit, AfterViewChecked
{
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input() public post?: PartnerCampaignPost;
  @Input() public postLog?: PostLog;
  @Input() public publishing = false;
  @Input() public scheduledPublishDate?: Date;

  @Output()
  public goToPaymentEvent =
    new EventEmitter<PartnerCampaignPromoteAdSettingsContinueEvent>();
  @Output()
  public publishAdEvent =
    new EventEmitter<PartnerCampaignPromoteAdSettingsContinueEvent>();

  protected readonly GreyBackground = ToolTipBackground.Grey;
  protected readonly HOURS_24 = HOURS_24;
  protected readonly MINUTES = MINUTES;
  protected budget = 50;
  protected duration = 3;
  protected isDarkPost = false;
  protected maxDate!: Date;
  protected minDate!: Date;
  protected scheduledPublishDateHour!: string;
  protected scheduledPublishDateMinute!: string;
  // MAP
  protected mapCenter!: google.maps.LatLng;
  protected mapRadius = 25;
  protected markers: google.maps.LatLng[] = [];
  protected maxRadiusDistance = 80;
  protected minRadiusDistance = 15;
  protected selectedAddress!: Address;

  private scheduledPublishDateAndTime!: Date;
  private mapInitialized = false;

  @ViewChild(GoogleMap) private map!: GoogleMap;

  public ngOnInit(): void {
    this.mapRadius = this.campaign.adTargetRadius;
    this.selectedAddress = this.campaign.partnerDefaultAddress;
    this.setMapCenterFromSelectedAddress();
    this.markers = this.campaign.partner.addresses.map(
      ({ latitude, longitude }) => new google.maps.LatLng(latitude, longitude),
    );
    this.minDate = this.campaign.startDate;
    this.maxDate = this.campaign.endDate;

    if (this.postLog) {
      if (this.postLog instanceof PartnerFacebookPostLog) {
        this.isDarkPost = this.postLog.isDarkPost;
      }

      if (this.postLog.hasAdProp()) {
        this.mapRadius = this.postLog.ad.radius;
      }

      if (this.scheduledPublishDate) {
        this.setScheduledPublishDate(this.scheduledPublishDate);
      } else {
        this.setScheduledPublishDate(this.postLog.scheduledPublishDate);
        this.scheduledPublishDate = this.postLog.scheduledPublishDate;
      }
    } else if (this.scheduledPublishDate) {
      this.setScheduledPublishDate(this.scheduledPublishDate);
    } else {
      // Unlikely case
      this.scheduledPublishDateAndTime = new Date();
      this.scheduledPublishDateHour = "12";
      this.scheduledPublishDateMinute = "00";
      this.scheduledPublishDate = new Date();
    }

    this.updatePostDate();
  }

  private setScheduledPublishDate(date: Date): void {
    this.scheduledPublishDateAndTime = date;
    this.scheduledPublishDateHour = dayjs(date).format("HH");
    this.scheduledPublishDateMinute = dayjs(date).format("mm");
  }

  public ngAfterViewChecked(): void {
    if (!this.mapInitialized) {
      this.centerMapInAddress();
      this.mapInitialized = true;
    }
  }

  protected hasReachedAdLimitOrPostLogHasAd(): boolean {
    // In case we are editing an existing post log
    // with an existing ad.
    if (this.postLog && this.postLog.hasAd && this.postLog.isPartnerApproved) {
      return false;
    }
    return this.campaign.hasReachedAdLimit;
  }

  protected publishAd(): void {
    this.publishAdEvent.emit(this.getContinueEventData());
  }

  protected goToPayment(): void {
    this.goToPaymentEvent.emit(this.getContinueEventData());
  }

  private getContinueEventData(): PartnerCampaignPromoteAdSettingsContinueEvent {
    return new PartnerCampaignPromoteAdSettingsContinueEvent(
      this.budget,
      this.duration,
      this.selectedAddress,
      this.scheduledPublishDateAndTime,
      this.isDarkPost,
      this.mapRadius,
    );
  }

  private setMapCenterFromSelectedAddress(): void {
    this.mapCenter = new google.maps.LatLng(
      parseFloat(this.selectedAddress.latitude + ""),
      parseFloat(this.selectedAddress.longitude + ""),
    );
  }

  private centerMapInAddress(): void {
    this.setMapCenterFromSelectedAddress();
    this.map.panTo(this.mapCenter);
  }

  public compareWithAddress(addressOne: Address, addressTwo: Address): boolean {
    return addressOne && addressTwo
      ? addressOne.id === addressTwo.id
      : addressOne === addressTwo;
  }

  protected updateDefaultAddress(address: Address): void {
    this.selectedAddress = address;
    this.centerMapInAddress();
  }

  protected printMapAddress(address: Address): string {
    return (
      address.streetAndNumber + " " + address.postalCode + " " + address.city
    );
  }

  protected updatePostDate(): void {
    const newDate = dayjs(this.scheduledPublishDate)
      .hour(+this.scheduledPublishDateHour)
      .minute(+this.scheduledPublishDateMinute)
      .toDate();
    this.scheduledPublishDateAndTime = newDate;
  }

  protected showDarkPostCheckbox(): boolean {
    if (this.postLog && this.postLog.publishedByPlatform) {
      return false;
    }
    return !this.isOrWillBeGoogleAd();
  }

  private isOrWillBeGoogleAd(): boolean {
    if (this.postLog) {
      return this.postLog.isGoogleAd;
    }
    if (this.post) {
      return this.post.isGoogleAd;
    }
    return false;
  }

  protected showTimePicker(): boolean {
    return !this.postLog || !this.postLog.publishedByPlatform;
  }
}
