import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { CustomFormValidators } from "../../../shared/validators/custom-form-validators";
import { CustomErrorStateMatcher } from "../partner-campaign-google-ad-edit/partner-campaign-google-ad-edit.component";

@Component({
  selector: "app-partner-campaign-google-ad-texts-editor",
  templateUrl: "./partner-campaign-google-ad-texts-editor.component.html",
  styleUrls: ["./partner-campaign-google-ad-texts-editor.component.scss"],
})
export class PartnerCampaignGoogleAdTextsEditorComponent implements OnInit {
  @Input({ required: true }) public googleAdPost!: PartnerGoogleAdPostLog;

  @Output() public hasFormError = new EventEmitter<boolean>();

  protected customErrorStateMatcher = new CustomErrorStateMatcher();
  protected form!: UntypedFormGroup;
  protected showDescriptionIndex = 1;
  protected showHeadlineIndex = 1;

  private readonly GOOGLE_ADS_VALIDATORS = [
    CustomFormValidators.googleAdsChars,
    CustomFormValidators.twoConsecutiveDots,
  ];

  constructor(private readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    if (this.googleAdPost.headlineFive) {
      this.showHeadlineIndex = 5;
    } else if (this.googleAdPost.headlineFour) {
      this.showHeadlineIndex = 4;
    } else if (this.googleAdPost.headlineThree) {
      this.showHeadlineIndex = 3;
    } else if (this.googleAdPost.headlineTwo) {
      this.showHeadlineIndex = 2;
    } else {
      this.showHeadlineIndex = 1;
    }

    if (this.googleAdPost.descriptionFive) {
      this.showDescriptionIndex = 5;
    } else if (this.googleAdPost.descriptionFour) {
      this.showDescriptionIndex = 4;
    } else if (this.googleAdPost.descriptionThree) {
      this.showDescriptionIndex = 3;
    } else if (this.googleAdPost.descriptionTwo) {
      this.showDescriptionIndex = 2;
    } else {
      this.showDescriptionIndex = 1;
    }

    this.initForm();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      headlineOne: new UntypedFormControl(this.googleAdPost.headlineOne, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      headlineTwo: new UntypedFormControl(
        this.googleAdPost.headlineTwo,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineThree: new UntypedFormControl(
        this.googleAdPost.headlineThree,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineFour: new UntypedFormControl(
        this.googleAdPost.headlineFour,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      headlineFive: new UntypedFormControl(
        this.googleAdPost.headlineFive,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      longHeadline: new UntypedFormControl(this.googleAdPost.longHeadline, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      descriptionOne: new UntypedFormControl(this.googleAdPost.descriptionOne, [
        Validators.required,
        ...this.GOOGLE_ADS_VALIDATORS,
      ]),
      descriptionTwo: new UntypedFormControl(
        this.googleAdPost.descriptionTwo,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionThree: new UntypedFormControl(
        this.googleAdPost.descriptionThree,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionFour: new UntypedFormControl(
        this.googleAdPost.descriptionFour,
        this.GOOGLE_ADS_VALIDATORS,
      ),
      descriptionFive: new UntypedFormControl(
        this.googleAdPost.descriptionFive,
        this.GOOGLE_ADS_VALIDATORS,
      ),
    });

    this.form.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.hasFormError.emit(!this.form.valid);
        this.updateGoogleAdPost();
      });
  }

  private updateGoogleAdPost(): void {
    const value = (field: string) => this.form.get(field)?.value ?? "";

    this.googleAdPost.headlineOne = value("headlineOne");
    this.googleAdPost.headlineTwo = value("headlineTwo");
    this.googleAdPost.headlineThree = value("headlineThree");
    this.googleAdPost.headlineFour = value("headlineFour");
    this.googleAdPost.headlineFive = value("headlineFive");
    this.googleAdPost.longHeadline = value("longHeadline");
    this.googleAdPost.descriptionOne = value("descriptionOne");
    this.googleAdPost.descriptionTwo = value("descriptionTwo");
    this.googleAdPost.descriptionThree = value("descriptionThree");
    this.googleAdPost.descriptionFour = value("descriptionFour");
    this.googleAdPost.descriptionFive = value("descriptionFive");
  }

  protected showNextHeadline(): void {
    this.showHeadlineIndex++;
  }

  protected showNextDescription(): void {
    this.showDescriptionIndex++;
  }

  protected getForbiddenCharErrorMessage(): string {
    return (
      this.translateService.instant(
        "brand.campaign.content.googleAdUploadCharError",
      ) +
      ": " +
      CustomFormValidators.GOOGLE_ADS_FORBIDDEN_CHARS.join(" , ")
    );
  }
}
