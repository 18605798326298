import { LowerCasePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationWithSummaryEventInput } from "../../../../../shared/content/components/publication-calendar/publication-with-summary-event-input";

@Component({
  standalone: true,
  selector: "app-partner-publication-calendar-event",
  templateUrl: "./partner-publication-calendar-event.component.html",
  styleUrl: "./partner-publication-calendar-event.component.scss",
  imports: [LowerCasePipe, TranslateModule, MatIcon],
})
export class PartnerPublicationCalendarEventComponent {
  public readonly event = input.required<PublicationWithSummaryEventInput>();
}
