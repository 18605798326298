import dayjs from "dayjs";

import { PartnerCampaignGoogleAdPost } from "../../models/partnerCampaignGoogleAdPost";

export class CreateGoogleAdFromPostData {
  public postId: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public descriptionFive: string;
  public descriptionFour: string;
  public descriptionOne: string;
  public descriptionThree: string;
  public descriptionTwo: string;
  public googleAdLogoUrl: string;
  public headlineFive: string;
  public headlineFour: string;
  public headlineOne: string;
  public headlineThree: string;
  public headlineTwo: string;
  public longHeadline: string;
  public radius: number;
  constructor(
    post: PartnerCampaignGoogleAdPost,
    addressId: number,
    scheduledPublishDate: Date,
    radius: number,
  ) {
    this.postId = post.id;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.longHeadline = post.longHeadline;
    this.headlineOne = post.headlineOne;
    this.headlineTwo = post.headlineTwo;
    this.headlineThree = post.headlineThree;
    this.headlineFour = post.headlineFour;
    this.headlineFive = post.headlineFive;
    this.descriptionOne = post.descriptionOne;
    this.descriptionTwo = post.descriptionTwo;
    this.descriptionThree = post.descriptionThree;
    this.descriptionFour = post.descriptionFour;
    this.descriptionFive = post.descriptionFive;
    this.googleAdLogoUrl = post.googleAdLogoUrl;
    this.radius = radius;
  }
}
