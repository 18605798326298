<h2 mat-dialog-title>
  {{ "partner.campaign.viewBudget.title" | translate }}
  <span class="close-icon" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </span>
</h2>
<mat-dialog-content class="content">
  <div class="content-bubble">
    <img src="../../../../assets/images/view-budget-girl-coin.svg" />
    <div class="content-bubble-item">
      {{ "partner.campaign.viewBudget.totalBudget" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.totalCampaignBudget }}{{ campaign.currencySymbol }}</span
      >
    </div>
    <div class="content-bubble-item">
      {{ "partner.campaign.viewBudget.totalAds" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.maximumNumberOfAds }} {{ "shared.ads" | translate }}</span
      >
    </div>
    <div class="content-bubble-item">
      {{ "partner.campaign.viewBudget.maxAds" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.standardAdDuration }} {{ "shared.days" | translate }}</span
      >
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    [mat-dialog-close]="false"
    class="round-button round-button--orange button-left"
  >
    <span>{{ "shared.ok" | translate }}</span>
  </button>
</mat-dialog-actions>
