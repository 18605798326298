import { NgModule } from "@angular/core";
import { PublicationIntentService } from "./data/publication-intent.service";
import { PublicationIntentProviderModule } from "./domain/publication-intent.provider.module";

@NgModule({
  declarations: [],
  imports: [PublicationIntentProviderModule],
  providers: [PublicationIntentService],
})
export class PublicationIntentSharedModule {}
