<h2 mat-dialog-title>
  <span>
    <img
      class="title-icon"
      src="../../../../assets/images/icons/instagram-facebook.svg"
    />
    {{ "partner.campaign.postConfirmBudget.headerTitle" | translate }}
  </span>
  <span class="close-icon" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </span>
</h2>
<mat-dialog-content class="content">
  <div class="content-title">
    {{ "partner.campaign.postConfirmBudget.title" | translate }}
  </div>
  <div class="content-subtitle">
    {{ "partner.campaign.postConfirmBudget.subtitle" | translate }}
  </div>
  <!-- Budget Section -->
  <div class="content-bubble">
    <img src="/assets/images//view-budget-girl-coin.svg" />
    <div class="content-bubble-item">
      {{ "partner.campaign.postConfirmBudget.totalBudget" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.totalCampaignBudget }}{{ campaign.currencySymbol }}</span
      >
    </div>
    <div class="content-bubble-item">
      {{ "partner.campaign.postConfirmBudget.totalAds" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.maximumNumberOfAds }} {{ "shared.ads" | translate }}</span
      >
    </div>
    <div class="content-bubble-item">
      {{ "partner.campaign.postConfirmBudget.maxAds" | translate }}
      <span class="content-bubble-item-amount"
        >{{ campaign.standardAdDuration }} {{ "shared.days" | translate }}</span
      >
    </div>
  </div>
  <div class="content-footer content-footer--first">
    {{ "partner.campaign.postConfirmBudget.footerOne" | translate }}
  </div>
  <div class="content-footer">
    {{ "partner.campaign.postConfirmBudget.footerTwo" | translate }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <a (click)="editBudgetDialog()" class="edit-budget-link">
    {{ "partner.campaign.postConfirmBudget.editBudget" | translate }}
  </a>
  <button
    (click)="closeDialog()"
    class="round-button round-button--grey button-left"
  >
    <span>{{ "shared.cancel" | translate }}</span>
  </button>
  <button
    [disabled]="saving"
    (click)="confirm()"
    class="round-button round-button--orange button-left"
  >
    <span *ngIf="!saving">{{ "shared.ok" | translate }}</span>
    <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
  </button>
</mat-dialog-actions>
