<ng-container *ngFor="let post of paginatedPosts">
  <app-partner-campaign-posts-list-post
    (postLogDeletedEvent)="postLogDeletedEvent.emit()"
    (postLogCreatedOrUpdated)="postLogCreatedOrUpdated.emit()"
    [partner]="partner"
    [campaign]="campaign"
    [type]="type"
    [post]="post"
  />
</ng-container>
<div class="no-posts" *ngIf="paginatedPosts.length === 0">
  {{ "campaign.postList.noPosts" | translate }}
</div>
<mat-paginator
  *ngIf="paginatedPosts.length !== 0"
  class="paginator--centered"
  [hidePageSize]="true"
  [length]="pageLength"
  [pageSize]="pageSize"
  (page)="pageChange($event)"
/>
