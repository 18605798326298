import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  computed,
  input,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AppData } from "../../../../shared/models/appData";
import {
  Order,
  OrderDetailRow,
  OrderStatus,
} from "../../../../shared/models/order";
import { Partner } from "../../../../shared/models/partner";
import { PartnerCampaign } from "../../../../shared/models/partnerCampaign";
import { PartnerUrl } from "../../../partner.url";
import { OrderDataSource } from "./order.data-source";
import { OrderService } from "../../shared/services/order.service";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { getTranslatedCountriesSignal } from "../../../../shared/services/language.service";
import { getDateFormatSignal } from "../../../../shared/services/date.service";

@Component({
  selector: "app-partner-campaign-order-list",
  templateUrl: "./partner-campaign-order-list.component.html",
  styleUrls: ["./partner-campaign-order-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" }),
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"),
      ),
    ]),
  ],
})
export class PartnerCampaignOrderListComponent implements OnInit, OnDestroy {
  public appData = input.required<AppData>();
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public partner!: Partner;

  protected readonly OrderStatus = OrderStatus;
  protected readonly currency = computed(() => this.appData().currency);
  protected readonly getDateFormat = getDateFormatSignal();
  protected readonly isExpansionDetailRow = (
    _: number,
    row: Order | OrderDetailRow,
  ) => row instanceof OrderDetailRow;

  protected displayedColumns = ["date", "number", "total", "status", "details"];
  protected dataSource!: OrderDataSource;

  private readonly countries = getTranslatedCountriesSignal(this.appData);
  private campaignSubscription?: Subscription;
  private campaignDetailsSubscription?: Subscription;

  constructor(
    private readonly orderService: OrderService,
    private readonly router: Router,
    private readonly partnerCampaignService: PartnerCampaignService,
  ) {}

  public ngOnInit(): void {
    this.initData(this.campaign);

    this.campaignSubscription =
      this.partnerCampaignService.partnerCampaignUpdated.subscribe(
        (campaign: PartnerCampaign) => {
          this.initData(campaign);
        },
      );
  }

  public ngOnDestroy(): void {
    this.campaignSubscription?.unsubscribe();
    this.campaignDetailsSubscription?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign) {
    if (!campaign.partnerHasAccess) {
      this.router.navigate([
        PartnerUrl.CampaignHome(this.partner.id, campaign.id),
      ]);
    }

    this.dataSource = new OrderDataSource(
      this.orderService,
      this.partner.id,
      this.campaign.id,
    );
  }

  protected getOrderTotal(order: Order): number {
    return (order.details ?? []).reduce(
      (total, ol) => total + parseFloat(ol.price),
      0,
    );
  }

  protected getCountryName(countryCode: string): string {
    return (
      this.countries().find((c) => c.code === countryCode)?.translatedName ?? ""
    );
  }
}
