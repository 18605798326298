<div class="loader-wrapper">
  <section *ngIf="campaignDetails">
    <h2>{{ "partner.contentArea.title" | translate }}</h2>
    <div class="panel-intro">
      <div class="panel-intro-left">
        <p class="text-dark-grey">
          {{ "partner.contentArea.subtitle" | translate }}
        </p>
      </div>
    </div>
    <mat-tab-group
      mat-stretch-tabs="false"
      [(selectedIndex)]="partnerCampaignService.selectedMenuCategoryIndex"
    >
      <ng-container
        *ngFor="
          let cat of campaignDetails.orderedActiveCategories;
          let indexTwo = index
        "
      >
        <mat-tab [id]="indexTwo">
          <ng-template mat-tab-label>
            <div class="download-tab-label">
              <div class="tab-title">{{ cat.title }}</div>
              <div class="tab-subtitle">{{ cat.subtitle }}</div>
            </div>
          </ng-template>
          <app-partner-campaign-download-category
            [partner]="partner"
            [campaign]="campaign"
            [category]="cat"
          />
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </section>
</div>
