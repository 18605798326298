import dayjs from "dayjs";

import { PartnerFacebookPostLog } from "../../models/partnerFacebookPostLog";

export class CreateFacebookAdFromPostLogData {
  public postLogId: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public radius: number;
  public isDarkPost: boolean;
  public text: string;
  public postToFacebook: boolean;
  public postToInstagram: boolean;
  constructor(
    postLog: PartnerFacebookPostLog,
    addressId: number,
    scheduledPublishDate: Date,
    isDarkPost: boolean,
    radius: number,
    postToFacebook: boolean,
    postToInstagram: boolean,
  ) {
    this.postLogId = postLog.id;
    this.text = postLog.text;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.radius = radius;
    this.isDarkPost = isDarkPost;
    this.postToFacebook = postToFacebook;
    this.postToInstagram = postToInstagram;
  }
}
