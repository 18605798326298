<div class="post-edit-row">
  @if (post.mediaType === ContentType.Image) {
    <img class="post-item-img" [src]="post.image" />
  } @else if (post.mediaType === ContentType.MultiImage) {
    <app-linkedin-multi-image-mock-up [post]="post" />
  } @else if (post.mediaType === ContentType.Video) {
    <video
      class="video"
      src="{{ post.videoUrl }}"
      controls
      controlslist="nofullscreen nodownload"
      oncontextmenu="return false;"
    ></video>
  }

  @if (showDownloadIcon) {
    <button
      class="download-button"
      (click)="download()"
      title="{{ 'shared.download' | translate }}"
    ></button>
  }

  <div class="post-text-editor">
    <app-partner-post-suggested-text-editor
      [showBulletPoint]="false"
      [showUseSuggestedText]="true"
      [showSuggestedText]="false"
      [post]="post"
    />
  </div>
</div>
