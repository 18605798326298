<div
  class="thumbnail"
  [style.background-image]="'url(' + publication().thumbnailUrl + ')'"
></div>
<div class="details">
  <div class="platform">
    @defer {
      <img
        [src]="
          '/assets/images/icons/ic_' + publication().platform + 'Content.svg'
        "
        class="header-icon"
      />
    }
    <span class="publication-type">
      {{
        "publicationTemplatePostContainer." +
          (publication().publicationTemplateType
            | caseTransform: "SnakeToCamel") | translate
      }}
    </span>
  </div>
  <div class="schedules">
    @for (range of publication().recommendedSchedules.ranges; track $index) {
      <app-publication-template-list-item-schedule [schedule]="range" />
    } @empty {
      <div class="date">
        {{ "publicationTemplate.schedule.notScheduledYet" | translate }}
      </div>
    }
    @if (publication().recommendedSchedules.areThereMore) {
      <a href="#">{{ "shared.showMore" | translate }}</a>
    }
  </div>
  @if (publication().intents; as intents) {
    @if (intents.notAccepted?.intents.length) {
      <div class="intents">
        <div class="icon-name title">
          <mat-icon>calendar_today</mat-icon>
          <span>{{
            "publicationTemplate.status.NOT_ACCEPTED" | translate
          }}</span>
        </div>
        @for (
          intent of intents.notAccepted?.intents;
          track intent.publisherId
        ) {
          <div class="intent">
            <div class="chip warning">
              {{ "publicationTemplate.status.NOT_ACCEPTED" | translate }}
            </div>
          </div>
        }
        @if (intents.notAccepted?.areThereMore) {
          <a class="show-more-intents" href="#">
            {{ "shared.showMore" | translate }}
          </a>
        }
      </div>
    }
    @if (intents.published?.intents.length) {
      <div class="intents">
        <div class="icon-name title">
          <mat-icon>calendar_today</mat-icon>
          <span>{{ "publicationTemplate.status.PUBLISHED" | translate }}</span>
        </div>
        @for (intent of intents.published?.intents; track intent.publisherId) {
          <div class="intent">
            <span>{{ intent.date | date: dateTimeFormat() }}</span>
          </div>
        }
        @if (intents.published?.areThereMore) {
          <a class="show-more-intents" href="#">
            {{ "shared.showMore" | translate }}
          </a>
        }
      </div>
    }
    @if (intents.scheduled?.intents.length) {
      <div class="intents">
        <div class="icon-name title">
          <mat-icon>calendar_today</mat-icon>
          <span>{{ "publicationTemplate.status.SCHEDULED" | translate }}</span>
        </div>
        @for (intent of intents.scheduled?.intents; track intent.publisherId) {
          <div class="intent">
            <span>{{ intent.date | date: dateTimeFormat() }}</span>
          </div>
        }
        @if (intents.scheduled?.areThereMore) {
          <a class="show-more-intents" href="#">
            {{ "shared.showMore" | translate }}
          </a>
        }
      </div>
    }
    @if (intents.withIssues?.intents.length) {
      <div class="intents">
        <div class="icon-name title">
          <mat-icon>calendar_today</mat-icon>
          <span>{{ "publicationTemplate.status.WITH_ISSUE" | translate }}</span>
        </div>
        @for (intent of intents.withIssues?.intents; track intent.publisherId) {
          <div class="intent">
            <span>{{ intent.date | date: dateTimeFormat() }}</span>
          </div>
        }
        @if (intents.withIssues?.areThereMore) {
          <a class="show-more-intents" href="#">
            {{ "shared.showMore" | translate }}
          </a>
        }
      </div>
    }
  }
</div>
