<div
  class="address-box address-box--{{ boxSize }}"
  [ngClass]="{
    'address-box--full-height': fullHeight,
    'address-box--loading': isLoading,
    default: isDefaultForTheType(),
    'summary-default': address.isDefault
  }"
>
  <div class="address">
    <div class="header">
      {{ address?.streetAndNumber }}
      <div class="header__actions">
        <div
          class="header__action"
          *ngIf="allowEdition"
          (click)="showEditAddressDialog()"
        >
          <img src="/assets/images/icons/ic_edit_icon_orange.svg" width="16" />
        </div>
        <div
          class="header__action"
          *ngIf="allowDeletion && !address.isDefault"
          (click)="showConfirmDeleteAddress($event)"
        >
          <img src="/assets/images/trash-bin-orange.svg" width="16" />
        </div>
      </div>
    </div>
    <p>{{ address?.postalCode }} {{ address?.city }}</p>
    <p>{{ getCountryName() }}</p>
  </div>
  <div *ngIf="address?.isDefault" class="default-banner">
    {{ "partner.edit.defaultAddress" | translate }}
  </div>
</div>
