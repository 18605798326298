import { PostPartnerStatus } from "../../enums/campaignPost.enums";
import { PartnerLinkedInPostLog } from "../../models/partnerLinkedInPostLog";
import dayjs from "dayjs";

export class UpdateLinkedinPostLogData {
  public readonly scheduledPublishDate: number;
  public readonly text: string;
  constructor(
    postLog: PartnerLinkedInPostLog,
    public readonly partnerStatus?: PostPartnerStatus,
  ) {
    this.scheduledPublishDate = dayjs(postLog.scheduledPublishDate).unix();
    this.text = postLog.text;
  }
}
