<ng-container *ngIf="products">
  <p
    class="subtitle"
    [innerHTML]="'partner.campaign.order.terms' | translate"
  ></p>

  <form
    name="order-form"
    #f="ngForm"
    novalidate
    (ngSubmit)="(orderLines.length > 0)"
    *ngIf="products?.length > 0"
  >
    <h1>{{ "partner.campaign.order.title" | translate }}</h1>
    <p class="subtitle">
      <a (click)="scrollToCard()" class="view-cart-button button button-orange">
        <img
          class="cart-icon"
          src="/assets/images/icons/ic_shopping_cart.svg"
        />
        <span>{{ "partner.campaign.order.viewCart" | translate }}</span
        >&nbsp;
        <span *ngIf="orderLines.length > 0">({{ orderLines.length }})</span>
      </a>
      {{ campaign.currentDetails.posIntroText }}
    </p>
    <ul class="product-list" *ngIf="products?.length > 0">
      <li class="product-item" *ngFor="let product of products">
        <div class="panel-left">
          <div class="product-title">{{ product.title }}</div>
          <p class="product-intro">{{ product.description }}</p>
          <p class="product-select">
            {{ "partner.campaign.order.pleaseSelect" | translate }}
          </p>
          <mat-radio-group
            *ngIf="product.options.length > 0"
            (change)="handleOptionChange(product.id, $event.value)"
          >
            <mat-radio-button class="radio-item" value="none">
              {{ "partner.campaign.order.notDesired" | translate }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-item"
              *ngFor="let option of product.options"
              [value]="option.id"
            >
              {{ option.text }}
              <ng-container *ngIf="option.price && option.price !== '0'">
                - {{ option.price | formatNumber: currency() }}
              </ng-container>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="panel-right">
          <div class="carousel" *ngIf="product.images.length > 0">
            <button
              class="carousel-button-left"
              type="button"
              title="{{ 'partner.campaign.order.moveLeft' | translate }}"
              *ngIf="product.images.length > 2"
              (click)="animateLeft(product.images)"
            >
              &lt;
            </button>
            <ul class="carousel-list">
              <li class="carousel-item" *ngFor="let image of product.images">
                <button
                  class="carousel-item-open-fullscreen"
                  (click)="openFullscreen(image.file)"
                >
                  <span
                    class="carousel-item-img"
                    [ngStyle]="{
                      'background-image': 'url(' + image.file + ')'
                    }"
                  >
                  </span>
                  <ng-container>{{
                    "partner.campaign.order.preview" | translate
                  }}</ng-container>
                </button>
              </li>
            </ul>
            <button
              class="carousel-button-right"
              type="button"
              title="{{ 'partner.campaign.order.moveRight' | translate }}"
              *ngIf="product.images.length > 2"
              (click)="animateRight(product.images)"
            >
              &gt;
            </button>
          </div>
        </div>
        <div
          class="carousel-fullscreen-container"
          *ngIf="fullscreenEnabled"
          (click)="closeFullscreen()"
        >
          <img
            class="carousel-fullscreen-img"
            src="{{ fullscreenImageUrl }}"
            alt=""
          />
          <button class="carousel-close-fullscreen" (click)="closeFullscreen()">
            <ng-container>{{
              "partner.campaign.order.close" | translate
            }}</ng-container>
          </button>
        </div>
      </li>
    </ul>
    <div id="cart" class="order-footer">
      <div class="panel-order-summary">
        <table class="order-summary-table">
          <caption class="order-summary-title" *ngIf="orderLines.length > 0">
            {{
              "partner.campaign.order.yourOrder" | translate
            }}
          </caption>
          <tbody>
            <tr *ngFor="let orderLine of orderLines">
              <td>
                <span class="order-item-amount">{{ orderLine.text }}</span>
                x {{ orderLine.title }}
              </td>
              <td class="order-item-price">
                {{
                  orderLine.price === "0"
                    ? "FREE"
                    : (orderLine.price | formatNumber: currency())
                }}
              </td>
            </tr>
            <tr *ngIf="orderLines.length > 0">
              <td>
                <span class="order-item-amount">{{
                  "partner.campaign.order.total" | translate
                }}</span>
              </td>
              <td class="order-item-price">
                {{ getOrderTotal() | formatNumber: currency() }}
              </td>
            </tr>
            <tr *ngIf="orderLines.length === 0">
              <td>
                <strong>{{
                  "partner.campaign.order.noItems" | translate
                }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          [innerHTML]="campaign.currentDetails.posAdditionalInformation"
        ></div>
      </div>
    </div>
    <div class="panel-order-address">
      <h3>{{ "partner.campaign.order.shippingAddress" | translate }}</h3>
      <app-address-box
        *ngIf="address && partner && countries()"
        [address]="address"
        [partner]="partner"
        [countries]="countries()"
        [mode]="'changeable'"
        [allowEdition]="true"
        [type]="AddressType.Shipping"
      />
      <mat-form-field class="additionalInformation">
        <mat-label>{{
          "partner.campaign.order.additionalInfo" | translate
        }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="order.additionalInformation"
          id="address"
          name="address"
          rows="2"
        >
        </textarea>
      </mat-form-field>
    </div>
    <button
      class="button-submit button-orange"
      [disabled]="orderLines.length === 0"
      (click)="showConfirmationDialog()"
    >
      {{ "partner.campaign.order.placeOrder" | translate }}
    </button>
  </form>
  <form *ngIf="products?.length === 0">
    {{ "partner.campaign.order.noProducts" | translate }}
  </form>
</ng-container>
