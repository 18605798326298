<span class="top-action-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</span>
<span
  *ngIf="!isAPostLogWithAlreadyPaidAd()"
  class="top-action-icon top-action-icon--no-corner"
  (click)="cancelPost()"
>
  <img src="/assets/images/trash-bin.svg" width="16" />
</span>
<span
  *ngIf="
    postLog.partnerStatus === PartnerStatus.Approved &&
    !isAPostLogWithAlreadyPaidAd()
  "
  class="top-action-icon top-action-icon--no-corner"
  (click)="updatePost(PartnerStatus.Pending)"
>
  <img src="/assets/images/un-schedule.svg" width="16" />
</span>
<h2 mat-dialog-title class="header-title">
  <img
    *ngIf="postLog.postToInstagram"
    class="facebook-title-logo"
    src="/assets/images/instagram-post-logo.svg"
    width="16"
  />
  <img
    *ngIf="postLog.postToFacebook"
    class="facebook-title-logo"
    src="/assets/images/facebook-post-logo.svg"
    width="16"
  />
  <span class="header-title-text">{{
    "partner.campaign.editFacebookPost.title" | translate
  }}</span>
</h2>
<mat-dialog-content class="no-padding">
  <div class="post-content-wrap">
    <div class="post-content-section">
      <div
        [ngClass]="{
          'post-content-section-images':
            postLog.mediaType !== ContentType.Carousel,
          'post-content-section-carousel':
            postLog.mediaType === ContentType.Carousel
        }"
      >
        <img
          *ngIf="postLog.isRenderedAsImage"
          class="post-item-img"
          src="{{ getImageUrl(postLog.image, postLog.imagePublicId) }}"
        />
        <div
          *ngIf="postLog.mediaType === ContentType.Image360"
          class="item-img-wrap"
        >
          <app-panoramic-viewer [imageUrl]="postLog.image" />
        </div>
        <app-carousel *ngIf="postLog.mediaType === ContentType.Carousel">
          <div
            carouselItem
            *ngFor="let item of postLog.carouselSlides"
            [image]="getImageUrl(item.url, item.imagePublicId)"
            [text]="item.title"
            [videoUrl]="item.videoUrl"
            [link]="postLog.link"
          ></div>
        </app-carousel>
        <video
          *ngIf="postLog.mediaType === ContentType.Video"
          style="max-width: 100%"
          src="{{ postLog.videoUrl }}"
          controls
        ></video>
      </div>
      <div class="post-content-section-editor">
        <app-partner-post-suggested-text-editor
          [post]="postLog"
          [showUseSuggestedText]="false"
          [showSuggestedText]="false"
          [showBulletPoint]="false"
        />
      </div>
    </div>
    <div class="post-settings-section">
      <app-partner-campaign-publish-post-time-picker
        (saveButtonClickedEvent)="handleSaveOrPublishButtonPressed($event)"
        [showPromoteAdCheckbox]="true"
        [publishing]="saving"
        [campaign]="campaign"
        [partner]="partner"
        [postLog]="postLog"
      />
    </div>
  </div>
</mat-dialog-content>
