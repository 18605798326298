<div class="post-item">
  <app-partner-campaign-linkedin-post-edit
    [showDownloadIcon]="true"
    [campaign]="campaign"
    [campaignDetails]="campaignDetails"
    [post]="post"
  >
  </app-partner-campaign-linkedin-post-edit>
</div>

<div class="post-item-footer">
  <button
    class="btn btn--primary"
    (click)="showScheduleLinkedInPostDialog()"
    [disabled]="isPostButtonDisabled"
  >
    <span>{{ "shared.publishPost" | translate }}</span>
  </button>
</div>
