import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { PartnerUrl } from "../../partner.url";
import { PartnerDialogService } from "../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign",
  templateUrl: "./partner-campaign.component.html",
  styleUrl: "./partner-campaign.component.scss",
})
export class PartnerCampaignComponent implements OnInit {
  protected loading = false;
  protected loadingError = false;
  protected campaign?: PartnerCampaign;
  protected partner: Partner;

  constructor(
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.partner = this.partnerService.currentPartner;
  }

  public ngOnInit(): void {
    this.load();
  }

  protected async load(): Promise<void> {
    this.loading = true;

    try {
      const campaign = await lastValueFrom(
        this.partnerCampaignService.getCurrentPartnerCampaign(true),
      );

      this.campaign = campaign;

      if (
        !campaign.partnerHasAccess &&
        this.route.snapshot.url.some((url) => url.path === "summary")
      ) {
        this.router.navigate([
          PartnerUrl.CampaignHome(this.partner.id, campaign.id),
        ]);
      }

      this.checkMissingUrl(campaign, this.partner);
      this.loadingError = false;
    } catch {
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  private checkMissingUrl(campaign: PartnerCampaign, partner: Partner): void {
    const shouldShowMissingUrlModal =
      campaign &&
      campaign.hasLandingPageRetailerOwnLink &&
      !campaign.partner.shortUrl;

    if (shouldShowMissingUrlModal) {
      this.partnerDialogService.showPartnerCampaignMissingUrl({
        campaign: campaign,
        webPrefix: partner.webUrlPrefix,
        webUrl: partner.webUrl,
      });
    }
  }
}
