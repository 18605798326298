<!-- Partner Map-->
<div class="ad-map">
  <h5 class="ad-map__address-selector__title">
    {{ "partner.promoteAd.location" | translate }}
  </h5>
  <div class="ad-map__address-selector">
    <mat-form-field appearance="outline">
      <mat-select
        [compareWith]="compareWithAddress"
        [(ngModel)]="selectedAddress"
        name="map-partner-address"
      >
        <mat-option
          [value]="address"
          *ngFor="let address of campaign.partner.addresses | sort: 'street'"
          (click)="updateDefaultAddress(address)"
        >
          {{ printMapAddress(address) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <google-map
    *ngIf="mapCenter"
    width="430px"
    height="344px"
    [zoom]="8"
    [options]="{ disableDefaultUI: true }"
    [center]="mapCenter"
  >
    <map-marker
      *ngFor="let markerAddress of markers"
      [options]="{
        icon:
          markerAddress.id === selectedAddress.id
            ? null
            : '/assets/images/icons/ic_orange_marker.svg'
      }"
      [position]="markerAddress"
    />
    <map-circle
      *ngIf="selectedAddress"
      [center]="mapCenter"
      [radius]="mapRadius * 1000"
      [options]="{ fillColor: 'blue', clickable: false }"
    />
  </google-map>
  <h5 class="ad-map__radius__title">
    {{ "partner.promoteAd.radius" | translate }}
  </h5>
  <div class="ad-map__radius">
    <span class="ad-map__radius__amount ad-map__radius__amount--smallest"
      >{{ minRadiusDistance }} Km</span
    >
    <mat-slider
      class="ad-map__radius__slider slider-thumb"
      [discrete]="true"
      [max]="maxRadiusDistance"
      [min]="minRadiusDistance"
      ><input matSliderThumb [(ngModel)]="mapRadius" />
    </mat-slider>
    <span class="ad-map__radius__amount ad-map__radius__amount--biggest"
      >{{ maxRadiusDistance }} Km</span
    >
  </div>
</div>
<div class="ad-settings">
  <div *ngIf="hasReachedAdLimitOrPostLogHasAd()" class="budget-and-duration">
    <div class="budget-and-duration-row">
      <div class="budget-and-duration-row-title">
        {{ "partner.promoteAd.budget" | translate }}&nbsp;
        <app-tooltip
          [background]="GreyBackground"
          text="{{ 'partner.promoteAd.budgetToolTip' | translate }}"
        />
      </div>
      <mat-form-field class="white-field">
        <mat-label>{{
          "partner.promoteAd.budgetPlaceholder" | translate
        }}</mat-label>
        <input min="0" max="2000" [(ngModel)]="budget" matInput type="number" />
        <div matSuffix>{{ campaign.currencySymbol }}</div>
      </mat-form-field>
    </div>
    <div class="budget-and-duration-row">
      <div class="budget-and-duration-row-title">
        {{ "partner.promoteAd.duration" | translate }}&nbsp;
        <app-tooltip
          [background]="GreyBackground"
          text="{{ 'partner.promoteAd.durationToolTip' | translate }}"
        />
      </div>
      <mat-form-field class="white-field">
        <mat-label>{{
          "partner.promoteAd.durationPlaceholder" | translate
        }}</mat-label>
        <input min="0" max="60" [(ngModel)]="duration" matInput type="number" />
        <div matSuffix>{{ "partner.promoteAd.durationDays" | translate }}</div>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!hasReachedAdLimitOrPostLogHasAd()" class="budget-and-duration">
    <div class="budget-and-duration-row">
      <div class="budget-and-duration-row-title">
        {{ "partner.promoteAd.budget" | translate }}&nbsp;
        <app-tooltip
          [background]="GreyBackground"
          text="{{ 'partner.promoteAd.budgetToolTip' | translate }}"
        />
      </div>
      <mat-form-field class="white-field">
        <mat-label>{{
          "partner.promoteAd.budgetPlaceholder" | translate
        }}</mat-label>
        <input
          [disabled]="true"
          [value]="
            postLog && postLog.ad ? postLog.ad.budget : campaign.budgetPerAd
          "
          matInput
          type="number"
        />
        <div matSuffix>{{ campaign.currencySymbol }}</div>
      </mat-form-field>
    </div>
    <div class="budget-and-duration-row">
      <div class="budget-and-duration-row-title">
        {{ "partner.promoteAd.duration" | translate }}&nbsp;
        <app-tooltip
          [background]="GreyBackground"
          text="{{ 'partner.promoteAd.durationToolTip' | translate }}"
        />
      </div>
      <mat-form-field class="white-field">
        <mat-label>{{
          "partner.promoteAd.durationPlaceholder" | translate
        }}</mat-label>
        <input
          [disabled]="true"
          [value]="
            postLog && postLog.ad
              ? postLog.ad.adDuration
              : campaign.standardAdDuration
          "
          matInput
          type="number"
        />
        <div matSuffix>{{ "partner.promoteAd.durationDays" | translate }}</div>
      </mat-form-field>
    </div>
  </div>

  <!--TIME PICKER -->
  <div *ngIf="showTimePicker()" class="time-picker">
    <div class="time-picker-title">
      {{ "partner.promoteAd.pickDate" | translate }}
    </div>
    <div class="time-picker-content">
      <div class="time-picker-content-field">
        <mat-form-field class="time-picker-content-date-field white-field">
          <mat-label>{{
            "partner.promoteAd.pickDatePlaceholder" | translate
          }}</mat-label>
          <input
            [(ngModel)]="scheduledPublishDate"
            [min]="minDate | dayjsTransform"
            [max]="maxDate | dayjsTransform"
            matInput
            [matDatepicker]="picker"
            (dateChange)="updatePostDate()"
            (focus)="picker.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="time-picker-content-field-center">
        <mat-form-field class="time-picker-content-time-field white-field">
          <mat-label>{{ "shared.hours" | translate }}</mat-label>
          <mat-select
            [disabled]="!scheduledPublishDate"
            [(ngModel)]="scheduledPublishDateHour"
            (selectionChange)="updatePostDate()"
          >
            <mat-option *ngFor="let hour of HOURS_24" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="time-picker-content-field-center">
        <mat-form-field class="time-picker-content-time-field white-field">
          <mat-label>{{ "shared.minutes" | translate }}</mat-label>
          <mat-select
            [disabled]="!scheduledPublishDate"
            [(ngModel)]="scheduledPublishDateMinute"
            (selectionChange)="updatePostDate()"
          >
            <mat-option *ngFor="let minute of MINUTES" [value]="minute">
              {{ minute }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!--DARK POST -->
  <div *ngIf="showDarkPostCheckbox()" class="dark-post">
    <mat-checkbox [(ngModel)]="isDarkPost" name="dark-post-check" class="white">
      {{ "partner.promoteAd.setAsDarkPost" | translate }}&nbsp;&nbsp;
      <app-tooltip text="{{ 'partner.promoteAd.darkPostToolTip' | translate }}">
      </app-tooltip>
    </mat-checkbox>
  </div>
</div>
<!--BUTTONS -->
<div class="button-wrapper">
  <button
    *ngIf="hasReachedAdLimitOrPostLogHasAd()"
    class="button-orange button-wrapper-promote-ad"
    (click)="goToPayment()"
  >
    <span>{{
      "partner.promoteAd.adMoreBudgetToPromoteYourPost" | translate
    }}</span>
  </button>
  <button
    *ngIf="!hasReachedAdLimitOrPostLogHasAd()"
    [disabled]="publishing"
    class="button-orange button-wrapper-promote-ad"
    (click)="publishAd()"
  >
    <span *ngIf="!publishing">{{
      "partner.promoteAd.promoteYourPost" | translate
    }}</span>
    <span *ngIf="publishing">{{ "shared.promoting" | translate }}</span>
  </button>
</div>
