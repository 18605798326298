<div class="side-menu">
  <div class="side-menu-content">
    <div class="title">
      {{ "partner.campaign.navigation.campaign" | translate }}
    </div>
    <ul class="menu-list">
      @if (isShown(NavigationItems.Summary)) {
        <li class="menu-item" [routerLinkActive]="'is-active'">
          <a
            data-test="nav-campaign-summary"
            class="menu-item-anchor"
            [routerLink]="['summary']"
            (click)="changeTab()"
          >
            {{ "partner.campaign.navigation.information" | translate }}</a
          >
        </li>
      }
      @if (isShown(NavigationItems.ContentCalendar)) {
        <li class="menu-item" [routerLinkActive]="'is-active'">
          <a
            data-test="nav-content-calendar"
            class="menu-item-anchor"
            [routerLink]="['content/calendar']"
            [ngClass]="{ disabled: hasCampaignAccess(campaign) ? null : true }"
            (click)="changeTab()"
            >{{ "partner.campaign.navigation.contentCalendar" | translate }}</a
          >
        </li>
      }
      @if (isShown(NavigationItems.PostedScheduled)) {
        <li class="menu-item" [routerLinkActive]="'is-active'">
          <a
            data-test="nav-content-posts"
            class="menu-item-anchor"
            [routerLink]="['content/posts']"
            [ngClass]="{ disabled: hasCampaignAccess(campaign) ? null : true }"
            (click)="changeTab()"
            >{{ "partner.campaign.navigation.postedScheduled" | translate }}</a
          >
        </li>
      }
      @if (isShown(NavigationItems.Publications)) {
        <li class="menu-item" [routerLinkActive]="'is-active'">
          <a
            data-test="nav-content-posts"
            class="menu-item-anchor"
            [routerLink]="['publications']"
            (click)="changeTab()"
          >
            {{ "partner.campaign.navigation.publicationTemplates" | translate }}
          </a>
        </li>
      }
      @if (isShown(NavigationItems.DownloadArea)) {
        <li
          class="menu-item menu-item-has-children"
          [routerLinkActive]="'is-active'"
        >
          <a
            data-test="nav-download-area"
            class="menu-item-anchor"
            [routerLink]="['downloads']"
            [ngClass]="{ disabled: hasCampaignAccess(campaign) ? null : true }"
            (click)="changeTab(0)"
            >{{ "partner.campaign.navigation.downloadArea" | translate }}</a
          >
          <ul
            class="sub-menu-list"
            *ngIf="
              campaign?.currentDetails?.orderedActiveCategories &&
              hasCampaignAccess(campaign)
            "
          >
            <ng-container
              *ngFor="
                let cat of campaign?.currentDetails?.orderedActiveCategories;
                let i = index
              "
            >
              <li class="toc-item sub-menu-item">
                <a
                  data-test="nav-downloads"
                  class="toc-anchor sub-menu-item-anchor"
                  [ngClass]="{ active: isCategoryActive(i) }"
                  [routerLink]="['downloads']"
                  (click)="changeTab(i)"
                >
                  {{ cat.title }}
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      }
      @if (isShown(NavigationItems.Winner)) {
        <li
          class="menu-item"
          [routerLinkActive]="'is-active'"
          *ngIf="
            campaignDetails?.postsPerPartner &&
            campaignDetails?.postsPerPartner.length > 0
          "
        >
          <a
            data-test="nav-winner-posts"
            class="menu-item-anchor"
            [routerLink]="['winner-posts']"
            [ngClass]="{ disabled: hasCampaignAccess(campaign) ? null : true }"
            (click)="changeTab()"
            >{{
              "partner.campaign.navigation.winnerAnnouncement" | translate
            }}</a
          >
        </li>
      }
      @if (isShown(NavigationItems.Pos)) {
        <!-- don't allow brand users to navigate to order-navigation -->
        <li class="menu-item" *ngIf="hasPOS()" [routerLinkActive]="'is-active'">
          <a
            data-test="nav-orders"
            class="menu-item-anchor"
            [routerLink]="['orders']"
            [ngClass]="{
              disabled: hasCampaignAccess(campaign) ? !hasPOS() : true
            }"
            (click)="changeTab()"
          >
            {{ "partner.campaign.navigation.posOrders" | translate }}
            <mat-icon class="material-icons" *ngIf="!hasPOS()">block</mat-icon>
          </a>
        </li>
      }
    </ul>
  </div>
</div>
<div class="content">
  <div *ngIf="campaign" class="right-header">
    <app-partner-campaign-header [campaign]="campaign" />
  </div>
  <div class="main">
    <router-outlet />
    <div id="scroll-to-top" *ngIf="isViewingDownloadPage()">
      <a (click)="scrollToTop()">
        <ng-container>{{ "shared.backToTop" | translate }}</ng-container>
        <img src="/assets/images/icons/ic_back_top_arrow_up.svg" />
      </a>
    </div>
  </div>
</div>
