<section class="phase">
  <h2 class="phase-info">
    <div>
      <span class="phase-title">{{ category.title }}</span>
      <span class="phase-subtitle">{{ category.subtitle }}</span>
    </div>
  </h2>
  <ng-container
    *ngIf="partner && campaign && category?.posts && category.posts.length > 0"
  >
    <p *ngIf="hasFacebookPosts" class="section-intro">
      <strong>{{ "partner.contentArea.usageLabel" | translate }}</strong>
      &nbsp;{{ "partner.contentArea.facebookPostsUsage" | translate }}
    </p>
    <p *ngIf="!hasFacebookPosts && hasGooglePosts" class="section-intro">
      <strong>{{ "partner.contentArea.usageLabel" | translate }}</strong>
      &nbsp;{{ "partner.contentArea.googlePostsUsage" | translate }}
    </p>
    <div class="post-list">
      <ng-container *ngFor="let post of category.posts">
        <app-partner-campaign-download-category-post
          *ngIf="!post.isLinkedInPost"
          [campaign]="campaign"
          [partner]="partner"
          [campaignDetails]="campaign?.currentDetails"
          [post]="post"
        />
        <app-partner-campaign-download-category-linkedin-post
          *ngIf="post.isLinkedInPost"
          [campaign]="campaign"
          [partner]="partner"
          [campaignDetails]="campaign?.currentDetails"
          [post]="post"
        />
      </ng-container>
    </div>
  </ng-container>
</section>

<section>
  <ng-container
    *ngIf="
      partner && campaign && category?.videos && category.videos.length > 0
    "
  >
    <h3>{{ "partner.contentArea.videosTitle" | translate }}</h3>
    <p class="section-intro">
      <strong>{{ "partner.contentArea.usageLabel" | translate }}</strong>
      &nbsp;{{ "partner.contentArea.videosSubtitle" | translate }}
    </p>
    <ul class="grid" *ngIf="category.videos">
      <li class="grid__item video-item" *ngFor="let video of category.videos">
        <div class="video-item-panel-title">
          <span class="video-item-title-label">
            {{ "partner.contentArea.videoTitle" | translate }}
          </span>
          {{ video.title }}
          <br />
          <ng-container *ngIf="video.link">
            <span class="video-item-panel-url">
              <strong>{{ "partner.contentArea.videoLink" | translate }}</strong>
            </span>
            {{ video.link }}
            <button
              class="btn--image-only"
              (click)="onCopyLinkAction(video.link)"
            >
              <img
                class="video-item-copy-link"
                src="/assets/images/icons/ic_copy_clipboard.svg"
                title="{{ 'shared.copyLink' | translate }}"
              />
            </button>
          </ng-container>
        </div>
        <video
          *ngIf="video.downloadLink"
          style="width: 100%"
          src="{{ video.downloadLink }}"
          controls
          controlslist="nofullscreen nodownload"
          oncontextmenu="return false;"
        ></video>
        <div class="video-item-image-wrapper" *ngIf="!video.downloadLink">
          <div
            *ngIf="!video.link"
            class="video-item-image-link"
            [ngStyle]="{
              'background-image': 'url(' + getVideoImage(video) + ')'
            }"
          ></div>
          <a
            *ngIf="video.link"
            class="video-item-image-link"
            [ngStyle]="{
              'background-image': 'url(' + getVideoImage(video) + ')'
            }"
            href="{{ video.link }}"
            target="_blank"
          ></a>
        </div>
        <ng-container *ngIf="video.downloadLink">
          <div class="video-item-panel-download">
            <div class="video-item-download-label">
              {{ "partner.contentArea.videoDownloadLabel" | translate }}
            </div>
            <button class="btn btn--grey" (click)="downloadVideo(video)">
              {{ "shared.downloadVideo" | translate }}
            </button>
          </div>
          <div class="video-item-meta">
            {{ video.time }}, {{ video.size }}, {{ video.format }}
          </div>
        </ng-container>
      </li>
    </ul>
  </ng-container>
</section>
<section>
  <ng-container
    *ngIf="
      partner && campaign && category?.images && category.images.length > 0
    "
  >
    <h3>{{ "partner.contentArea.socialMediaTitle" | translate }}</h3>
    <p class="section-intro">
      <strong>{{ "partner.contentArea.usageLabel" | translate }}</strong>
      &nbsp;{{ "partner.contentArea.socialMediaUsage" | translate }}
    </p>
    <section *ngFor="let imageType of category.images">
      <ng-container *ngIf="imageType.files.length > 0">
        <h4>{{ imageType.title }}</h4>
        <!-- TODO a class="anchor-download-all" href="">Download all</a-->
        <ul class="grid">
          <li class="grid__item" #li *ngFor="let file of imageType.files">
            <a
              class="banner-item-download"
              (click)="downloadAsset(file)"
              [ngStyle]="{
                'background-image':
                  'url(' + optimizeImage(file.file, file.filePublicId) + ')'
              }"
            >
              <img
                class="banner-item-icon"
                src="/assets/images/icons/ic_download_icon.svg"
                alt="Download"
              />
              <img
                class="banner-item-img"
                src="{{ optimizeImage(file.file, file.filePublicId) }}"
                #img
                (load)="imageWideRelatedClass(img, li)"
              />
            </a>
          </li>
        </ul>
        <div class="banner-list-empty" *ngIf="imageType.files.length === 0">
          {{ "partner.contentArea.noImages" | translate }}
        </div>
      </ng-container>
    </section>
  </ng-container>
</section>
