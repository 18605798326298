<p class="subtitle">
  <ng-container>{{ "partner.orderList.title" | translate }}</ng-container>
  &nbsp;<a href="mailto:info@socialpals.de">socialPALS</a>.
</p>

<mat-table #table [dataSource]="dataSource">
  <ng-container cdkColumnDef="date">
    <mat-header-cell *cdkHeaderCellDef>{{
      "partner.orderList.date" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{
      row.date | date: getDateFormat()
    }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="number">
    <mat-header-cell *cdkHeaderCellDef>{{
      "partner.orderList.orderNumber" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.num }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="total">
    <mat-header-cell *cdkHeaderCellDef>{{
      "partner.orderList.orderTotal" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.total }}{{ currency() }}</mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="status">
    <mat-header-cell *cdkHeaderCellDef>{{
      "partner.orderList.status" | translate
    }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <label *ngIf="row.status === OrderStatus.PENDING">{{
        "shared.orderStatus.pending" | translate
      }}</label>
      <label *ngIf="row.status === OrderStatus.DELIVERED">{{
        "shared.orderStatus.delivered" | translate
      }}</label>
      <label *ngIf="row.status === OrderStatus.COMPLETE">{{
        "shared.orderStatus.completed" | translate
      }}</label>
      <label *ngIf="row.status === OrderStatus.CANCELED">{{
        "shared.orderStatus.canceled" | translate
      }}</label>
    </mat-cell>
  </ng-container>

  <ng-container cdkColumnDef="details">
    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
    <mat-cell *cdkCellDef="let row"></mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column -->
  <ng-container cdkColumnDef="expandedDetail">
    <mat-cell *cdkCellDef="let detail">
      <table class="order-lines-table">
        <thead>
          <tr>
            <th>{{ "partner.orderList.itemDescription" | translate }}</th>
            <th class="align-right">
              {{ "partner.orderList.quantity" | translate }}
            </th>
            <th class="align-right">
              {{ "partner.orderList.cost" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of detail.order.details">
            <td>{{ item.concept }}</td>
            <td class="align-right">
              {{ item.text !== "0" ? item.text : "-" }}
            </td>
            <td class="align-right">
              {{
                item.price === 0
                  ? "FREE"
                  : (item.price | formatNumber: currency())
              }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="align-right">
              <strong>{{ "partner.orderList.total" | translate }}</strong>
            </td>
            <td class="align-right">
              <strong>{{
                getOrderTotal(detail.order) | formatNumber: currency()
              }}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="address">
        <h3>{{ "partner.orderList.shippingAddress" | translate }}</h3>
        <p>
          {{ detail.order.address.streetAndNumber }}
          <br />
          {{ detail.order.address.city }},
          {{ detail.order.address.postalCode }} <br />
          {{ getCountryName(detail.order.address.country) }}
          {{ detail.order.additional_information }}
        </p>
      </div>
    </mat-cell>
  </ng-container>
  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    matRipple
    class="order-row is-expanded"
  >
  </mat-row>
  <mat-row
    *matRowDef="
      let row;
      columns: ['expandedDetail'];
      when: isExpansionDetailRow
    "
    [@detailExpand]="'expanded'"
    style="overflow: hidden"
  >
  </mat-row>
</mat-table>
