import { Component, Input, OnInit } from "@angular/core";
import { BannerFileEntity } from "../../../shared/entities/file/banner.entity";
import { VideoEntity } from "../../../shared/entities/file/video.entity";
import { NotificationService } from "../../../shared/services/notification.service";
import { ImageService } from "../../../shared/services/image.service";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { copyToClipboard } from "../../../shared/utils/copy-to-clipboard.interactor";
import {
  ICampaignContentDownload,
  PartnerContentDownloadTrackingService,
} from "../shared/services/partner-content-download-tracking.service";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaignCategory } from "../../../shared/models/partnerCampaignCategory";

@Component({
  selector: "app-partner-campaign-download-category",
  templateUrl: "./partner-campaign-download-category.component.html",
  styleUrl: "./partner-campaign-download-category.component.scss",
})
export class PartnerCampaignDownloadCategoryComponent implements OnInit {
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public category!: PartnerCampaignCategory;

  public hasFacebookPosts = false;
  public hasGooglePosts = false;

  constructor(
    private readonly imageService: ImageService,
    private readonly notificationService: NotificationService,
    private readonly contentDownloadService: PartnerContentDownloadTrackingService,
  ) {}

  public ngOnInit(): void {
    if (this.category && this.category.posts) {
      this.hasFacebookPosts = this.category.posts.some(
        (post) => post?.isFacebookPost,
      );
      this.hasGooglePosts = this.category.posts.some(
        (post) => post?.isGoogleAd,
      );
    }
  }

  protected getVideoImage(video: VideoEntity): string {
    if (video.image) {
      return video.image;
    }

    if (video.link) {
      const videoId = video.link.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
      );

      if (videoId) {
        return (
          "https://img.youtube.com/vi/" + videoId[1] + "/maxresdefault.jpg"
        );
      }
    }

    return "";
  }

  protected async onCopyLinkAction(url: string): Promise<void> {
    await copyToClipboard(url);
    this.notificationService.info("shared.copiedToClipboard");
  }

  protected imageWideRelatedClass(img: HTMLImageElement, li: HTMLElement) {
    if (img.clientWidth / img.clientHeight > 2) {
      li.classList.add("grid__item--wide");
    }
  }

  protected optimizeImage(fileUrl: string, imageId: string): string {
    return this.imageService.optimizeImage(fileUrl, imageId);
  }

  protected downloadVideo(video: VideoEntity): void {
    this.trackDownload({
      videoId: video.id,
    });

    window?.open(video.downloadLink, "_blank");
  }

  protected downloadAsset(file: BannerFileEntity): void {
    this.trackDownload({
      imageFileId: file.id,
    });

    window?.open(
      this.imageService.directDownloadURL(file.file, file.filePublicId),
      "_blank",
    );
  }

  private trackDownload(
    campaignContentDownload: ICampaignContentDownload,
  ): void {
    this.contentDownloadService.trackCampaignContentDownload(
      this.campaign.id,
      campaignContentDownload,
    );
  }
}
