import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import dayjs from "dayjs";

import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignGoogleAdPost } from "../../../shared/models/partnerCampaignGoogleAdPost";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { PartnerCampaignBudgetConfirmDialogData } from "../partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog-data";
import { PartnerCampaignBudgetPostConfirmDialogData } from "../partner-campaign-budget-post-confirm-dialog/partner-campaign-budget-post-confirm-dialog-data";
import { PartnerCampaignBudgetPostConfirmDialogComponent } from "../partner-campaign-budget-post-confirm-dialog/partner-campaign-budget-post-confirm-dialog.component";
import { CampaignPostContentType } from "../../../shared/enums/campaignPost.enums";
import { AdActionType } from "../../../shared/enums/campaign.enums";
import { PartnerDialogService } from "../../partner-dialog.service";

enum AfterConnectAction {
  ScheduleFacebookPost,
  SchedulePaidAd,
  ScheduleAd,
}

@Component({
  selector: "app-partner-campaign-download-category-post",
  templateUrl: "./partner-campaign-download-category-post.component.html",
  styleUrl: "./partner-campaign-download-category-post.component.scss",
})
export class PartnerCampaignDownloadCategoryPostComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public campaignDetails!: PartnerCampaignDetails;
  @Input({ required: true }) public post!: PartnerCampaignPost | PostLog;
  @Input({ required: true }) public partner!: Partner;

  protected validForm = true;
  protected facebookPost?: PartnerCampaignFacebookPost;
  protected googleAdPost?: PartnerCampaignGoogleAdPost;
  protected isCreateAdCheckboxDisabled = false;

  private readonly subscription = new Subscription();
  private createAd = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  public ngOnInit(): void {
    if (this.post instanceof PartnerCampaignFacebookPost) {
      this.facebookPost = this.post as PartnerCampaignFacebookPost;
      this.isCreateAdCheckboxDisabled =
        this.campaign.adActionType === AdActionType.Boost &&
        (this.facebookPost.mediaType === CampaignPostContentType.Gif ||
          this.facebookPost.mediaType === CampaignPostContentType.Image360);
    } else if (this.post instanceof PartnerCampaignGoogleAdPost) {
      this.googleAdPost = this.post as PartnerCampaignGoogleAdPost;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected showAdMoreBudgetDialog(): void {
    if (this.isGoogleAdMoreBudgetButtonDisabled || !this.campaign.partner) {
      return;
    }

    if (
      this.campaign.hasLandingPageRetailerOwnLink &&
      this.campaign.partner.isShortUrlMissing
    ) {
      this.showMissingShortUrlModal(AfterConnectAction.SchedulePaidAd);
      return;
    }

    if (this.facebookPost) {
      if (!this.partner.isConnectedToFB) {
        this.showFBConnectionModal();
        return;
      }

      if (
        !this.partner.isConnectedToInstagram &&
        this.facebookPost.postToInstagram &&
        this.facebookPost.canBePostedToInstagram
      ) {
        this.showFBConnectionModal();
        return;
      }

      if (!this.partner.isAuthorizedFBAds) {
        this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }

      this.showScheduleAdDialog();
    } else if (this.googleAdPost) {
      this.showScheduleAdDialog();
    }
  }

  protected showScheduleFacebookPostDialog(): void {
    if (!this.campaign.partner) {
      return;
    }
    if (
      this.campaign.hasLandingPageRetailerOwnLink &&
      this.campaign.partner.isShortUrlMissing
    ) {
      this.showMissingShortUrlModal(AfterConnectAction.ScheduleFacebookPost);
      return;
    }

    if (!this.partner.isConnectedToFB) {
      this.showFBConnectionModal();
      return;
    }

    if (this.createAd) {
      if (!this.partner.isAuthorizedFBAds) {
        this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }
      this.showScheduleAdDialog();
    } else {
      this.partnerDialogService.showPartnerCampaignPublishPost({
        campaign: this.campaign,
        partner: this.partner,
        post: this.post as PartnerCampaignFacebookPost,
      });
    }
  }

  private async openEditBudgetDialog(
    budget?: number,
    adDuration?: number,
    numberOfAds?: number,
  ): Promise<void> {
    const confirmBudgetDialogRef =
      await this.partnerDialogService.showPartnerCampaignBudgetEdit({
        campaign: this.campaign,
        budget: budget,
        adDuration: adDuration,
        numberOfAds: numberOfAds,
      });

    if (confirmBudgetDialogRef) {
      this.subscription.add(
        confirmBudgetDialogRef.componentInstance.backEvent.subscribe(
          (newBudget: PartnerCampaignBudgetConfirmDialogData) => {
            this.openEditBudgetDialog(
              newBudget.budget,
              newBudget.adDuration,
              newBudget.numberOfAds,
            );
          },
        ),
      );
    }

    this.subscription.add(
      this.partnerCampaignService.partnerCampaignBudgetEditedEvent.subscribe(
        () => {
          this.showScheduleAdDialog();
        },
      ),
    );
  }

  private openConfirmBudgetDialog(): void {
    const confirmBudgetDialog = this.dialog.open(
      PartnerCampaignBudgetPostConfirmDialogComponent,
      {
        width: "784px",
        data: new PartnerCampaignBudgetPostConfirmDialogData(this.campaign),
        disableClose: true,
      },
    );

    this.subscription.add(
      confirmBudgetDialog.componentInstance.confirmButtonClickEvent.subscribe(
        () => {
          confirmBudgetDialog.close();
          this.showScheduleAdDialog();
        },
      ),
    );

    this.subscription.add(
      confirmBudgetDialog.componentInstance.editBudgetClickEvent.subscribe(
        () => {
          confirmBudgetDialog.close();
          this.openEditBudgetDialog();
        },
      ),
    );
  }

  protected showScheduleAdDialog(): void {
    if (!this.campaign.partner) {
      return;
    }

    // Check if partner has to define budget but have not yet
    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      this.openConfirmBudgetDialog();
      return;
    }

    if (
      this.campaign.hasLandingPageRetailerOwnLink &&
      this.campaign.partner.isShortUrlMissing
    ) {
      this.showMissingShortUrlModal(AfterConnectAction.ScheduleAd);
      return;
    }

    let postToFacebook = false;
    let postToInstagram = false;
    if (this.post.isFacebookPost) {
      const facebookPost = this.post as PartnerCampaignFacebookPost;
      postToFacebook = facebookPost.postToFacebook;
      postToInstagram = facebookPost.canBePostedToInstagram
        ? facebookPost.postToInstagram
        : false;
    }

    this.dialog.open(PartnerCampaignPromoteAdDialogComponent, {
      data: new PartnerCampaignPromoteAdDialogData(
        this.campaign,
        this.post,
        dayjs().add(1, "hours").toDate(),
        this.partner,
        true,
        postToFacebook,
        postToInstagram,
      ),
      disableClose: true,
    });
  }

  private async showMissingShortUrlModal(
    afterConnectAction: AfterConnectAction,
  ): Promise<void> {
    const campaignPartner =
      await this.partnerDialogService.showInvalidCampaignShortUrl({
        campaign: this.campaign,
      });

    if (campaignPartner) {
      this.campaign.partner.shortUrl = campaignPartner.shortUrl;
      switch (afterConnectAction) {
        case AfterConnectAction.ScheduleFacebookPost:
          this.showScheduleFacebookPostDialog();
          break;
        case AfterConnectAction.SchedulePaidAd:
          this.showScheduleFacebookPostDialog();
          break;
        case AfterConnectAction.ScheduleAd:
          this.showScheduleAdDialog();
          break;
      }
    }
  }

  private async showFBConnectionModal(): Promise<void> {
    await this.partnerDialogService.showFacebookNotConnected({
      partner: this.partner,
    });
  }

  public get tooManyAds(): boolean {
    if (!this.campaign) {
      return true;
    }
    return !this.campaign.canPromoteMoreAds;
  }

  public get adsCreatedString(): string {
    if (!this.campaign) {
      return "";
    }
    return `${this.campaign.numberOfAdsCreated} of ${this.campaign.maximumNumberOfAds}`;
  }

  protected isFacebookPostButtonDisabled(): boolean {
    return (
      !this.campaign || !this.facebookPost?.text || this.campaign?.hasFinished
    );
  }

  public get isGoogleAdPostButtonDisabled(): boolean {
    return (
      !this.campaign ||
      !this.partner ||
      !this.partner.areGoogleAdsAuthorized ||
      !this.googleAdPost?.headlineOne ||
      !this.validForm ||
      this.campaign?.hasFinished
    );
  }

  public get isGoogleAdMoreBudgetButtonDisabled(): boolean {
    return (
      !this.campaign ||
      !this.partner ||
      !this.partner.areGoogleAdsAuthorized ||
      !this.validForm ||
      this.campaign?.hasFinished
    );
  }
}
