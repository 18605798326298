<div class="loader-wrapper">
  <ng-container *ngIf="campaign">
    <div class="promo-ads-top-bar" *ngIf="campaign">
      <div class="promo-ads-top-bar__campaign-info">
        <ul>
          <li>
            <p>
              <img
                class="info-image"
                src="/assets/images/icons/ads-icon-orange.svg"
                width="19"
              />
              <span
                matTooltip="{{
                  'partner.campaign.postList.totalPromoAdsAvailable' | translate
                }}"
                [matTooltipClass]="'custom-tooltip--large-text'"
                class="dark"
              >
                {{ "partner.campaign.postList.totalPromoAds" | translate }}
              </span>
              <b class="orange">
                {{ campaign.totalNonPaidAds }}
                {{ "shared.xAdsOfy" | translate }}
                {{ campaign.maximumNumberOfAds }}
                <span *ngIf="campaign.totalPaidAds > 0">
                  {{
                    "partner.campaign.postList.andExtraPaidAds"
                      | translate
                        : {
                            total: campaign.totalPaidAds
                          }
                  }}
                </span>
              </b>
            </p>
          </li>
          <li>
            <p>
              <img
                class="info-image"
                src="/assets/images/dollar-icon-orange.svg"
                width="19"
              />
              <span
                matTooltip="{{
                  'partner.campaign.postList.totalBudgetAvailable' | translate
                }}"
                [matTooltipClass]="'custom-tooltip--large-text'"
                class="dark"
              >
                {{ "partner.campaign.postList.totalBudgetLeft" | translate }}
              </span>
              <b class="orange">
                {{ campaign.currencySymbol }}
                {{ campaign.budgetLeftFromBrand }}
                {{ "shared.xAdsOfy" | translate }}
                {{ campaign.currencySymbol }}
                {{ campaign.budgetCampaignFromBrand }}
                <span *ngIf="campaign.budgetPaidByPartner > 0">
                  {{
                    "partner.campaign.postList.andExtraPaidBudget"
                      | translate
                        : {
                            budget: campaign.budgetPaidByPartner,
                            currency: campaign.currencySymbol
                          }
                  }}
                </span>
              </b>
            </p>
          </li>
          <li *ngIf="campaign.audiences">
            <img
              class="info-image"
              src="/assets/images/information-orange.svg"
              width="19"
            />
            <span [innerHTML]="campaign.audiences + '&nbsp;'"></span>
          </li>
        </ul>
      </div>
      <div
        *ngIf="campaign.promoAdsEnabled && !campaign.hasFinished"
        class="promo-ads-top-bar__budget-info"
      >
        <button
          *ngIf="campaign.useFacebookAds || campaign.useGoogleAds"
          class="round-button round-button--orange promo-ads-button"
          (click)="newAd()"
        >
          <b>{{ "partner.campaign.postList.newPromoAd" | translate }}</b>
          ({{ campaign.totalNonPaidAds }} {{ "shared.xAdsOfy" | translate }}
          {{ campaign.maximumNumberOfAds }})
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col posts-wrapper">
        <mat-tab-group
          mat-stretch-tabs="false"
          [(selectedIndex)]="postsTabsSelectedIndex"
        >
          <mat-tab [id]="0">
            <ng-template mat-tab-label>
              <div class="download-tab-label">
                <div class="tab-title">
                  {{ "partner.campaign.postList.published" | translate }}
                  <span *ngIf="!loading"
                    >({{ campaign ? campaign.totalPublishedPostLogs : 0 }}
                    {{ "shared.posts" | translate }} /
                    {{ campaign ? campaign.totalPublishedAds : 0 }}
                    {{ "shared.ads" | translate }})
                  </span>
                </div>
              </div>
            </ng-template>
            <section *ngIf="!reloading">
              <p class="text-dark-grey">
                {{ "partner.campaign.postList.publishedInfo" | translate }}
              </p>
              <app-partner-campaign-posts-list
                (postLogCreatedOrUpdated)="refreshCampaign()"
                (postLogDeletedEvent)="refreshCampaign()"
                [type]="PostLogStatus.Published"
                [posts]="publishedPosts"
                [partner]="partner"
                [campaign]="campaign"
              />
            </section>
          </mat-tab>
          <mat-tab [id]="1">
            <ng-template mat-tab-label>
              <div class="download-tab-label">
                <div class="tab-title">
                  {{ "partner.campaign.postList.scheduled" | translate }}
                  <span *ngIf="!loading">
                    ({{ campaign ? campaign?.totalScheduledPostLogs : 0 }}
                    {{ "shared.posts" | translate }} /
                    {{ campaign ? campaign?.totalScheduledAds : 0 }}
                    {{ "shared.ads" | translate }})
                  </span>
                </div>
              </div>
            </ng-template>
            <section *ngIf="!reloading">
              <p class="text-dark-grey">
                {{ "partner.campaign.postList.scheduledInfo" | translate }}
              </p>
              <p class="text-dark-grey"></p>
              <app-partner-campaign-posts-list
                (postLogCreatedOrUpdated)="refreshCampaign()"
                (postLogDeletedEvent)="refreshCampaign()"
                [type]="PostLogStatus.Scheduled"
                [posts]="scheduledPosts"
                [partner]="partner"
                [campaign]="campaign"
              />
            </section>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-container>
  <app-loader
    [text]="'shared.loadingCampaign' | translate"
    [ngClass]="{ visible: loading }"
  ></app-loader>
</div>
