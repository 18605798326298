import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import {
  LinkedInPostContentType,
  PostPartnerStatus,
} from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UpdateLinkedinPostLogData } from "../../../shared/services/parameters/update-linkedin-post-log-data";
import { SavePostLogData } from "../../partner-campaign-publish-post-time-picker/save-post-log-data";
import { PartnerCampaignLinkedInPostEditDialogData } from "./partner-campaign-linkedin-post-edit-dialog-data";
import { PostEditDialog } from "../shared/post-edit-dialog";
import { PartnerDialogService } from "../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-linkedin-post-edit-dialog",
  templateUrl: "./partner-campaign-linkedin-post-edit-dialog.component.html",
  styleUrl: "./partner-campaign-linkedin-post-edit-dialog.component.scss",
})
export class PartnerCampaignLinkedInPostEditDialogComponent
  implements PostEditDialog<PartnerLinkedInPostLog>
{
  public readonly campaignPostLogUpdatedEvent =
    new EventEmitter<PartnerLinkedInPostLog>();
  public readonly postRemoved = new EventEmitter<PartnerLinkedInPostLog>();

  protected readonly PostPartnerStatus = PostPartnerStatus;
  protected readonly MediaType = LinkedInPostContentType;
  protected partner: Partner;
  protected campaign: PartnerCampaign;
  protected postLog: PartnerLinkedInPostLog;
  protected saving = false;

  constructor(
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignLinkedInPostEditDialogData,
  ) {
    this.campaign = data.campaign;
    this.partner = data.partner;
    this.postLog = data.postLog;
  }

  protected async handleSaveOrPublishButtonPressed(
    postLogData: SavePostLogData,
  ): Promise<void> {
    if (!this.partner.isConnectedToLinkedIn) {
      const result = await this.partnerDialogService.showLinkedInNotConnected({
        partnerId: this.partner.id,
      });

      if (result) {
        const partner = await lastValueFrom(
          this.partnerService.fetchCurrentPartner(),
        );

        this.partner = partner!;

        return;
      }
    }

    this.updatePost(PostPartnerStatus.Approved, postLogData);
  }

  protected updatePost(
    partnerStatus?: PostPartnerStatus,
    partnerCampaignSaveButtonPressed?: SavePostLogData,
  ): void {
    this.saving = true;
    if (partnerCampaignSaveButtonPressed) {
      this.postLog.scheduledPublishDate =
        partnerCampaignSaveButtonPressed.scheduledPublishDateAndTime;
    }

    if (!this.partner.isConnectedToLinkedIn) {
      this.postLog.partnerStatus = PostPartnerStatus.Pending;
    } else if (partnerStatus) {
      this.postLog.partnerStatus = partnerStatus;
    }

    this.partnerCampaignPostLogService
      .updateCampaignPost(
        this.postLog.id,
        new UpdateLinkedinPostLogData(this.postLog, this.postLog.partnerStatus),
      )
      .subscribe({
        next: (updatedPost) => {
          this.postLog = updatedPost as PartnerLinkedInPostLog;
          this.saving = false;
          this.dialog.closeAll();
          this.notificationService.success("shared.changesSavedSuccessfully");
          this.campaignPostLogUpdatedEvent.emit(this.postLog);
        },
        error: () => {
          this.saving = false;
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      });
  }

  protected async cancelPost(): Promise<void> {
    const isDeleted = await this.partnerDialogService.showDeletePost({
      post: this.postLog,
    });

    if (isDeleted) {
      this.postRemoved.emit(this.postLog);
    }
  }
}
