import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PostPlatform } from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PublishPostData } from "../../partner-campaign-publish-post-time-picker/publish-post-data";
import { PartnerDialogService } from "../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-create-ads-list",
  templateUrl: "./partner-campaign-create-ads-list.component.html",
  styleUrl: "./partner-campaign-create-ads-list.component.scss",
})
export class PartnerCampaignCreateAdsListComponent implements OnInit {
  @Input({ required: true }) public partner!: Partner;
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public campaignDetails!: PartnerCampaignDetails;
  @Input({ required: true }) public platform!: PostPlatform;

  public posts: PartnerCampaignPost[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  public ngOnInit(): void {
    this.posts = [];

    this.campaign.details.forEach((campaignDetail) => {
      campaignDetail.categories.forEach((category) => {
        category.posts.forEach((post) => {
          if (this.platform === post.platform) {
            post.willBecomeAd = true;
            this.posts.push(post);
          }
        });
      });
    });
  }

  private showFBConnectionModal(): void {
    this.partnerDialogService.showFacebookNotConnected({
      partner: this.partner,
      isReconnect: true,
    });
  }

  protected showPostAdDialog(publishPostData: PublishPostData): void {
    if (publishPostData.post.isFacebookPost) {
      const facebookPost = publishPostData.post as PartnerCampaignFacebookPost;

      if (!this.partner.isConnectedToFB) {
        this.showFBConnectionModal();
        return;
      }

      if (
        publishPostData.postToInstagram &&
        facebookPost.canBePostedToInstagram &&
        !this.partner.isConnectedToInstagram
      ) {
        this.showFBConnectionModal();
        return;
      }

      if (!this.partner.isAuthorizedFBAds) {
        this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }
    }

    this.dialog.closeAll();
    this.dialog.open(PartnerCampaignPromoteAdDialogComponent, {
      data: new PartnerCampaignPromoteAdDialogData(
        this.campaign,
        publishPostData.post,
        publishPostData.scheduledPublishDateAndTime,
        this.partner,
        true,
        publishPostData.postToFacebook,
        publishPostData.postToInstagram,
      ),
      disableClose: true,
    });
  }
}
