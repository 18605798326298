<div
  class="guide-tooltip guide-tooltip--{{ position }}"
  [ngClass]="{
    'guide-tooltip--shown': visible,
    'guide-tooltip--hidden': !visible
  }"
>
  <span class="tips"
    >{{ currentStep }}/{{ totalSteps }} {{ "shared.tips" | translate }}</span
  >
  <div class="title">{{ title }}</div>
  <p class="description">{{ description }}</p>
  <div class="buttons-wrap">
    <span
      *ngIf="bottomIconType === ICON_TYPE_DRAG_DROP"
      class="tooltip-bottom-icon"
    >
      <img src="/assets/images/drag-drop.svg" width="28" />
    </span>
    <button
      (click)="skip()"
      class="tool-tip-button"
      *ngIf="closeButtonText === 'skip'"
      [ngClass]="{
        'button-white tool-tip-button--white': closeButtonColor === 'white',
        'button-orange': closeButtonColor === 'orange'
      }"
    >
      <span>{{ "shared.skip" | translate }}</span>
    </button>
    <button
      (click)="close()"
      class="tool-tip-button"
      *ngIf="closeButtonText === 'close'"
      [ngClass]="{
        'button-white tool-tip-button--white': closeButtonColor === 'white',
        'button-orange': closeButtonColor === 'orange'
      }"
    >
      <span>{{ "shared.close" | translate }}</span>
    </button>
    <button
      *ngIf="displayNextButton === true"
      (click)="nextGuideToolTip()"
      class="button-orange tool-tip-button tool-tip-button--next"
    >
      <span>{{ "shared.next" | translate }}</span>
    </button>
  </div>
</div>
