import { NgModule } from "@angular/core";
import { angularProvidersBuilder } from "../../../../../harmony/angular";
import { PublicationIntentService } from "../data/publication-intent.service";
import { CountPendingPublicationIntentsInteractor } from "./interactors/count-pending-publication-intents.interactor";
import {
  PublicationIntentProvider,
  PublicationIntentsDefaultProvider,
} from "./publication-intent.provider";

@NgModule({
  providers: angularProvidersBuilder({
    provide: PublicationIntentProvider,
    deps: [PublicationIntentService],
    useFactory: (PublicationIntentsService: PublicationIntentService) =>
      new PublicationIntentsDefaultProvider(PublicationIntentsService),
  })
    .add(CountPendingPublicationIntentsInteractor, (p) =>
      p.provideCountPendingIntents(),
    )
    .build(),
})
export class PublicationIntentProviderModule {}
