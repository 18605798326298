import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OrderLineCreate } from "../partner-campaign-create-order/partner-campaign-create-order.component";
import { Address } from "../../../../shared/models/address";

export interface PartnerCampaignOrderConfirmationDialogData {
  orderLines: OrderLineCreate[];
  orderTotal: number;
  currency: string;
  companyName: string;
  partnerName: string;
  address: Address;
  email: string;
}

@Component({
  selector: "app-partner-campaign-order-confirmation-dialog",
  templateUrl: "./partner-campaign-order-confirmation-dialog.component.html",
  styleUrls: ["./partner-campaign-order-confirmation-dialog.component.scss"],
})
export class PartnerCampaignOrderConfirmationDialogComponent {
  @Output() public orderConfirmedEvent = new EventEmitter<void>();

  public orderConfirmed = false;

  protected saving = false;

  constructor(
    public dialogRef: MatDialogRef<PartnerCampaignOrderConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PartnerCampaignOrderConfirmationDialogData,
  ) {}

  protected confirmOrder(): void {
    this.saving = true;
    this.orderConfirmedEvent.emit();
  }

  protected seeOrders(): void {
    this.dialogRef.close();
  }
}
