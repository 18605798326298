<ng-container *ngIf="hasPOS() && appData() && partner && campaign">
  <app-partner-campaign-create-order
    [appData]="appData()"
    [partner]="partner"
    [campaign]="campaign"
    *ngIf="hasPastOrders === false"
  />
  <app-partner-campaign-order-list
    [appData]="appData()"
    [partner]="partner"
    [campaign]="campaign"
    *ngIf="hasPastOrders"
  />
</ng-container>

<ng-container *ngIf="!hasPOS()">
  <div class="no-pos-available">
    <img src="/assets/images/as_posorders_pal.png" />
    <h2>{{ "partner.orderList.notActive" | translate }}</h2>
    <p>{{ "partner.orderList.notActiveSubtitle" | translate }}</p>
  </div>
</ng-container>
