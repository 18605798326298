import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  booleanAttribute,
} from "@angular/core";

import { Address, AddressType } from "../../models/address";
import { PartnerService } from "../../services/api/partner.service";
import { DialogService } from "../../services/dialog.service";
import { NotificationService } from "../../services/notification.service";
import { AppDataCountry } from "../../models/appData";
import { Partner } from "../../models/partner";
import { PartnerDialogService } from "../../../partner/partner-dialog.service";
import { NgClass, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-address-box",
  templateUrl: "./address-box.component.html",
  styleUrl: "./address-box.component.scss",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, TranslateModule],
})
export class AddressBoxComponent {
  @Input({ required: true }) address!: Address;
  @Input({ required: true }) allowEdition!: boolean;
  @Input({ required: true }) countries!: AppDataCountry[];
  @Input({ required: true }) partner!: Partner;
  @Input({ required: true }) type!: AddressType;
  @Input() mode: "changeable" | "editable" | "noAction" = "changeable";
  @Input() allowDeletion = true;
  @Input() boxSize: "medium" | "small" | "summary" = "medium";
  @Input({ transform: booleanAttribute }) public fullHeight = false;

  public isLoading = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly dialogService: DialogService,
  ) {}

  public getCountryName(): string {
    if (this.address && this.countries) {
      const country = this.countries.find(
        (c) => c.code === this.address.country,
      );

      return (country ? country.translatedName : this.address.country) ?? "";
    }

    return "";
  }

  public isDefaultForTheType(): boolean {
    return (
      (this.type === AddressType.Billing &&
        this.partner.billingAddressId === this.address.id) ||
      (this.type === AddressType.Shipping &&
        this.partner.shippingAddressId === this.address.id)
    );
  }

  public showConfirmDeleteAddress(event: MouseEvent): void {
    event.stopPropagation();

    this.dialogService.confirm({
      title: "addressBox.deleteConfirmTitle",
      message: "addressBox.deleteConfirm",
      onConfirm: () => this.deleteAddress(),
    });
  }

  private deleteAddress(): void {
    this.isLoading = true;
    this.cdr.markForCheck();

    this.partnerService
      .deleteAddress(this.partner.id, this.address.id)
      .subscribe({
        next: () => {
          this.partner.addresses = this.partner.addresses.filter(
            (a) => a.id !== this.address.id,
          );
          this.partnerService.setCurrentPartner(this.partner);
          this.notificationService.success("addressBox.deleteSuccess");
        },
        error: () => {
          this.notificationService.error("shared.errorSavingChanges");
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  public async showEditAddressDialog(): Promise<void> {
    const updatedAddress = await this.partnerDialogService.showChooseAddress({
      address: this.address,
      addresses: this.partner.addresses,
      type: this.type,
    });

    if (updatedAddress) {
      // TODO: Move this logic to partner service - Use this task to track https://app.asana.com/0/1201670003497872/1201929265193479/f
      const hasChangedDefault =
        this.address.isDefault !== updatedAddress.isDefault;

      this.partner.addresses = this.partner.addresses.map((address) => {
        const isUpdatedAddress = address.id === updatedAddress.id;
        const hasSameType = address.type === updatedAddress.type;

        if (isUpdatedAddress) {
          address = updatedAddress;
        }

        if (!isUpdatedAddress && hasChangedDefault && hasSameType) {
          address.isDefault = false;
        }

        if (address.isDefault) {
          if (address.isBillingAddress) {
            this.partner.billingAddressId = address.id;
          }

          if (address.isShippingAddress) {
            this.partner.shippingAddressId = address.id;
          }
        }

        return address;
      });

      this.partnerService.setCurrentPartner(this.partner);
    }
  }
}
