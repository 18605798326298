import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CAMPAIGN_ACCESS_DENIED } from "../../../brand/brand-campaign/shared/services/brand-campaign.service";

import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { RouteWithDataService } from "../../../shared/services/route-with-data.service";
import { PartnerUrl } from "../../partner.url";

enum NavigationItems {
  ContentCalendar = "content-calendar",
  DownloadArea = "download-area",
  Pos = "pos",
  PostedScheduled = "posted-scheduled",
  Publications = "publications",
  Summary = "summary",
  Winner = "winner",
}

@Component({
  selector: "app-partner-campaign-navigation",
  templateUrl: "./partner-campaign-navigation.component.html",
  styleUrls: ["./partner-campaign-navigation.component.scss"],
})
export class PartnerCampaignNavigationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected campaign?: PartnerCampaign;
  protected campaignDetails?: PartnerCampaignDetails;

  private readonly subscription = new Subscription();

  protected readonly NavigationItems = NavigationItems;
  private readonly BetaCampaignSections = [
    NavigationItems.Summary,
    NavigationItems.Publications,
    NavigationItems.Pos,
  ];

  constructor(
    protected readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
    private readonly routeWithDataService: RouteWithDataService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationService: NotificationService,
  ) {}

  public ngOnInit(): void {
    const campaignId = +this.activatedRoute.snapshot.params.campaignId;

    this.partnerCampaignService.setCurrentCampaignId(campaignId);
    this.isViewingDownloadPage();

    this.partnerCampaignService.getCurrentPartnerCampaign(true).subscribe({
      next: (campaign) => {
        if (campaign) {
          this.campaign = campaign;
        }
      },
      error: (err) => {
        if (
          err.error?.key === CAMPAIGN_ACCESS_DENIED ||
          err.error?.error?.key === CAMPAIGN_ACCESS_DENIED
        ) {
          this.notificationService.error("shared.errorNoAccessToCampaign");
          this.router.navigate([
            PartnerUrl.Home(this.partnerService.currentPartnerId),
          ]);
          return;
        } else {
          this.notificationService.error("shared.errorLoadingTheCampaign");
        }
      },
    });

    this.subscription.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe(
        (campaign) => {
          this.campaign = campaign;
        },
      ),
    );

    this.subscription.add(
      this.partnerCampaignService.partnerCampaignDetailsUpdated.subscribe(
        ([, details]) => {
          this.campaignDetails = details;
        },
      ),
    );
  }

  public ngAfterViewInit(): void {
    if (this.routeWithDataService.tabIndex) {
      this.partnerCampaignService.selectedMenuCategoryIndex =
        this.routeWithDataService.tabIndex;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected hasPOS(): boolean {
    const currentDetails = this.campaign?.currentDetails;

    if (!currentDetails) {
      return false;
    }

    return currentDetails.pos && currentDetails.pos.length > 0;
  }

  protected hasCampaignAccess(campaign?: PartnerCampaign): boolean {
    if (!campaign) {
      return false;
    }

    return campaign.partnerHasAccess;
  }

  protected changeTab(index?: number): void {
    this.routeWithDataService.tabIndex = index;
    this.partnerCampaignService.selectedMenuCategoryIndex = index;
  }

  protected isViewingDownloadPage(): boolean {
    return this.router.url.indexOf("downloads") !== -1;
  }

  protected isCategoryActive(i: number): boolean {
    const index = this.partnerCampaignService.selectedMenuCategoryIndex;

    if (index === undefined) {
      return false;
    }

    return index === i;
  }

  protected scrollToTop(): void {
    document.querySelector("html, body")?.scrollIntoView({
      behavior: "smooth",
    });
  }

  protected isShown(section: NavigationItems): boolean {
    if (!this.campaign) {
      return false;
    }

    if (this.campaign.isBeta) {
      return this.BetaCampaignSections.includes(section);
    }

    return section !== NavigationItems.Publications;
  }
}
