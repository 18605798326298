import dayjs from "dayjs";

import { PostPartnerStatus } from "../../enums/campaignPost.enums";
import { PartnerGoogleAdPostLog } from "../../models/partnerGoogleAdPostLog";

export class UpdateGoogleAdPostLogData {
  public scheduledPublishDate: number;
  public partnerStatus?: PostPartnerStatus;
  public descriptionFive: string;
  public descriptionFour: string;
  public descriptionOne: string;
  public descriptionThree: string;
  public descriptionTwo: string;
  public headlineFive: string;
  public headlineFour: string;
  public headlineOne: string;
  public headlineThree: string;
  public headlineTwo: string;
  public longHeadline: string;

  constructor(
    postLog: PartnerGoogleAdPostLog,
    partnerStatus?: PostPartnerStatus,
    scheduledPublishDate: Date | null = null,
  ) {
    if (scheduledPublishDate) {
      this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    } else {
      this.scheduledPublishDate = dayjs(postLog.scheduledPublishDate).unix();
    }
    this.partnerStatus = partnerStatus;
    this.descriptionFive = postLog.descriptionFive;
    this.descriptionFour = postLog.descriptionFour;
    this.descriptionOne = postLog.descriptionOne;
    this.descriptionThree = postLog.descriptionThree;
    this.descriptionTwo = postLog.descriptionTwo;
    this.headlineFive = postLog.headlineFive;
    this.headlineFour = postLog.headlineFour;
    this.headlineOne = postLog.headlineOne;
    this.headlineThree = postLog.headlineThree;
    this.headlineTwo = postLog.headlineTwo;
    this.longHeadline = postLog.longHeadline;
  }
}
