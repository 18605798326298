<ng-container *ngFor="let post of posts">
  <div class="post post-{{ post.id }}">
    <app-generic-post-mock-up [partner]="partner" [post]="post" />
    <app-partner-campaign-publish-post-time-picker
      (publishButtonClickedEvent)="showPostAdDialog($event)"
      [campaign]="campaign"
      [partner]="partner"
      [post]="post"
    />
  </div>
</ng-container>
