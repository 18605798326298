import { Component, Input } from "@angular/core";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignLinkedInPost } from "../../../shared/models/partnerCampaignLinkedInPost";
import { PartnerDialogService } from "../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-download-category-linkedin-post",
  templateUrl:
    "./partner-campaign-download-category-linkedin-post.component.html",
  styleUrl: "./partner-campaign-download-category-linkedin-post.component.scss",
})
export class PartnerCampaignDownloadCategoryLinkedInPostComponent {
  @Input({ required: true }) campaign!: PartnerCampaign;
  @Input({ required: true }) campaignDetails!: PartnerCampaignDetails;
  @Input({ required: true }) post!: PartnerCampaignLinkedInPost;
  @Input({ required: true }) partner!: Partner;

  constructor(private readonly partnerDialogService: PartnerDialogService) {}

  public async showScheduleLinkedInPostDialog(): Promise<void> {
    if (!this.partner) {
      return;
    }

    if (!this.partner.isConnectedToLinkedIn) {
      await this.partnerDialogService.showLinkedInNotConnected({
        partnerId: this.partner.id,
      });
      return;
    }

    this.partnerDialogService.showPartnerCampaignPublishPost({
      campaign: this.campaign,
      partner: this.partner,
      post: this.post,
    });
  }

  public get isPostButtonDisabled(): boolean {
    return !this.campaign || !this.post?.text || this.campaign.hasFinished;
  }
}
