import { Component, OnInit } from "@angular/core";
import { safeLocalStorage } from "../../../../shared/utils/safe-storage";

@Component({
  selector: "app-select-campaign-warning-dialog",
  templateUrl: "./select-campaign-warning-dialog.component.html",
  styleUrl: "./select-campaign-warning-dialog.component.scss",
})
export class CalendarSelectCampaignWarningDialogComponent implements OnInit {
  public currentLanguageCode = "en";
  // TODO: Change it to availableLocales constant when we have all the images
  private availableImageLanguages = ["de_DE", "en_GB"];

  public ngOnInit(): void {
    const localeId = safeLocalStorage.getItem("localeId") ?? "";

    if (this.availableImageLanguages.includes(localeId)) {
      this.currentLanguageCode = localeId.substring(0, 2);
    }
  }
}
