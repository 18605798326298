import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { CampaignType } from "../../../shared/enums/campaign.enums";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerCampaignBudgetConfirmDialogComponent } from "../partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog.component";
import {
  PartnerCampaignBudgetViewDialogComponent,
  PartnerCampaignBudgetViewDialogData,
} from "../partner-campaign-budget-view-dialog/partner-campaign-budget-view-dialog.component";
import { PartnerDialogService } from "../../partner-dialog.service";
@Component({
  selector: "app-partner-campaign-header",
  templateUrl: "./partner-campaign-header.component.html",
  styleUrls: ["./partner-campaign-header.component.scss"],
})
export class PartnerCampaignHeaderComponent implements OnInit, OnDestroy {
  @Input({ required: true }) campaign!: PartnerCampaign;

  private confirmDialogRef?: MatDialogRef<PartnerCampaignBudgetConfirmDialogComponent>;
  private readonly subscription = new Subscription();

  constructor(
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly dialog: MatDialog,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.partnerCampaignService.partnerCampaignBudgetEditedEvent.subscribe(
        () => {
          if (this.confirmDialogRef) {
            this.confirmDialogRef.close();
          }
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected shouldShowShortLink(): boolean {
    return this.campaign.type !== CampaignType.DownloadOnly;
  }

  protected goToLink(): void {
    window.open(this.campaign.campaignUrl, "_blank");
  }

  protected openBudgetDialog(): void {
    if (this.campaign.canPartnerEditBudget) {
      this.openEditBudgetDialog();
    } else {
      this.openViewBudgetDialog();
    }
  }

  private async openEditBudgetDialog(
    budget?: number,
    adDuration?: number,
    numberOfAds?: number,
  ): Promise<void> {
    const confirmBudgetDialogRef =
      await this.partnerDialogService.showPartnerCampaignBudgetEdit({
        campaign: this.campaign,
        budget: budget,
        adDuration: adDuration,
        numberOfAds: numberOfAds,
      });

    if (confirmBudgetDialogRef) {
      this.confirmDialogRef = confirmBudgetDialogRef;

      this.subscription.add(
        confirmBudgetDialogRef.componentInstance.backEvent.subscribe(
          (newBudget) => {
            this.openEditBudgetDialog(
              newBudget.budget,
              newBudget.adDuration,
              newBudget.numberOfAds,
            );
          },
        ),
      );
    }
  }

  private openViewBudgetDialog(): void {
    this.dialog.open(PartnerCampaignBudgetViewDialogComponent, {
      width: "536px",
      data: new PartnerCampaignBudgetViewDialogData(this.campaign),
      disableClose: true,
    });
  }
}
