import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Order } from "../../../../shared/models/order";
import { HttpClient } from "../../../../shared/services/http-client";

@Injectable()
export class OrderService {
  constructor(private http: HttpClient) {}

  getOrders(partnerId: number, campaignId: number): Observable<Order[]> {
    return this.http
      .get(`partner/${partnerId}/campaign/${campaignId}/order`)
      .pipe(
        map(({ body }) => body.items.map((order: any) => new Order(order))),
      );
  }
}
